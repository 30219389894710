var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("table-wrapper", {
        attrs: {
          confirmNewItems: function confirmNewItems() {},
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          allItems: _vm.contracts,
          sortBy: "title"
        },
        on: {
          "click:row": _vm.openContractSideCard,
          addItem: _vm.addContract
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c("excel-import-create", {
                  attrs: {
                    dto: _vm.createDto,
                    baseData: _vm.baseData,
                    excludeKeys: ["vehicleId", "vehicleDisplayName"]
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item.title",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _c("a", {
                  domProps: {
                    textContent: _vm._s(item.title || item.number)
                  },
                  on: {
                    click: function click($event) {
                      return _vm.openContractSideCard(item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "item.startDate",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                item.startDate
                  ? _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.simpleDoubleDigitDate(item.startDate)
                        )
                      }
                    })
                  : _c("v-icon", {
                      domProps: {
                        textContent: _vm._s("mdi-minus")
                      }
                    })
              ]
            }
          },
          {
            key: "item.endDate",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                item.endDate
                  ? _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.simpleDoubleDigitDate(item.endDate)
                        )
                      }
                    })
                  : _c("v-icon", {
                      domProps: {
                        textContent: _vm._s("mdi-minus")
                      }
                    })
              ]
            }
          },
          {
            key: "footer",
            fn: function fn() {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      icon: "",
                      elevation: 0
                    },
                    on: {
                      click: _vm.download
                    }
                  },
                  [_c("v-icon", [_vm._v(" mdi-download ")])],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("contract-create-dialog", {
        attrs: {
          vehicle: _vm.vehicle,
          isDialogActive: _vm.createContractDialog
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.createContractDialog = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.createContractDialog = $event
          }
        }
      }),
      _vm.sideCardContract
        ? _c("contract-side-card", {
            attrs: {
              loading: _vm.loading || _vm.isUpdatingContractLoading,
              partnerId: _vm.vehicle.partnerId,
              vehicle: _vm.vehicle,
              documentSuggestions: _vm.vehicleDocuments,
              loadingDocumentSuggestions: _vm.loadingDocumentSuggestions
            },
            on: {
              close: _vm.closeContractSideCard,
              save: _vm.onUpdateContract,
              delete: _vm.onRemoveContract
            },
            model: {
              value: _vm.sideCardContract,
              callback: function callback($$v) {
                _vm.sideCardContract = $$v
              },
              expression: "sideCardContract"
            }
          })
        : _vm._e(),
      _vm.contractToDelete
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isConfirmDeleteContractDialogActive,
              title: _vm.$t("components.fleet.contract.confirmDeleteContract", {
                name: _vm.contractToDelete.number
              }),
              leftText: _vm.$t("common.verbs.cancel"),
              rightText: _vm.$t("common.verbs.remove")
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isConfirmDeleteContractDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isConfirmDeleteContractDialogActive = $event
              },
              leftClick: function leftClick($event) {
                _vm.isConfirmDeleteContractDialogActive = false
              },
              rightClick: function rightClick($event) {
                return _vm.onRemoveContract(_vm.contractToDelete)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }