var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        {
          attrs: {
            fluid: ""
          }
        },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.TEMPLATE)
                ? _c("v-tab", [
                    _vm._v(
                      " " +
                        _vm._s("navigation.ThePartnerList.setting.templates") +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.SETTING_PARTNER_INTERNAL
              )
                ? _c("v-tab")
                : _vm._e(),
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                ? _c("v-tab")
                : _vm._e(),
              _c("v-tab-item"),
              _c("v-tab-item"),
              _c("v-tab-item")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }