var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("table-wrapper", {
        attrs: {
          headers: _vm.headers,
          allItems: _vm.allDocuments
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.user.firstName) +
                    " " +
                    _vm._s(item.user.lastName) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.activity",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.i18n[item.activity] || item.activity) + " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }