var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.title,
              subtitle: _vm.subtitle,
              chips: _vm.chips,
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions,
              alerts: _vm.alerts
            },
            on: {
              actionClicked: _vm.processAction,
              alertClicked: _vm.processAlerts,
              "update:alerts": function updateAlerts($event) {
                _vm.alerts = $event
              }
            }
          }),
          _c(
            "div",
            {
              staticClass: "mx-4"
            },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function callback($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c("v-tabs-slider", {
                    attrs: {
                      color: "yellow"
                    }
                  }),
                  _vm._l(_vm.tabs, function(item) {
                    return _c(
                      "v-tab",
                      {
                        key: item.key,
                        staticClass: "v-tabs__container"
                      },
                      [
                        item.icon
                          ? _c(
                              "v-icon",
                              {
                                attrs: {
                                  left: ""
                                }
                              },
                              [_vm._v(_vm._s(item.icon))]
                            )
                          : _vm._e(),
                        _vm._v(_vm._s(item.text) + " ")
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c("v-divider")
            ],
            1
          ),
          _vm.activeTab === 0
            ? _c(
                "m-detail-view-grid",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "details",
                        fn: function fn() {
                          return [
                            _c("m-detail-table", {
                              attrs: {
                                title: _vm.$t("about"),
                                i18nPath: "designGuide.MDetailTable",
                                item: _vm.testItem,
                                showMoreAction: _vm.tableShowMoreAction,
                                config: _vm.detailTableConfig,
                                customFieldValues: _vm.customFieldsForm
                              },
                              on: {
                                actionClicked: _vm.processAction
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "actions",
                        fn: function fn() {
                          return [
                            _c("m-action-list", {
                              attrs: {
                                divider: true,
                                actions: _vm.actions
                              },
                              on: {
                                actionClicked: _vm.processAction
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    218083683
                  )
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [_vm._v("Lorem Ipsum")]),
                      _c("v-card-text", [
                        _vm._v(
                          " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.activeTab === 1
            ? _c(
                "v-row",
                {
                  staticClass: "mt-4 mx-4",
                  attrs: {
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-medium-emphasis"
                        },
                        [_vm._v("Select Image")]
                      ),
                      _c("v-select", {
                        attrs: {
                          items: _vm.imageOptions,
                          "item-text": "text",
                          "item-value": "src",
                          outlined: ""
                        },
                        model: {
                          value: _vm.imageMarkerSrc,
                          callback: function callback($$v) {
                            _vm.imageMarkerSrc = $$v
                          },
                          expression: "imageMarkerSrc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "grow"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-medium-emphasis"
                        },
                        [_vm._v("Markersize")]
                      ),
                      _c(
                        "v-btn-toggle",
                        {
                          model: {
                            value: _vm.markerSize,
                            callback: function callback($$v) {
                              _vm.markerSize = $$v
                            },
                            expression: "markerSize"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: "x-small"
                              }
                            },
                            [_vm._v(" XS ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: "small"
                              }
                            },
                            [_vm._v(" S ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: "medium"
                              }
                            },
                            [_vm._v(" M ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: "large"
                              }
                            },
                            [_vm._v(" L ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "shrink"
                    },
                    [
                      _c("v-switch", {
                        model: {
                          value: _vm.activated,
                          callback: function callback($$v) {
                            _vm.activated = $$v
                          },
                          expression: "activated"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("m-image-marker", {
                        attrs: {
                          src: _vm.imageMarkerSrc,
                          markers: _vm.markers,
                          markerSize: _vm.markerSize,
                          deactivated: !_vm.activated
                        },
                        on: {
                          markerCreated: _vm.addMarker
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.activeTab === 2
            ? _c(
                "v-row",
                {
                  staticClass: "mt-4 mx-4"
                },
                [_vm._v(" View 2 ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }