var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-row",
        [
          _c(
            "v-card",
            {
              staticClass: "mt-4 mb-n6",
              attrs: {
                elevation: 0
              }
            },
            [
              _c("v-card-text", [
                _c(
                  "a",
                  {
                    on: {
                      click: _vm.goToBookingView
                    }
                  },
                  [
                    _c("v-icon", [_vm._v(" mdi-menu-left ")]),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sign.DocumentDetail.back")) + " "
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [_c("resource-table")],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [_c("service-table")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }