var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.$t("nav.BookingCalendar.title"),
              actions: _vm.actions,
              breadCrumbs: _vm.breadCrumbs,
              chips: _vm.chips
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              }
            }
          }),
          _c("partner-booking-calendar", {
            attrs: {
              loading: _vm.isLoading,
              bookings: _vm.bookings,
              services: _vm.services,
              resources: _vm.resources
            },
            on: {
              click: function click($event) {
                _vm.selectedBooking = $event
              },
              reload: _vm.load
            }
          }),
          _vm.selectedBooking
            ? _c("booking-side-card", {
                attrs: {
                  value: _vm.bookingDto,
                  loading: _vm.isLoading,
                  resourceName: _vm.resourceName,
                  serviceName: _vm.serviceName,
                  resources: _vm.resourceList,
                  service: _vm.service
                },
                on: {
                  goTo: _vm.processGoToAction,
                  delete: _vm.onDeleteBooking,
                  close: _vm.onCloseSideCard
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }