var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        outlined: ""
      }
    },
    [
      _c(
        "v-card-title",
        [
          _vm._v(" " + _vm._s(_vm.i18n["title"]) + " "),
          _c("v-spacer"),
          _c("context-menu", [
            _c(
              "div",
              {
                staticClass: "mb-n6"
              },
              _vm._l(_vm.checkboxes, function(checkbox, index) {
                return _c("v-checkbox", {
                  key: index,
                  staticClass: "mt-n1 mb-n1",
                  attrs: {
                    color: "success",
                    label: _vm.i18n[checkbox.label]
                  },
                  model: {
                    value: checkbox.value,
                    callback: function callback($$v) {
                      _vm.$set(checkbox, "value", $$v)
                    },
                    expression: "checkbox.value"
                  }
                })
              }),
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "ma-auto"
        },
        [
          _c("v-chart", {
            staticStyle: {
              "min-height": "382px"
            },
            attrs: {
              loading: false,
              option: _vm.chartOption,
              autoresize: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }