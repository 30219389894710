var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("v-container", [
        _vm.isLoading
          ? _c(
              "div",
              [
                _c("v-skeleton-loader", {
                  attrs: {
                    type:
                      "list-item, list-item-two-line, list-item-two-line, list-item-two-line"
                  }
                })
              ],
              1
            )
          : _vm.customField
          ? _c(
              "div",
              [
                _c(
                  "a",
                  {
                    on: {
                      click: _vm.onBack
                    }
                  },
                  [
                    _c("v-icon", [_vm._v(" mdi-menu-left ")]),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sign.DocumentDetail.back")) + " "
                      )
                    ])
                  ],
                  1
                ),
                _c("custom-field-detail-card", {
                  attrs: {
                    customField: _vm.customField
                  },
                  on: {
                    onCheck: _vm.update,
                    onDelete: _vm.onDelete,
                    onClose: function onClose($event) {
                      return _vm.setCustomField(null)
                    }
                  }
                }),
                _c("confirm-action-dialog", {
                  attrs: {
                    title: _vm.$t(
                      "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                    ),
                    isDialogActive: _vm.isDeleteDialogActive,
                    rightLoading: _vm.isLoading
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isDeleteDialogActive = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isDeleteDialogActive = $event
                    },
                    leftClick: function leftClick($event) {
                      _vm.isDeleteDialogActive = false
                    },
                    rightClick: _vm.confirmDeletion
                  }
                })
              ],
              1
            )
          : _c("div", [_c("latest-entries-card-empty")], 1)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }