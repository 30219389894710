var render = function() {
  var _vm$selected$registra,
    _vm$selected$registra2,
    _vm$selected$registra4,
    _vm$selected$registra5

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.selected
    ? _c(
        "v-card",
        {
          attrs: {
            flat: _vm.flat,
            outlined: ""
          }
        },
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass: "text-truncate"
              },
              [_vm._v(" " + _vm._s(_vm.selected.displayName) + " ")]
            )
          ]),
          _vm.detailed
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "px-0"
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-identifier")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(_vm.selected.identificationnumber)
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "px-0"
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-ticket-outline")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.selected.numberplate))
                          ])
                        ],
                        1
                      ),
                      (_vm$selected$registra = _vm.selected.registration) !==
                        null &&
                      _vm$selected$registra !== void 0 &&
                      _vm$selected$registra.vehicleClass
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "px-0"
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  (_vm$selected$registra2 =
                                    _vm.selected.registration) !== null &&
                                  _vm$selected$registra2 !== void 0 &&
                                  _vm$selected$registra2.vehicleClass
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function fn(_ref) {
                                                  var _vm$selected$registra3

                                                  var on = _ref.on,
                                                    attrs = _ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getIconForVehicleClass(
                                                              (_vm$selected$registra3 =
                                                                _vm.selected
                                                                  .registration) ===
                                                                null ||
                                                                _vm$selected$registra3 ===
                                                                  void 0
                                                                ? void 0
                                                                : _vm$selected$registra3.vehicleClass
                                                            ).icon
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3068621175
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getIconForVehicleClass(
                                                  (_vm$selected$registra4 =
                                                    _vm.selected
                                                      .registration) === null ||
                                                    _vm$selected$registra4 ===
                                                      void 0
                                                    ? void 0
                                                    : _vm$selected$registra4.vehicleClass
                                                ).text
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm$selected$registra5 =
                                          _vm.selected.registration) === null ||
                                          _vm$selected$registra5 === void 0
                                          ? void 0
                                          : _vm$selected$registra5.vehicleClass
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showActions
            ? _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color: "info",
                        elevation: "0",
                        disabled: ""
                      }
                    },
                    [_vm._v("Bearbeiten")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        small: "",
                        outlined: "",
                        color: "info",
                        disabled: ""
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }