var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("the-layout-portal-list", {
              attrs: {
                title: _vm.$t("nav.PartnerUserList.title"),
                getModule: _vm.getModule,
                isLoadingMore: _vm.isLoadingMore
              },
              on: {
                loadMore: _vm.loadMore,
                refresh: function refresh() {
                  return _vm.refresh({
                    partnerId: _vm.partnerId
                  })
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "listItem",
                  fn: function fn(_ref) {
                    var listItem = _ref.listItem
                    return [
                      _vm.isLoading || !listItem
                        ? _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type:
                                "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider"
                            }
                          })
                        : _c(
                            "v-list-item",
                            {
                              key: listItem.id,
                              staticClass: "item",
                              class: _vm.isActive(listItem),
                              attrs: {
                                "two-line": ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.set(listItem)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(listItem.firstName) +
                                        " " +
                                        _vm._s(listItem.lastName)
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(listItem.userName))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          var _vm$user$contact, _vm$user$contact2

          return [
            _vm.isLoadingDetail
              ? _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: {
                    type:
                      "card-heading, divider, list-item-two-line,list-item-two-line, divider"
                  }
                })
              : _vm.user
              ? _c(
                  "v-card",
                  {
                    attrs: {
                      flat: ""
                    }
                  },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass: "pt-4"
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "text-truncate"
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.user.userName) + " "),
                            _vm.user.countryCode
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("getFlagEmoji")(
                                          _vm.user.countryCode
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _c(
                      "v-card-subtitle",
                      [
                        _c(
                          "v-row",
                          {
                            staticStyle: {
                              margin: "0 1px 0 1px"
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.user.id) + " "),
                            _c("v-spacer"),
                            _c("user-administration-detail-identity-status", {
                              attrs: {
                                isPartner: true,
                                value: _vm.user
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("customer-contact-card", {
                      attrs: {
                        customerName:
                          _vm.user.firstName + " " + _vm.user.lastName,
                        address: _vm.user.address,
                        email:
                          (_vm$user$contact = _vm.user.contact) === null ||
                          _vm$user$contact === void 0
                            ? void 0
                            : _vm$user$contact.email,
                        phone:
                          (_vm$user$contact2 = _vm.user.contact) === null ||
                          _vm$user$contact2 === void 0
                            ? void 0
                            : _vm$user$contact2.phone,
                        isCompany: _vm.user.isCompany,
                        companyName: _vm.user.company,
                        isTaxDeductible: _vm.user.isTaxDeductible,
                        taxnumber: _vm.user.taxnumber
                      }
                    }),
                    _c("debug", {
                      attrs: {
                        debug: _vm.user
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }