var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("partner-report-list-paginated", {
              attrs: {
                isLoadingDetail: _vm.loading
              },
              on: {
                set: _vm.set
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _c("partner-report-detail", {
              attrs: {
                loading: _vm.loading
              },
              on: {
                "update:loading": function updateLoading($event) {
                  _vm.loading = $event
                },
                setReport: _vm.loadDetail
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }