var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _vm.value.ruleOptions
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "views.fleet.EventCard.rrule.".concat(
                    _vm.value.ruleOptions.freq
                  ),
                  {
                    x: _vm.value.ruleOptions.interval
                  }
                )
              ) +
              " "
          )
        ])
      : _vm._e(),
    _vm.value.ruleOptions.until
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("common.nouns.end")) +
              " " +
              _vm._s(
                _vm.$t("views.fleet.EventCreateDialog.repeatItems.atDay")
              ) +
              " " +
              _vm._s(
                _vm._f("simpleDoubleDigitDate")(_vm.value.ruleOptions.until)
              ) +
              " "
          )
        ])
      : _vm.value.ruleOptions.count
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("views.fleet.EventCreateDialog.recurringEnd")) +
              " " +
              _vm._s(
                _vm.value.repeatItems.find(function(i) {
                  return i.value === _vm.value.limit
                }).text
              ) +
              " " +
              _vm._s(_vm.value.ruleOptions.count) +
              " " +
              _vm._s(_vm.$t("views.fleet.EventCreateDialog.recurringRepeats")) +
              " "
          )
        ])
      : _vm.value.limit === "none"
      ? _c("div", {
          domProps: {
            textContent: _vm._s(
              _vm.$t("views.fleet.EventCreateDialog.repeatItems.noEnd")
            )
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }