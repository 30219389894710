var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          attrs: {
            cols: "1"
          }
        },
        [
          _c("v-checkbox", {
            attrs: {
              inset: ""
            },
            model: {
              value: _vm.updatedAvailabilityDto.isAvailable,
              callback: function callback($$v) {
                _vm.$set(_vm.updatedAvailabilityDto, "isAvailable", $$v)
              },
              expression: "updatedAvailabilityDto.isAvailable"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          attrs: {
            cols: "5"
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t(
                "components.booking.AvailabilityFormField.startTime.".concat(
                  _vm.weekDay
                )
              ),
              rules: _vm.requiredRule,
              disabled: _vm.disabled,
              readonly: _vm.disabled,
              type: "time",
              "hide-details": "",
              outlined: "",
              "persistent-hint": ""
            },
            model: {
              value: _vm.updatedAvailabilityDto.startString,
              callback: function callback($$v) {
                _vm.$set(_vm.updatedAvailabilityDto, "startString", $$v)
              },
              expression: "updatedAvailabilityDto.startString"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          attrs: {
            cols: "5"
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t(
                "components.booking.AvailabilityFormField.endTime.".concat(
                  _vm.weekDay
                )
              ),
              rules: _vm.requiredRule,
              disabled: _vm.disabled,
              readonly: _vm.disabled,
              type: "time",
              "hide-details": "",
              outlined: "",
              "persistent-hint": ""
            },
            model: {
              value: _vm.updatedAvailabilityDto.endString,
              callback: function callback($$v) {
                _vm.$set(_vm.updatedAvailabilityDto, "endString", $$v)
              },
              expression: "updatedAvailabilityDto.endString"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }