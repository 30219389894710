var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialogModel,
        absolute: true,
        title: _vm.$t("anonymization.api.title"),
        hideRight: true,
        hideLeft: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        close: _vm.close,
        leftClick: _vm.close
      }
    },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.$t("anonymization.api.titleI")))]),
      _c("v-card-text", [
        _vm._v(" " + _vm._s(_vm.$t("anonymization.api.textI")) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: "info"
              },
              on: {
                click: _vm.goToCredential
              }
            },
            [_vm._v(_vm._s(_vm.$t("anonymization.api.actionI")) + "n")]
          )
        ],
        1
      ),
      _c("v-card-title", [_vm._v(_vm._s(_vm.$t("anonymization.api.titleII")))]),
      _c("v-card-text", [
        _vm._v(" " + _vm._s(_vm.$t("anonymization.api.textII")) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: "info"
              },
              on: {
                click: _vm.goToDocs
              }
            },
            [_vm._v(_vm._s(_vm.$t("anonymization.api.actionII")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }