var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "div",
            {
              class: !_vm.isEdit ? "mb-2 mt-2" : "mt-2",
              staticStyle: {
                display: "flex",
                "align-items": "center",
                height: "35px"
              }
            },
            [
              _vm.firstName && _vm.lastName
                ? _c(
                    "v-avatar",
                    {
                      staticClass: "white--text ml-1 mr-2",
                      attrs: {
                        color: "primary",
                        size: "25px",
                        right: true
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.firstName[0]) +
                          _vm._s(_vm.lastName[0]) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.subtitle) + " "),
              _c("v-spacer"),
              !_vm.isEdit
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        text: ""
                      },
                      on: {
                        click: _vm.startEdit
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-0",
              class: _vm.isEdit ? "" : "pa-2 ",
              style: _vm.templateEditorStyle,
              attrs: {
                flat: "",
                outlined: !_vm.isEdit
              }
            },
            [
              _c("template-editor", {
                key: "template-editor" + _vm.isEdit,
                attrs: {
                  readOnly: !_vm.isEdit,
                  isMobile: true,
                  outlined: _vm.isEdit
                },
                model: {
                  value: _vm.body,
                  callback: function callback($$v) {
                    _vm.body = $$v
                  },
                  expression: "body"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isEdit
        ? _c(
            "v-card-actions",
            {
              staticClass: "px-4 mt-2"
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    small: "",
                    elevation: "0",
                    color: "info"
                  },
                  on: {
                    click: _vm.updateBody
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    outlined: "",
                    color: "info"
                  },
                  on: {
                    click: _vm.cancel
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("discard")) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }