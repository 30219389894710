var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("paginated-table", {
            ref: "paginationTable",
            attrs: {
              store: _vm.pagination,
              title: _vm.$t("nav.ReportControlView.title"),
              headers: _vm.headers,
              baseQuery: _vm.baseQuery
            },
            scopedSlots: _vm._u([
              {
                key: "item.progressStatus",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _c(
                      "tooltip",
                      {
                        attrs: {
                          text: _vm.$t(
                            "components.partner.PartnerReportList.statusToolTip.".concat(
                              item.progressStatus
                            )
                          )
                        }
                      },
                      [
                        _c("v-avatar", {
                          staticClass: "mr-1 white--text",
                          attrs: {
                            color: _vm.color(item.progressStatus),
                            elevation: 0,
                            size: "14"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.timestamp.created",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("simpleDoubleDigitDate")(
                            item.timestamp.created
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }