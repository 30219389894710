var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialogModel,
        absolute: true,
        hideLeft: true,
        title: _vm.$t("objects.snapshot.create"),
        loading: _vm.loading
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        close: _vm.close,
        rightClick: _vm.rightClick
      }
    },
    [
      _vm._v(" " + _vm._s(_vm.$t("objects.snapshot.description")) + " "),
      _c("debug", [_vm._v(_vm._s(_vm.value))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }