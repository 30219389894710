import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivCreateSnapshotDtoGen } from "../v1/data-contracts";
import { Snapshot } from "../v1/Snapshot";

/**
 * Communicate with snapshot endpoints
 */
class SnapshotService {
  mrfiktivProxy: Snapshot;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Snapshot(mrfiktivHttpClientProvider.client());
  }

  async createForHandover(partnerId: string, handoverId: string, data: MrfiktivCreateSnapshotDtoGen) {
    return (await this.mrfiktivProxy.snapshotControllerCreate(partnerId, handoverId, data)).data;
  }

  async findAllByHandoverId(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.snapshotControllerFindAll(partnerId, handoverId)).data;
  }

  async findOneByHandoverId(partnerId: string, handoverId: string, snapshotId: string) {
    return (await this.mrfiktivProxy.snapshotControllerFindOne(partnerId, handoverId, snapshotId)).data;
  }
}

/**
 * Communicate with snapshot endpoints
 */
export default new SnapshotService(new MrfiktivHttpClientProvider());
