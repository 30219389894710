var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("fleet-calendar-card", {
    attrs: {
      parsed: _vm.parsed,
      recurringEvents: _vm.recurringEvents,
      loading: _vm.isLoadingEvents || _vm.isEventAggregationLoading,
      vehicleId: _vm.vehicleAggregation.vehicle.id,
      partnerId: _vm.partnerId,
      enableGrouping: false
    },
    on: {
      updateEventList: _vm.setRecurringEvents,
      openVehicleSideCard: function openVehicleSideCard(v) {
        return _vm.$emit("openVehicleSideCard", v)
      },
      openTicketSideCard: function openTicketSideCard(v) {
        return _vm.$emit("openTicketSideCard", v)
      },
      openReportSideCard: function openReportSideCard(v) {
        return _vm.$emit("openReportSideCard", v)
      },
      openContractDetail: function openContractDetail(v) {
        return _vm.$emit("openContractDetail", v)
      },
      openEventSideCard: function openEventSideCard($event) {
        return _vm.$emit("openEventSideCard", $event)
      }
    },
    model: {
      value: _vm.calendarFocus,
      callback: function callback($$v) {
        _vm.calendarFocus = $$v
      },
      expression: "calendarFocus"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }