








































































































































import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { dottedSubString } from "@/lib/utility/string-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ActivityCard from "../thg/ActivityCard.vue";
import Debug from "../utility/Debug.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import MImageMarker from "../utility/mmmint/MImageMarker.vue";
import MNavigationList from "../utility/mmmint/MNavigationList.vue";
import MQuickLinkList from "../utility/mmmint/MQuickLinkList.vue";
import RefsList from "../utility/RefsList.vue";
import CreateHandoverFindingDialog from "./CreateHandoverFindingDialog.vue";
import InspectionCard from "./InspectionCard.vue";
import TourHandoverFindingDialog from "./TourHandoverFindingDialog.vue";
import InspectionCreateDialog from "./InspectionCreateDialog.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { ICompany } from "@/models/company.entity";
import { emailRuleOptional } from "@/lib/rules/contactRule";

type ICompanyKeys = Extract<keyof IHandover, "owner" | "keeper" | "newKeeper">;
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import CreateHandoverSnapshotDialog from "./CreateHandoverSnapshotDialog.vue";

@Component({
  components: {
    Debug,
    MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MImageMarker,
    MNavigationList,
    MQuickLinkList,
    CreateHandoverFindingDialog,
    CreateHandoverSnapshotDialog,
    TourHandoverFindingDialog,
    InspectionCard,
    RefsList,
    RefsVehicle,
    PaginatedTable,
    ActivityCard,
    LatestEntriesCardEmpty,
    InspectionCreateDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class HandoverDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IHandover;

  @Prop()
  vehicle!: IVehicle;

  readonly handoverReportStore = HandoverReportModule;

  isUpdatingCompany = false;
  createDialog = false;
  createSnapshotDialog = false;

  isCompanyMenu: Record<Partial<ICompanyKeys>, boolean> = {
    owner: false,
    keeper: false,
    newKeeper: false
  };

  readonly companyKeys: ICompanyKeys[] = ["owner", "keeper", "newKeeper"];

  get emailRuleOptional() {
    return [emailRuleOptional()];
  }

  get partnerId() {
    return this.value.partnerId;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get title() {
    if (!this.value) {
      return this.$t("common.handover");
    }

    return `#${this.value.number} ${this.value.title}`;
  }

  get subtitle() {
    return "";
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.value) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.value.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.value.title, 30);
    } else {
      handoverTitle = `#${this.value.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: true,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.value.partnerId,
          handoverId: this.value.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push(
      {
        text: "Schaden erstellen",
        key: "createDamge",
        icon: "mdi-wrench-outline",
        exec: () => (this.$refs.findingCreateDialog as CreateHandoverFindingDialog)?.open(),
        disabled: !this.value.inspections?.length
      },
      {
        text: "Rundgang starten",
        key: "startTour",
        icon: "mdi-ray-start-arrow",
        exec: () => (this.$refs.tourDialog as TourHandoverFindingDialog)?.open()
      },
      {
        text: "Begehung erstellen",
        key: "newInspection",
        icon: "mdi-calendar-clock",
        exec: () => (this.$refs.inspectionCreateDialog as InspectionCreateDialog)?.open()
      },
      {
        text: this.$t("objects.snapshot.create").toString(),
        key: "showDetails",
        icon: "mdi-note-edit-outline",
        exec: () => (this.createSnapshotDialog = true)
      },
      {
        text: "Übergabe löschen",
        key: "delete",
        icon: "mdi-delete-outline",
        color: "red",
        exec: () => {
          //Todo
        }
      }
    );

    return actions;
  }

  get tableShowMoreAction() {
    // return {
    //   text: $t("designGuide.MDetailForm.allProps"),
    //   key: "showDetails"
    // };

    return undefined;
  }

  get navigation() {
    return [
      {
        text: "Befunde",
        key: "createDamge",
        icon: "mdi-magnify",
        color: "success",
        count: this.handoverReportStore._pageProvider.totalItems,
        exec: this.goToHandoverReports
      },
      {
        text: "Begehungen",
        key: "createInspection",
        icon: "mdi-note-edit-outline",
        color: "warning",
        count: this.value.inspections?.length,
        exec: this.goToInspections
      },
      {
        text: this.$t("objects.snapshot.title"),
        key: "showDetails",
        icon: "mdi-code-tags",
        color: "info",
        count: this.snapshots.length,
        exec: this.goToHandoverSnapshots
      },
      {
        text: "Aktivitäten",
        key: "showActivities",
        icon: "mdi-timeline-check-outline",
        color: "black"
      },
      {
        text: "Analysen",
        key: "showAnalysis",
        icon: "mdi-chart-line",
        color: "grey"
      }
    ];
  }

  get quickLinks() {
    return [
      {
        text: "Neuer Befund",
        description: "Dokumentieren Sie Schäden und Befunde am Fahrzeug",
        key: "newFinding",
        color: "success",
        exec: () => (this.$refs.findingCreateDialog as CreateHandoverFindingDialog)?.open(),
        disabled: !this.value.inspections.length
      },
      {
        text: "Rundgang starten",
        description: "Starten Sie einen Rundgang um alle Befunde zu begutachten.",
        key: "startTour",
        color: "success",
        exec: () => (this.$refs.tourDialog as TourHandoverFindingDialog)?.open()
      },
      {
        text: "Begehung erstellen",
        description: "Legen Sie eine Begehung mit Ort, Datum, Teilnehmenden und mehr an.",
        key: "newInspection",
        color: "warning",
        exec: () => (this.$refs.inspectionCreateDialog as InspectionCreateDialog)?.open()
      },
      {
        text: this.$t("objects.snapshot.create").toString(),
        description: this.$t("objects.snapshot.description").toString(),
        key: "newProtocol",
        color: "info",
        exec: () => (this.createSnapshotDialog = true)
      }
    ];
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "state",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "open", displayValue: "Offen", color: "success" },
          { value: "closed", displayValue: "Kunde", color: "grey" }
        ]
      },
      {
        key: "description",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "owner",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "keeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "newKeeper",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "timestamp.created",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      }
    ];
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.value.id
    };
  }

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100
      },
      {
        text: "Title",
        value: "title",
        width: 90
      },
      {
        text: "Message",
        value: "message",
        width: 90
      }
    ];
  }

  get partner() {
    return PartnerModule.partner;
  }

  get isMail() {
    return !!this.value.attendees;
  }

  get mails() {
    return this.value.attendees.map(u => u.email).join("; ");
  }

  get snapshots() {
    return HandoverSnapshotModule.entities;
  }

  goToHandoverReports() {
    new GoToHelper(this.$router).goToHandoverReportTableView(this.value.id, this.value.partnerId, undefined, false);
  }

  goToHandoverSnapshots() {
    new GoToHelper(this.$router).goToSnapshotTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspections() {
    new GoToHelper(this.$router).goToInspectionTableView(this.value.id, this.value.partnerId, false);
  }

  goToInspection(id: string) {
    new GoToHelper(this.$router).goToInspectionDetail(id, this.value.id, this.value.partnerId, false);
  }

  goToVehicle(value: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(value.id, value.partnerId, VehicleTabs.HOME, true);
  }

  companyCopy: ICompany = {
    companyName: "",
    email: "",
    phone: ""
  };

  startUpdateCompany(company: ICompanyKeys) {
    this.isCompanyMenu[company] = true;
    if (this.value[company]) {
      this.companyCopy.companyName = this.value[company]?.companyName ?? "";
      this.companyCopy.email = this.value[company]?.email ?? "";
      this.companyCopy.phone = this.value[company]?.phone ?? "";
    }
  }

  abortUpdate(company: ICompanyKeys) {
    if (this.value[company]) {
      this.companyCopy.companyName = this.value[company]?.companyName ?? "";
      this.companyCopy.email = this.value[company]?.email ?? "";
      this.companyCopy.phone = this.value[company]?.phone ?? "";
    }
    this.isCompanyMenu.keeper = false;
    this.isCompanyMenu.newKeeper = false;
    this.isCompanyMenu.owner = false;
  }

  async updateCompany(company: ICompanyKeys) {
    this.isUpdatingCompany = true;
    await this.value.updatePartial({ [company]: this.companyCopy }).catch(handleError);
    this.isUpdatingCompany = false;
    this.isCompanyMenu.keeper = false;
    this.isCompanyMenu.newKeeper = false;
    this.isCompanyMenu.owner = false;
  }
}
