import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { IReport } from "@/models/report.entity";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { ProgressStatusEnum } from "../enum/partner/progress.status.enum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PaginationFilterListElement } from "./base-pagination.store";
import { HandoverModule } from "./handover.store";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";

/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handoverReports",
  store
})
export class HandoverReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  _data = ReportDataAccessLayer;
  _pageProvider = ReportPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "title", type: PageFilterTypes.STRING },
    {
      key: "progressStatus",
      type: PageFilterTypes.ENUM,
      config: { items: [ProgressStatusEnum.NEW, ProgressStatusEnum.IN_PROGRESS, ProgressStatusEnum.FINISHED] }
    },
    {
      key: "handover.handoverId",
      type: PageFilterTypes.OBJECT_ID,
      config: {
        itemCallback: () => HandoverModule.entities,
        mapItemToComponent: (item: any) => {
          return { item };
        },
        itemValue: "id",
        component: "refs-handover"
      }
    }
  ].map(f => new PaginationFilterListElement(f));
}

export const HandoverReportModule = getModule(HandoverReportStore);
