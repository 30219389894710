import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import snapshotService from "@/services/mrfiktiv/services/snapshotService";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { BaseStore } from "../base.store";
import { PaginationFilterListElement } from "./base-pagination.store";

export const HandoverSnapshotDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ISnapshot> {
  protected mapEntity(entity: ISnapshot): ISnapshot {
    return new Snapshot(entity);
  }

  getIdentifier(entity: ISnapshot): string {
    return entity.id;
  }
})();

/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handoverSnapshot",
  store
})
export class HandoverSnapshotStore extends BaseStore<ISnapshot> {
  _data = HandoverSnapshotDataAccessLayer;

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = Snapshot.filterables;

  @Action
  async fetchAll(data: { partnerId: string; handoverId: string }) {
    const res = await snapshotService.findAllByHandoverId(data.partnerId, data.handoverId);

    const snapshots = res.map(e => new Snapshot(e));

    for (const s of snapshots) {
      this._data.set(s);
    }

    return res;
  }
}

export const HandoverSnapshotModule = getModule(HandoverSnapshotStore);
