import { render, staticRenderFns } from "./PartnerFormsDetailView.vue?vue&type=template&id=d2666894&"
import script from "./PartnerFormsDetailView.vue?vue&type=script&lang=ts&"
export * from "./PartnerFormsDetailView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d2666894')) {
      api.createRecord('d2666894', component.options)
    } else {
      api.reload('d2666894', component.options)
    }
    module.hot.accept("./PartnerFormsDetailView.vue?vue&type=template&id=d2666894&", function () {
      api.rerender('d2666894', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/portal/PartnerFormsDetailView.vue"
export default component.exports