



































import { ddmmyyyhhmm } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IInspection } from "@/models/inspection.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import InspectionCreateDialog from "./InspectionCreateDialog.vue";

@Component({
  components: { TableWrapper, InspectionCreateDialog }
})
export default class InspectionTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  handover!: IHandover;

  createDialogActive = false;

  openDialog() {
    this.createDialogActive = true;
  }

  get inspections(): IInspection[] {
    return this.handover?.inspections || [];
  }

  search = "";

  ddmmyyyhhmm(date: number) {
    return ddmmyyyhhmm(date);
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push(
      {
        text: $t("objects.inspection.start"),
        value: "start",
        width: "200px"
      },
      {
        text: $t("objects.inspection.title"),
        value: "title"
      },
      {
        text: $t("objects.inspection.description"),
        value: "description"
      },
      {
        text: $t("objects.inspection.address"),
        value: "address"
      },
      {
        text: $t("objects.inspection.timestamp.created"),
        value: "timestamp.created"
      }
    );

    return headers;
  }
}
