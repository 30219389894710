











import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import InspectionDetail from "@/components/handover/InspectionDetail.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";

@Component({
  components: {
    TheLayoutPortal,
    InspectionDetail,
    MActionList,
    LatestEntriesCardEmpty
  }
})
export default class InspectionDetailView extends mixins(Vue, PermissionMixin) {
  isLoading = false;

  inspection: IInspection | null = null;

  vehicle: IVehicle | null = null;

  handover: IHandover | null = null;

  async fetchVehicle() {
    const partnerId = this.$route.params.partnerId;
    const vehicleId = this.handover?.vehicleId;

    const vehicle = await new Vehicle({
      partnerId: partnerId,
      id: vehicleId
    }).fetch();

    this.vehicle = vehicle;
  }

  async fetchInspection() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;
    const inspectionId = this.$route.params.inspectionId;

    const inspection = await new Inspection({
      partnerId: partnerId,
      handoverId: handoverId,
      id: inspectionId
    }).fetch();

    this.inspection = inspection;
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.fetchHandover();
      await this.fetchInspection();
      await this.fetchVehicle();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
