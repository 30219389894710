var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line,list-item-two-line ,list-item-two-line"
            }
          })
        : _vm.value
        ? _c(
            "div",
            [
              _c("m-header", {
                attrs: {
                  title: _vm.value.title,
                  actions: _vm.actions,
                  chips: _vm.chips,
                  breadCrumbs: _vm.breadCrumbs
                },
                on: {
                  actionClicked: _vm.processAction
                }
              }),
              _vm.loadingDocuments
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: ""
                    }
                  })
                : _c("v-divider"),
              _c(
                "m-detail-view-grid",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "details",
                      fn: function fn() {
                        return [
                          _c("m-detail-table", {
                            attrs: {
                              title: _vm.$t("about"),
                              i18nPath:
                                "components.fleet.FleetVehicleDetailContractTableLeasingInputs",
                              item: _vm.value,
                              config: _vm.detailTableConfig,
                              customFieldValues: _vm.customFieldsForm,
                              showMoreAction: _vm.tableShowMoreAction
                            },
                            on: {
                              actionClicked: _vm.processAction
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function fn() {
                        return [
                          _c("m-action-list", {
                            attrs: {
                              divider: true,
                              actions: _vm.actions
                            },
                            on: {
                              actionClicked: _vm.processAction
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.startDate || _vm.endDate
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-4 mb-2"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.nouns.contract")) + " "
                          ),
                          _vm.startDate
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": true,
                                    "open-on-hover": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function fn(_ref) {
                                          var on = _ref.on,
                                            attrs = _ref.attrs
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("beginsOn", {
                                                      date: ""
                                                    })
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "a",
                                                _vm._g(
                                                  _vm._b({}, "a", attrs, false),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.readableStartDate
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    984872844
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-date-picker", {
                                        staticClass: "ma-1",
                                        attrs: {
                                          readonly: true,
                                          label: _vm.i18n["startDate"]
                                        },
                                        model: {
                                          value: _vm.startDateCopy,
                                          callback: function callback($$v) {
                                            _vm.startDateCopy = $$v
                                          },
                                          expression: "startDateCopy"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.startDate && _vm.endDate
                            ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("and")))])
                            : _vm._e(),
                          _vm.endDate
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function fn(_ref2) {
                                          var on = _ref2.on,
                                            attrs = _ref2.attrs
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("endsOn", {
                                                      date: ""
                                                    })
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "a",
                                                _vm._g(
                                                  _vm._b({}, "a", attrs, false),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.readableEndDate
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1564668811
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-date-picker", {
                                        staticClass: "ma-1",
                                        attrs: {
                                          readonly: true,
                                          label: _vm.i18n["endDate"],
                                          color:
                                            _vm.endDateCopy <= _vm.startDateCopy
                                              ? "red"
                                              : "info"
                                        },
                                        model: {
                                          value: _vm.endDateCopy,
                                          callback: function callback($$v) {
                                            _vm.endDateCopy = $$v
                                          },
                                          expression: "endDateCopy"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "ml-n1"
                            },
                            [_vm._v(".")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "mx-2",
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("v-spacer"),
                      !_vm.isEditDescription
                        ? _c("v-btn", {
                            attrs: {
                              elevation: 0,
                              text: "",
                              "x-small": ""
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("edit"))
                            },
                            on: {
                              click: _vm.startEditDescription
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("template-editor", {
                    key:
                      "template-editor-description-key" +
                      _vm.descriptionEditorKeys,
                    staticClass: "mb-10 mx-2",
                    staticStyle: {
                      height: "180px"
                    },
                    attrs: {
                      readOnly: !_vm.isEditDescription,
                      outlined: true
                    },
                    model: {
                      value: _vm.descriptionCopy,
                      callback: function callback($$v) {
                        _vm.descriptionCopy = $$v
                      },
                      expression: "descriptionCopy"
                    }
                  }),
                  _vm.isEditDescription
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-2 mx-2",
                          staticStyle: {
                            display: "flex"
                          }
                        },
                        [
                          _c("v-btn", {
                            staticClass: "mr-2",
                            attrs: {
                              elevation: 0,
                              small: "",
                              color: "info"
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("save"))
                            },
                            on: {
                              click: _vm.saveEditDesciption
                            }
                          }),
                          _c("v-btn", {
                            attrs: {
                              elevation: 0,
                              small: "",
                              outlined: "",
                              color: "info"
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("close"))
                            },
                            on: {
                              click: _vm.abortEditDesciption
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.READ,
                        a: _vm.ResourceEnum.DOCUMENT,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mx-2"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex"
                              }
                            },
                            [
                              _c("v-spacer"),
                              _c("v-btn", {
                                attrs: {
                                  loading: _vm.loadingDocumentSuggestions,
                                  elevation: 0,
                                  text: "",
                                  "x-small": ""
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "components.fleet.FleetVehicleDetailContractTableLeasingInputs.addDocument"
                                    )
                                  )
                                },
                                on: {
                                  click: _vm.initiateFileUpload
                                }
                              })
                            ],
                            1
                          ),
                          _vm.loadingDocuments && !_vm.documentIds.length
                            ? _c("v-skeleton-loader", {
                                attrs: {
                                  type: "image"
                                }
                              })
                            : _vm.documentIds.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "mt-2"
                                },
                                _vm._l(_vm.documentIds, function(
                                  documentId,
                                  index
                                ) {
                                  return _c(
                                    "v-card",
                                    {
                                      key:
                                        "referenceDocuments" +
                                        index +
                                        documentId,
                                      staticClass: "mb-1",
                                      attrs: {
                                        outlined: "",
                                        elevation: 0
                                      }
                                    },
                                    [
                                      _c("contract-card-document", {
                                        attrs: {
                                          documentId: documentId,
                                          partnerId: _vm.partnerId
                                        },
                                        on: {
                                          deleteReference: _vm.deleteReference
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c("v-divider"),
                  _c("activity-card", {
                    attrs: {
                      partner: _vm.partner,
                      source: _vm.source,
                      config: {
                        comment: true,
                        mail: false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.addDocumentDialog
        ? _c("document-selection-dialog", {
            attrs: {
              activity: _vm.ActivityTypeEnum.CREATE_DOCUMENT,
              folder: _vm.vehicle
                ? "/vehicle/".concat(_vm.vehicle.id)
                : _vm.vehicleId
                ? "/vehicle/".concat(_vm.vehicleId)
                : "",
              isDialogActive: _vm.addDocumentDialog,
              createActivityOnSelect: false,
              reference: _vm.reference,
              partnerId: _vm.partnerId,
              documentSuggestions: _vm.documentSuggestions
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.addDocumentDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.addDocumentDialog = $event
              },
              created: _vm.addReferenceToContract
            }
          })
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isConfirmDeleteContractDialogActive,
          title: _vm.$t("components.fleet.contract.confirmDeleteContract", {
            name: _vm.value.number
          }),
          leftText: _vm.$t("common.verbs.cancel"),
          rightText: _vm.$t("common.verbs.remove")
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteContractDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteContractDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isConfirmDeleteContractDialogActive = false
          },
          rightClick: _vm.deleteContract
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }