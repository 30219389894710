var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-card",
        {
          attrs: {
            loading: _vm.loading,
            flat: "",
            outlined: "",
            tile: ""
          }
        },
        [
          _c(
            "template",
            {
              slot: "progress"
            },
            [
              _c("v-progress-linear", {
                attrs: {
                  color: _vm.loadingColor,
                  height: "3",
                  indeterminate: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              attrs: {
                src: _vm.headerUrl,
                gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                height: "200px"
              }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "align-start "
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-truncate"
                    },
                    [_vm._v(" " + _vm._s(_vm.partner.companyName) + " ")]
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "v-container",
        [
          _c("debug", [
            _vm._v(
              "All (" +
                _vm._s(_vm.allExamples.length) +
                ") " +
                _vm._s(_vm.allExamples)
            )
          ]),
          _c("debug", [
            _vm._v(
              "Filtered(" +
                _vm._s(_vm.filteredExamples.length) +
                ") " +
                _vm._s(_vm.filteredExamples)
            )
          ]),
          _c(
            "v-row",
            {
              staticClass: "match-height"
            },
            [
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.REPORT,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("statistics-card", {
                        attrs: {
                          loading: _vm.loadingReport,
                          change: _vm.schadensmeldungStats.change,
                          icon: _vm.schadensmeldungStats.icon,
                          statistics: _vm.schadensmeldungStats.statistics,
                          "stat-title": _vm.schadensmeldungStats.statTitle,
                          subtitle: _vm.schadensmeldungStats.subtitle,
                          items: _vm.reports
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.FORM,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("statistics-card", {
                        attrs: {
                          loading: _vm.loadingForm,
                          change: _vm.landingzoneStats.change,
                          icon: _vm.landingzoneStats.icon,
                          statistics: _vm.landingzoneStats.statistics,
                          "stat-title": _vm.landingzoneStats.statTitle,
                          subtitle: _vm.landingzoneStats.subtitle,
                          items: _vm.forms
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "match-height"
            },
            [
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.REPORT,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("latest-report-entries-card", {
                        attrs: {
                          loading: _vm.loadingReport
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.FORM,
                _vm.partnerId
              )
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        lg: "6",
                        md: "6",
                        sm: "12"
                      }
                    },
                    [
                      _c("latest-form-entries-card", {
                        attrs: {
                          loading: _vm.loadingForm
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }