import { render, staticRenderFns } from "./TicketTableCard.vue?vue&type=template&id=64efdf5c&"
import script from "./TicketTableCard.vue?vue&type=script&lang=ts&"
export * from "./TicketTableCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VSlideXReverseTransition})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64efdf5c')) {
      api.createRecord('64efdf5c', component.options)
    } else {
      api.reload('64efdf5c', component.options)
    }
    module.hot.accept("./TicketTableCard.vue?vue&type=template&id=64efdf5c&", function () {
      api.rerender('64efdf5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/project/TicketTableCard.vue"
export default component.exports