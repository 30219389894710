var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("credential.name"),
          hint: _vm.$t("credential.nameHint"),
          "persistent-hint": "",
          required: "",
          rules: _vm.requiredRule,
          outlined: ""
        },
        model: {
          value: _vm.credential.name,
          callback: function callback($$v) {
            _vm.$set(_vm.credential, "name", $$v)
          },
          expression: "credential.name"
        }
      }),
      _vm._l(_vm.credential.permissions, function(pem, idx) {
        return _c(
          "div",
          {
            key: idx
          },
          [
            _c(
              "v-card-text",
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("credential.permissionsNumber", {
                        number: idx + 1
                      })
                    ) +
                    " "
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-2",
                    attrs: {
                      elevation: "0",
                      small: "",
                      color: "error"
                    },
                    on: {
                      click: function click($event) {
                        return _vm.removePermission(idx)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("credential.remove")) + " ")]
                )
              ],
              1
            ),
            _c("v-autocomplete", {
              attrs: {
                rules: _vm.requiredRule,
                items: _vm.availableRessources,
                type: "text",
                label: _vm.$t("credential.ressource"),
                outlined: "",
                required: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _c("v-list-item-title", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("BackendResourceEnum.".concat(item))
                              ) +
                              " "
                          )
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item) + " ")
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: pem.type,
                callback: function callback($$v) {
                  _vm.$set(pem, "type", $$v)
                },
                expression: "pem.type"
              }
            }),
            _c("v-select", {
              attrs: {
                label: _vm.$t("credential.action"),
                items: _vm.actions,
                outlined: "",
                chips: "",
                "small-chips": "",
                multiple: ""
              },
              model: {
                value: pem.action,
                callback: function callback($$v) {
                  _vm.$set(pem, "action", $$v)
                },
                expression: "pem.action"
              }
            }),
            _c("v-divider")
          ],
          1
        )
      }),
      _c(
        "v-btn",
        {
          staticClass: "my-4",
          on: {
            click: _vm.addPem
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t(_vm.addPemBtnTxt)))]
      ),
      _c("v-switch", {
        attrs: {
          label: _vm.$t("credential.isActive")
        },
        model: {
          value: _vm.credential.isActive,
          callback: function callback($$v) {
            _vm.$set(_vm.credential, "isActive", $$v)
          },
          expression: "credential.isActive"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }