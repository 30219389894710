import { render, staticRenderFns } from "./FleetVehicleDriverDetailTable.vue?vue&type=template&id=37627f54&scoped=true&"
import script from "./FleetVehicleDriverDetailTable.vue?vue&type=script&lang=ts&"
export * from "./FleetVehicleDriverDetailTable.vue?vue&type=script&lang=ts&"
import style0 from "./FleetVehicleDriverDetailTable.vue?vue&type=style&index=0&id=37627f54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37627f54",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VChip,VDivider,VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemTitle,VMenu,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37627f54')) {
      api.createRecord('37627f54', component.options)
    } else {
      api.reload('37627f54', component.options)
    }
    module.hot.accept("./FleetVehicleDriverDetailTable.vue?vue&type=template&id=37627f54&scoped=true&", function () {
      api.rerender('37627f54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/fleet/FleetVehicleDriverDetailTable.vue"
export default component.exports