var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("input", {
        ref: "uploader",
        staticStyle: {
          display: "none"
        },
        attrs: {
          "data-test-report-image-upload": "",
          name: "file",
          multiple: "",
          type: "file",
          accept: _vm.accept
        },
        on: {
          change: _vm.onFileInputEvent
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: true
          },
          model: {
            value: _vm.uploadingDialog,
            callback: function callback($$v) {
              _vm.uploadingDialog = $$v
            },
            expression: "uploadingDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: "",
                loading: _vm.isLoading
              }
            },
            [
              _c(
                "template",
                {
                  slot: "progress"
                },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      height: "10",
                      indeterminate: ""
                    }
                  })
                ],
                1
              ),
              _c("v-card-title", [_vm._v("Bilder werden hochgeladen")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "fill-height ma-0 pt-2",
                      attrs: {
                        align: "center",
                        justify: "center"
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 70,
                          width: 3,
                          color: "primary",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("debug", {
                    attrs: {
                      debug: _vm.displayImages
                    }
                  })
                ],
                1
              ),
              _c("v-card-actions", [
                _vm._v(
                  " " +
                    _vm._s(_vm.displayImages.length) +
                    " / " +
                    _vm._s(_vm.fileCount) +
                    " "
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }