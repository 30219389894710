var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              "hide-details": "",
              label: "Search",
              "single-line": ""
            },
            model: {
              value: _vm.search,
              callback: function callback($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          loading: _vm.loading,
          headers: _vm.headers,
          "item-class": _vm.itemRowBackground,
          items: _vm.fahrzeugscheine,
          search: _vm.search,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc
        },
        on: {
          "click:row": _vm.click
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }