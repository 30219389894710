var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("debug", [
        _vm._v(
          " TicketTableView " +
            _vm._s(_vm.partnerId) +
            " " +
            _vm._s(_vm.projectId) +
            " " +
            _vm._s(_vm.ticketNumber) +
            " "
        )
      ]),
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              breadCrumbs: _vm.breadCrumbList,
              title: _vm.$t("project.project.viewTickets"),
              chips: _vm.chips
            }
          }),
          _c("ticket-table-card", {
            ref: "TicketTableCard",
            attrs: {
              refs: _vm.refs,
              loadingTickets: _vm.loadingTickets,
              loadingPartnerUsers: _vm.loadingPartnerUsers,
              loadingProjects: _vm.loadingProjects,
              partnerId: _vm.partnerId,
              updateUrl: true
            },
            on: {
              refresh: _vm.refresh
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }