var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: "Details for OS-MM 123 E",
              chips: _vm.chips,
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions
            },
            on: {
              actionClicked: _vm.processAction
            }
          }),
          _c(
            "m-detail-view-grid",
            {
              attrs: {
                bottomSheet: false
              },
              scopedSlots: _vm._u([
                {
                  key: "details",
                  fn: function fn() {
                    return [
                      _c("m-detail-table", {
                        attrs: {
                          title: _vm.$t("about"),
                          description:
                            "Diese Eigenschaften werden angezeigt, wenn Sie Informationen zu ihrem Fahrzeug aufrufen. Sie können die Ansicht individualisieren, in dem Sie das Fahrzeug einer Flotte zuweisen.",
                          i18nPath: "designGuide.MDetailTable",
                          item: _vm.testItem,
                          config: _vm.detailTableConfig,
                          customFieldValues: _vm.customFieldValuesTable,
                          copyClipboard: false,
                          scrollableOnMobile: false
                        },
                        on: {
                          actionClicked: _vm.processAction
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("m-detail-form", {
                attrs: {
                  title: _vm.$t("designGuide.MDetailForm.allProps"),
                  item: _vm.formTestItem,
                  customFields: _vm.customFieldsForm,
                  config: _vm.config,
                  syncChanges: _vm.syncChanges,
                  abortChanges: _vm.abortChanges
                },
                on: {
                  "update:item": function updateItem($event) {
                    _vm.formTestItem = $event
                  },
                  "update:config": function updateConfig($event) {
                    _vm.config = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }