var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type:
                "card-heading, divider, list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
            }
          })
        : _vm.vehicle && _vm.contract
        ? _c(
            "v-container",
            {
              staticClass: "wrapper"
            },
            [
              _c("contract-card", {
                attrs: {
                  loading: _vm.loading,
                  partnerId: _vm.partnerId,
                  vehicle: _vm.vehicle,
                  hideToDetail: true,
                  hideBreadcrumbs: false,
                  documentSuggestions: _vm.vehicleDocuments,
                  loadingDocumentSuggestions: _vm.loadingDocumentSuggestions
                },
                on: {
                  save: _vm.onUpdateContract,
                  delete: _vm.onDeleteContract
                },
                model: {
                  value: _vm.contract,
                  callback: function callback($$v) {
                    _vm.contract = $$v
                  },
                  expression: "contract"
                }
              })
            ],
            1
          )
        : _c("latest-entries-card-empty")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }