var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        onBack: _vm.onBack
      }
    },
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader")
            : _c("service-form", {
                attrs: {
                  value: _vm.service,
                  partner: _vm.partner,
                  resources: _vm.allResources,
                  isLoading: _vm.isLoading
                },
                on: {
                  input: _vm.onUpdateService
                }
              }),
          _c(
            "v-btn",
            {
              staticClass: "mt-4 mr-4",
              attrs: {
                color: "primary",
                large: ""
              },
              on: {
                click: _vm.save
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("views.booking.ServiceDetail.save")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }