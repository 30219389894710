var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: ""
            .concat(_vm.$t("components.fleet.contract.detailsFor"), " ")
            .concat(_vm.value.summary),
          breadCrumbs: _vm.breadCrumbs,
          alerts: _vm.alerts,
          chips: _vm.chips,
          actions: [_vm.recurring]
        },
        on: {
          actionClicked: _vm.processAction,
          alertClicked: _vm.processAction
        }
      }),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: ""
            }
          })
        : _c("v-divider"),
      _c(
        "m-detail-view-grid",
        {
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function fn() {
                return [
                  _c("m-detail-table", {
                    attrs: {
                      title: _vm.$t("about"),
                      i18nPath: "objects.event",
                      item: _vm.value,
                      config: _vm.detailTableConfig
                    },
                    on: {
                      actionClicked: _vm.processAction
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "slot.userId",
                        fn: function fn(_ref) {
                          var item = _ref.item
                          return [
                            _c("refs-user", {
                              staticClass: "ma-n2 mx-n4",
                              attrs: {
                                item: _vm.getUserFromId(item.value)
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "slot.timestamp.created",
                        fn: function fn(_ref2) {
                          var item = _ref2.item
                          return [
                            item.value
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.simpleDoubleDigitDate(item.value)
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "slot.rrule",
                        fn: function fn(_ref3) {
                          var item = _ref3.item
                          return [
                            item.rrule
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      item.recurrenceDescription
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "slot.ack",
                        fn: function fn(_ref4) {
                          var item = _ref4.item
                          return [
                            item.value
                              ? _c("refs-user", {
                                  staticClass: "ma-n2 mx-n4",
                                  attrs: {
                                    item: _vm.getUserFromId(item.value.by)
                                  }
                                })
                              : _vm._e(),
                            item.value
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.simpleDoubleDigitDate(item.value.at)
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "slot.start",
                        fn: function fn() {
                          return [
                            _c("span", [
                              _vm.value.start
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.simpleDoubleDigitDate(
                                            new Date(
                                              _vm.value.start
                                            ).toISOString()
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.value.isAllDay
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatHoursAndMinutes(
                                            new Date(_vm.value.start)
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.end",
                        fn: function fn() {
                          return [
                            _vm.value.end
                              ? _c("span", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.simpleDoubleDigitDate(
                                            new Date(
                                              _vm.value.end
                                            ).toISOString()
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm.value.isAllDay
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("objects.event.isAllDay")
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatHoursAndMinutes(
                                                new Date(_vm.value.end)
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.ruleOptions",
                        fn: function fn() {
                          return [
                            _c("event-recurring", {
                              attrs: {
                                value: _vm.value
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function fn() {
                return [
                  _c("m-action-list", {
                    attrs: {
                      divider: true,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: _vm.processAction
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("m-detail-form", {
            ref: "contractDetailForm",
            attrs: {
              title: _vm.$t("designGuide.MDetailForm.allProps"),
              description: _vm.$t("components.fleet.contract.details"),
              item: _vm.value,
              config: _vm.config,
              syncChanges: _vm.trySyncChanges,
              abortChanges: _vm.abortChanges,
              extraPanelsCount: 1
            },
            on: {
              "update:item": function updateItem($event) {
                _vm.value = $event
              },
              "update:config": function updateConfig($event) {
                _vm.config = $event
              }
            }
          })
        ],
        1
      ),
      _vm.isWarnRecurringChangeDialog
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isWarnRecurringChangeDialog
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isWarnRecurringChangeDialog = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isWarnRecurringChangeDialog = $event
                },
                close: _vm.abortChanges,
                leftClick: _vm.abortChanges,
                rightClick: _vm.syncChanges
              }
            },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    border: "left",
                    color: "orange",
                    icon: "mdi-alert",
                    type: "warning"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("views.fleet.EventCard.recurringChangeWarning")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isAcknowledgeDialog
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isAcknowledgeDialog,
              title: _vm.$t("views.fleet.EventCard.markAsAcknowledged")
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isAcknowledgeDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isAcknowledgeDialog = $event
              },
              close: function close($event) {
                _vm.isAcknowledgeDialog = false
              },
              leftClick: function leftClick($event) {
                _vm.isAcknowledgeDialog = false
              },
              rightClick: _vm.syncAcknowledge
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isDeleteDialogActive,
              title: _vm.$t("delete")
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isDeleteDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isDeleteDialogActive = $event
              },
              close: function close($event) {
                _vm.isDeleteDialogActive = false
              },
              leftClick: function leftClick($event) {
                _vm.isDeleteDialogActive = false
              },
              rightClick: _vm.confirmDeletion
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }