var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-stepper",
            {
              attrs: {
                flat: ""
              },
              model: {
                value: _vm.currentStep,
                callback: function callback($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep"
              }
            },
            [
              _vm.isBigScreen
                ? _c(
                    "v-stepper-header",
                    {
                      staticClass: "elevation-0"
                    },
                    _vm._l(_vm.steps.slice(0, -1), function(step, index) {
                      return _c("v-stepper-step", {
                        key: index,
                        attrs: {
                          complete: _vm.currentStep > index,
                          step: index + 1
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "v-stepper-items",
                [
                  _vm._l(_vm.steps, function(step, index) {
                    return _c(
                      "v-stepper-content",
                      {
                        key: index,
                        attrs: {
                          step: index
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: {
                              hidden: _vm.loadingActive
                            }
                          },
                          [
                            _vm.isStepActive(step, index, "DESCRIPTION")
                              ? _c("partner-manage-stepper-description-step", {
                                  attrs: {
                                    goToPrevious: _vm.goToPrevious,
                                    goToNext: _vm.goToNext,
                                    currentStep: _vm.currentStep + 1,
                                    totalSteps: _vm.steps.length,
                                    descriptionType: step.descriptionType,
                                    setLoading: _vm.setLoading
                                  }
                                })
                              : _vm.isStepActive(
                                  step,
                                  index,
                                  "PREDEFINED_SETTING"
                                )
                              ? _c(
                                  "partner-manage-stepper-predefined-setting-step",
                                  {
                                    attrs: {
                                      settingKey: step.key,
                                      goToNext: _vm.goToNext,
                                      goToPrevious: _vm.goToPrevious,
                                      currentStep: _vm.currentStep + 1,
                                      totalSteps: _vm.steps.length,
                                      forceEncrypt: step.forceEncrypt,
                                      setLoading: _vm.setLoading,
                                      rules: step.rules,
                                      items: step.items
                                    }
                                  }
                                )
                              : _vm.isStepActive(step, index, "CUSTOM_SETTING")
                              ? _c(
                                  "partner-manage-stepper-custom-setting-step",
                                  {
                                    attrs: {
                                      settingKey: step.key,
                                      goToNext: _vm.goToNext,
                                      goToPrevious: _vm.goToPrevious,
                                      currentStep: _vm.currentStep + 1,
                                      totalSteps: _vm.steps.length,
                                      setLoading: _vm.setLoading
                                    }
                                  }
                                )
                              : _vm.isStepActive(step, index, "SLOT")
                              ? _c(
                                  "partner-manage-stepper-slot-step",
                                  {
                                    attrs: {
                                      goToNext: _vm.goToNext,
                                      goToPrevious: _vm.goToPrevious,
                                      currentStep: _vm.currentStep + 1,
                                      totalSteps: _vm.steps.length,
                                      setLoading: _vm.setLoading,
                                      preHook: step.preHook,
                                      postHook: step.postHook
                                    }
                                  },
                                  [_vm._t("step")],
                                  2
                                )
                              : _vm.isStepActive(step, index, "CREDENTIALS")
                              ? _c(
                                  "partner-manage-stepper-verify-credentials-step",
                                  {
                                    attrs: {
                                      settingKey: step.key,
                                      goToNext: _vm.goToNext,
                                      goToPrevious: _vm.goToPrevious,
                                      currentStep: _vm.currentStep + 1,
                                      totalSteps: _vm.steps.length,
                                      setLoading: _vm.setLoading,
                                      context: step.context
                                    }
                                  }
                                )
                              : _vm.isStepActive(step, index, "SETUP")
                              ? _c("partner-manage-stepper-setup-step", {
                                  attrs: {
                                    settingKey: step.key,
                                    goToNext: _vm.goToNext,
                                    goToPrevious: _vm.goToPrevious,
                                    currentStep: _vm.currentStep + 1,
                                    totalSteps: _vm.steps.length,
                                    setLoading: _vm.setLoading,
                                    setupSettings: step.setupSettings,
                                    context: step.context
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%"
                      },
                      attrs: {
                        hidden: !_vm.loadingActive
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        staticClass: "ma-auto mb-10",
                        staticStyle: {
                          width: "100%"
                        },
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "green",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }