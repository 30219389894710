import { render, staticRenderFns } from "./PartnerManageEditSetting.vue?vue&type=template&id=4556bdf0&"
import script from "./PartnerManageEditSetting.vue?vue&type=script&lang=ts&"
export * from "./PartnerManageEditSetting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDialog,VFlex,VIcon,VSpacer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4556bdf0')) {
      api.createRecord('4556bdf0', component.options)
    } else {
      api.reload('4556bdf0', component.options)
    }
    module.hot.accept("./PartnerManageEditSetting.vue?vue&type=template&id=4556bdf0&", function () {
      api.rerender('4556bdf0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PartnerManageEditSetting.vue"
export default component.exports