var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          breadCrumbs: _vm.breadCrumbList
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function fn() {
                return [
                  !_vm.isEdit
                    ? _c(
                        "div",
                        {
                          class: _vm.isSideCard ? "mt-3" : "",
                          staticStyle: {
                            display: "flex"
                          }
                        },
                        [
                          _vm.isSideCard
                            ? _c(
                                "h4",
                                {
                                  staticClass: "title"
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: _vm.ticketTo,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s("#" + _vm.ticket.number) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.ticket.title) + " ")
                                ],
                                1
                              )
                            : _c(
                                "h4",
                                {
                                  staticClass: "title"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s("#" + _vm.ticket.number) +
                                      " " +
                                      _vm._s(_vm.ticket.title) +
                                      " "
                                  )
                                ]
                              ),
                          _c("v-spacer"),
                          _c(
                            "div",
                            [
                              _vm.isMobile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: _vm.startEdit
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        text: ""
                                      },
                                      on: {
                                        click: _vm.startEdit
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("project.ticket.editTitle")
                                          )
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        {
                          class: _vm.isSideCard ? "mt-3" : "",
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pa-0"
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("project.ticket.title"),
                                  dense: "",
                                  required: "",
                                  rules: _vm.requiredRule,
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.editTitle,
                                  callback: function callback($$v) {
                                    _vm.editTitle = $$v
                                  },
                                  expression: "editTitle"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    elevation: "0",
                                    color: "info",
                                    disabled: !_vm.editTitle
                                  },
                                  on: {
                                    click: _vm.updateTicketTitle
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    outlined: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.cancel
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("discard")) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              },
              proxy: true
            },
            !_vm.isEdit
              ? {
                  key: "subtitle",
                  fn: function fn() {
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-2 white--text",
                          attrs: {
                            rounded: "",
                            color: _vm.ticketColor(),
                            small: ""
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "project.ticketState." + _vm.ticket.state
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm.isOverdue
                        ? _c(
                            "tooltip",
                            {
                              attrs: {
                                text: _vm.dueDate
                              }
                            },
                            [
                              _vm.isOverdue
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "mr-2 white--text",
                                      attrs: {
                                        rounded: "",
                                        color: "error",
                                        outlined: "",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            left: ""
                                          }
                                        },
                                        [_vm._v("mdi-calendar")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("project.ticket.overdue")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c("v-divider"),
      _c(
        "v-row",
        {
          attrs: {
            "no-gutters": ""
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "8"
              }
            },
            [
              _c("ticket-side-card-body", {
                staticStyle: {
                  "min-height": "50px"
                },
                on: {
                  update: _vm.updateTicketBody
                },
                model: {
                  value: _vm.ticket,
                  callback: function callback($$v) {
                    _vm.ticket = $$v
                  },
                  expression: "ticket"
                }
              }),
              _vm.ticket.refs
                ? _c("ticket-refs-select", {
                    staticClass: "ml-4 mr-4 my-2",
                    attrs: {
                      partnerId: _vm.partnerId,
                      isConfirmable: true
                    },
                    on: {
                      input: _vm.updateReferences,
                      change: _vm.updateReferences
                    },
                    model: {
                      value: _vm.ticket.refs,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ticket, "refs", $$v)
                      },
                      expression: "ticket.refs"
                    }
                  })
                : _vm._e(),
              _c("v-divider"),
              _c("ticket-side-card-activities", {
                staticClass: "mx-2",
                on: {
                  updateTicketStatus: _vm.updateTicketStatus,
                  openEdit: function openEdit($event) {
                    _vm.editSheet = true
                  }
                },
                model: {
                  value: _vm.ticket,
                  callback: function callback($$v) {
                    _vm.ticket = $$v
                  },
                  expression: "ticket"
                }
              })
            ],
            1
          ),
          _c(
            "v-bottom-sheet",
            {
              attrs: {
                inset: "",
                scrollable: ""
              },
              model: {
                value: _vm.editSheet,
                callback: function callback($$v) {
                  _vm.editSheet = $$v
                },
                expression: "editSheet"
              }
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    loading: _vm.loading
                  }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("h1", [
                        _vm._v(_vm._s(_vm.$t("designGuide.MDetailForm.props")))
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.editSheet = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticStyle: {
                        height: "400px"
                      }
                    },
                    [
                      _c("ticket-form", {
                        staticClass: "pt-4 ",
                        attrs: {
                          valid: _vm.isFormValid,
                          hideBody: true,
                          hideTitle: true
                        },
                        on: {
                          "update:valid": function updateValid($event) {
                            _vm.isFormValid = $event
                          },
                          change: _vm.debounceUpdateBaseTicket,
                          "update:assignees": function updateAssignees(v) {
                            return _vm.$emit("update:assignees", v)
                          },
                          "delete:assignees": function deleteAssignees(v) {
                            return _vm.$emit("delete:assignees", v)
                          },
                          "add:assignees": function addAssignees(v) {
                            return _vm.$emit("add:assignees", v)
                          }
                        },
                        model: {
                          value: _vm.ticket,
                          callback: function callback($$v) {
                            _vm.ticket = $$v
                          },
                          expression: "ticket"
                        }
                      }),
                      _vm.project &&
                      _vm.project.configuration.customFieldConfig.length
                        ? _c(
                            "div",
                            [
                              _c("v-divider", {
                                staticClass: "pt-6"
                              }),
                              _c("custom-field-list-form", {
                                attrs: {
                                  valid: _vm.isCustomFieldValid,
                                  customFieldConfig:
                                    _vm.project.configuration.customFieldConfig
                                },
                                on: {
                                  "update:valid": function updateValid($event) {
                                    _vm.isCustomFieldValid = $event
                                  },
                                  change: _vm.debounceUpdateCustomFields
                                },
                                model: {
                                  value: _vm.ticket.values,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.ticket, "values", $$v)
                                  },
                                  expression: "ticket.values"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "v-col",
                {
                  staticClass: "pl-2",
                  attrs: {
                    cols: "12",
                    md: "4"
                  }
                },
                [
                  _c("ticket-form", {
                    staticClass: "pt-4 mr-4 ml-4 ml-md-0",
                    attrs: {
                      valid: _vm.isFormValid,
                      hideBody: true,
                      hideTitle: true
                    },
                    on: {
                      "update:valid": function updateValid($event) {
                        _vm.isFormValid = $event
                      },
                      change: _vm.debounceUpdateBaseTicket,
                      "update:assignees": function updateAssignees(v) {
                        return _vm.$emit("update:assignees", v)
                      },
                      "delete:assignees": function deleteAssignees(v) {
                        return _vm.$emit("delete:assignees", v)
                      },
                      "add:assignees": function addAssignees(v) {
                        return _vm.$emit("add:assignees", v)
                      }
                    },
                    model: {
                      value: _vm.ticket,
                      callback: function callback($$v) {
                        _vm.ticket = $$v
                      },
                      expression: "ticket"
                    }
                  }),
                  _vm.project &&
                  _vm.project.configuration.customFieldConfig.length
                    ? _c(
                        "div",
                        [
                          _c("v-divider", {
                            staticClass: "pt-6"
                          }),
                          _c("custom-field-list-form", {
                            staticClass: "mr-4 ml-4 ml-md-0",
                            attrs: {
                              valid: _vm.isCustomFieldValid,
                              customFieldConfig:
                                _vm.project.configuration.customFieldConfig
                            },
                            on: {
                              "update:valid": function updateValid($event) {
                                _vm.isCustomFieldValid = $event
                              },
                              change: _vm.debounceUpdateCustomFields
                            },
                            model: {
                              value: _vm.ticket.values,
                              callback: function callback($$v) {
                                _vm.$set(_vm.ticket, "values", $$v)
                              },
                              expression: "ticket.values"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", {
                    staticClass: "mt-6 "
                  }),
                  _c("ticket-side-card-actions", {
                    staticClass: "mb-6",
                    attrs: {
                      projectId: _vm.projectId,
                      viewId: _vm.viewId
                    },
                    on: {
                      updateTicketStatus: _vm.updateTicketStatus,
                      deleteTicket: _vm.deleteTicket
                    },
                    model: {
                      value: _vm.ticket,
                      callback: function callback($$v) {
                        _vm.ticket = $$v
                      },
                      expression: "ticket"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }