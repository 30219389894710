var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.title,
          subtitle: _vm.subtitle,
          breadCrumbs: _vm.breadCrumbs,
          actions: _vm.actions
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          }
        }
      }),
      _c(
        "m-detail-view-grid",
        {
          attrs: {
            bottomSheet: false
          },
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function fn() {
                return [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("common.nouns.vehicle")))
                      ]),
                      _vm.vehicle.loading
                        ? _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "paragraph"
                            }
                          })
                        : _vm.vehicle
                        ? _c(
                            "v-card",
                            {
                              attrs: {
                                flat: "",
                                outlined: ""
                              }
                            },
                            [
                              _c("refs-vehicle", {
                                attrs: {
                                  item: _vm.vehicle
                                },
                                on: {
                                  openDetail: _vm.goToVehicle
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("common.nouns.parties")))
                      ]),
                      _vm._l(_vm.companyKeys, function(company) {
                        return _c(
                          "v-menu",
                          {
                            key: "company" + company,
                            staticClass: "mx-4",
                            attrs: {
                              "close-on-content-click": false
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function fn(_ref) {
                                    var on = _ref.on,
                                      attrs = _ref.attrs
                                    return [
                                      _c(
                                        "v-card",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                flat: "",
                                                outlined: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.startUpdateCompany(
                                                    company
                                                  )
                                                }
                                              }
                                            },
                                            "v-card",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm.value[company].companyName ||
                                          _vm.value[company].email ||
                                          _vm.value[company].phone
                                            ? _c("v-card-subtitle", [
                                                _vm._v(
                                                  " " + _vm._s(company) + " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.value[company].companyName ||
                                          _vm.value[company].email ||
                                          _vm.value[company].phone
                                            ? _c("v-card-text", [
                                                _c("div", [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.value[company]
                                                          .companyName
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.value[company].email
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.value[company].phone
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            : _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        text: "",
                                                        block: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(company) +
                                                          " hinzufügen"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.isCompanyMenu[company],
                              callback: function callback($$v) {
                                _vm.$set(_vm.isCompanyMenu, company, $$v)
                              },
                              expression: "isCompanyMenu[company]"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _vm._v(" " + _vm._s(company) + " ")
                                ]),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        label: _vm.$t(
                                          "objects.company.companyName"
                                        )
                                      },
                                      model: {
                                        value: _vm.companyCopy.companyName,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            _vm.companyCopy,
                                            "companyName",
                                            $$v
                                          )
                                        },
                                        expression: "companyCopy.companyName"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        label: _vm.$t("objects.company.email"),
                                        rules: _vm.emailRuleOptional
                                      },
                                      model: {
                                        value: _vm.companyCopy.email,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            _vm.companyCopy,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "companyCopy.email"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        label: _vm.$t("objects.company.phone")
                                      },
                                      model: {
                                        value: _vm.companyCopy.phone,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            _vm.companyCopy,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression: "companyCopy.phone"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          text: ""
                                        },
                                        on: {
                                          click: function click() {
                                            return _vm.abortUpdate(company)
                                          }
                                        }
                                      },
                                      [_vm._v("Abbrechen")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          text: "",
                                          isLoading: _vm.isUpdatingCompany
                                        },
                                        on: {
                                          click: function click() {
                                            return _vm.updateCompany(company)
                                          }
                                        }
                                      },
                                      [_vm._v(" Speichern ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c("m-detail-table", {
                    attrs: {
                      title: "Eigenschaften",
                      i18nPath: "objects.handover",
                      item: _vm.value,
                      showMoreAction: _vm.tableShowMoreAction,
                      config: _vm.detailTableConfig
                    },
                    on: {
                      actionClicked: function actionClicked($event) {
                        return $event.exec()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "slot.owner",
                        fn: function fn() {
                          return [
                            _vm.value.owner
                              ? _c("span", [
                                  _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.value.owner.companyName)
                                      )
                                    ])
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.owner.email))
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.owner.phone))
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.keeper",
                        fn: function fn() {
                          var _vm$value$keeper$comp

                          return [
                            _vm.value.keeper
                              ? _c("span", [
                                  _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          (_vm$value$keeper$comp =
                                            _vm.value.keeper.companyName) !==
                                            null &&
                                            _vm$value$keeper$comp !== void 0
                                            ? _vm$value$keeper$comp
                                            : "EMPTY"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.keeper.email))
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.keeper.phone))
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.newKeeper",
                        fn: function fn() {
                          var _vm$value$newKeeper$c

                          return [
                            _vm.value.newKeeper
                              ? _c("span", [
                                  _c("div", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          (_vm$value$newKeeper$c =
                                            _vm.value.newKeeper.companyName) !==
                                            null &&
                                            _vm$value$newKeeper$c !== void 0
                                            ? _vm$value$newKeeper$c
                                            : "EMPTY"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.newKeeper.email))
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.value.newKeeper.phone))
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function fn() {
                return [
                  _c("m-action-list", {
                    attrs: {
                      divider: true,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: function actionClicked($event) {
                        return $event.exec()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("debug", [_vm._v("value " + _vm._s(_vm.value))]),
          _c("m-navigation-list", {
            attrs: {
              title: "Navigation",
              actions: _vm.navigation
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              }
            }
          }),
          _c("m-quick-link-list", {
            attrs: {
              title: "Schnellzugriffe",
              actions: _vm.quickLinks
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              }
            }
          }),
          _c("create-handover-finding-dialog", {
            ref: "findingCreateDialog",
            attrs: {
              vehicle: _vm.vehicle,
              handover: _vm.value
            }
          }),
          _c("create-handover-snapshot-dialog", {
            attrs: {
              value: _vm.value,
              isDialogActive: _vm.createSnapshotDialog
            },
            on: {
              close: function close($event) {
                _vm.createSnapshotDialog = false
              }
            }
          }),
          _c("tour-handover-finding-dialog", {
            ref: "tourDialog"
          }),
          _c("inspection-create-dialog", {
            ref: "inspectionCreateDialog",
            attrs: {
              handover: _vm.value
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }