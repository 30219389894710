var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "partner-manage-stepper-step-layout",
    {
      attrs: {
        title: _vm.$t(
          "components.partner.PartnerManageStepper.VerifyCredentialsStep.title"
        ),
        currentStep: _vm.currentStep,
        totalSteps: _vm.totalSteps,
        rightButtonFunction: _vm.rightButton,
        leftButtonFunction: _vm.goToPrevious
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }