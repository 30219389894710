import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import snapshotService from "@/services/mrfiktiv/services/snapshotService";
import { MrfiktivCreateSnapshotDtoGen, MrfiktivSnapshotViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverDataAccessLayer } from "@/store/modules/access-layers/handover.access-layer";
import { HandoverSnapshotDataAccessLayer } from "@/store/modules/handover-snapshot.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";

@IsFilterable
class SnapshotBase implements MrfiktivSnapshotViewModelGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.snapshot.id",
    config: {
      itemCallback: () => HandoverSnapshotDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-snapshot"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.handover.number"
  })
  number: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.id",
    config: {
      itemCallback: () => HandoverDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-handover"
    }
  })
  handoverId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.shapshot.status",
    config: {
      items: Object.values(DigitalSignatureRequestStatusEnum)
    }
  })
  status: DigitalSignatureRequestStatusEnum;

  /**
   * Construct handover
   */
  constructor(obj?: Partial<SnapshotBase | MrfiktivSnapshotViewModelGen>) {
    this.id = obj?.id ?? "";
    this.number = obj?.number ?? -1;

    this.partnerId = obj?.partnerId ?? "";
    this.handoverId = obj?.handoverId ?? "";
    this.userId = obj?.userId ?? "";

    this.status = (obj?.status as DigitalSignatureRequestStatusEnum) ?? DigitalSignatureRequestStatusEnum.CREATED;

    this.timestamp = new Timestamp(obj?.timestamp);
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    const res = await snapshotService.findOneByHandoverId(this.partnerId, this.handoverId, this.id);

    this.map(res);
    HandoverSnapshotDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  private map(obj?: MrfiktivSnapshotViewModelGen) {
    if (!obj) return;
    this.id = obj.id;
    this.number = obj.number;
    this.partnerId = obj.partnerId;
    this.handoverId = obj.handoverId;
    this.timestamp = new Timestamp(obj.timestamp);
  }

  /**
   * create fetch handover
   */
  async create(data: MrfiktivCreateSnapshotDtoGen) {
    const res = await snapshotService.createForHandover(this.partnerId, this.handoverId, data);

    this.map(res);

    HandoverSnapshotDataAccessLayer.set(this);

    return this;
  }
}

type ISnapshot = SnapshotBase;
const Snapshot = Filter.createForClass(SnapshotBase);

export { ISnapshot, Snapshot };
