var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-img",
        {
          key: _vm.src,
          class: _vm.imageClass,
          staticStyle: {
            border: "1px solid lightgrey",
            position: "relative"
          },
          attrs: {
            id: "markerImage",
            src: _vm.src,
            alt: "My Image Marker field"
          },
          on: {
            load: _vm.onImageLoad,
            mousedown: _vm.onMouseDown,
            mouseup: _vm.onMouseUp,
            mousemove: _vm.onMouseMove,
            touchstart: _vm.onTouchStart,
            touchend: _vm.onTouchEnd,
            touchmove: _vm.onTouchMove
          },
          scopedSlots: _vm._u([
            {
              key: "placeholder",
              fn: function fn() {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-center fill-height"
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "grey-lighten-4",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.showMagnifier && !_vm.deactivated
            ? _c(
                "div",
                {
                  staticClass: "magnifier",
                  style: _vm.magnifierStyle
                },
                [
                  _c("div", {
                    staticClass: "magnifier-target"
                  })
                ]
              )
            : _vm._e(),
          _vm.imageLoaded
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "relative"
                  }
                },
                _vm._l(_vm.markerPositions, function(marker, idx) {
                  return _c(
                    "span",
                    {
                      key: idx,
                      class: _vm.markerClass,
                      style: marker.style,
                      on: {
                        click: function click($event) {
                          return _vm.markerClicked(marker)
                        }
                      }
                    },
                    [
                      _vm.showText && marker.text
                        ? [_vm._v(_vm._s(marker.text))]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }