var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogModel,
            title: _vm.$t("credential.create"),
            leftLoading: _vm.isLoading,
            rightLoading: _vm.isLoading,
            rightDisabled: !_vm.isValid,
            width: "850"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialogModel = false
            },
            rightClick: _vm.update,
            close: function close($event) {
              _vm.dialogModel = false
            }
          }
        },
        [
          _c("credential-form", {
            attrs: {
              valid: _vm.isValid
            },
            on: {
              "update:valid": function updateValid($event) {
                _vm.isValid = $event
              }
            },
            model: {
              value: _vm.credential,
              callback: function callback($$v) {
                _vm.credential = $$v
              },
              expression: "credential"
            }
          }),
          _c("v-card-text", [
            _vm._v(" " + _vm._s(_vm.$t("credential.generalHint")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }