var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.CREATE,
                a: _vm.ResourceEnum.REPORT
              }
            },
            [
              _vm._t("activator", null, {
                on: _vm.open
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            width: "1200px",
            isDialogActive: _vm.isDialogActive,
            title: _vm.title,
            absolute: true,
            persistent: true,
            fullscreen: _vm.fullScreen,
            rightLoading: _vm.isRightLoading || _vm.isImageLoading,
            rightDisabled: _vm.rightDisabled,
            leftText: _vm.leftText,
            rightText: _vm.rightText,
            hideLeft: _vm.hideLeft
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.rightClick,
            leftClick: _vm.leftClick,
            close: _vm.close
          }
        },
        [
          _c(
            "v-window",
            {
              model: {
                value: _vm.step,
                callback: function callback($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.StepEnum.LOCATOR
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " Wählen Sie zunächst den Bereich aus und anschließend die genaue Position. Tipp: Wenn Sie den gedrückt halten, erscheint eine Lupe, damit Sie die Lokalisierung noch genauer vornehmen können. "
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "mx-4",
                    attrs: {
                      items: _vm.blueprints,
                      "item-text": "text",
                      "item-value": "id",
                      outlined: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function fn(_ref) {
                          var item = _ref.item
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(_vm._s(item.description))
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(_vm._s(item.type))
                                        ]),
                                        item.location
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                _vm._s(item.location.join(", "))
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function fn(_ref2) {
                          var item = _ref2.item
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(_vm._s(item.description))
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(_vm._s(item.type))
                                        ]),
                                        item.location
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                _vm._s(item.location.join(", "))
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedBlueprintId,
                      callback: function callback($$v) {
                        _vm.selectedBlueprintId = $$v
                      },
                      expression: "selectedBlueprintId"
                    }
                  }),
                  _vm.bluePrintImageUrl
                    ? _c(
                        "div",
                        [
                          _c("m-image-marker", {
                            ref: "imageMarkerCreateDialog",
                            staticClass: "mx-4",
                            attrs: {
                              src: _vm.bluePrintImageUrl,
                              markers: _vm.markers
                            },
                            on: {
                              markerCreated: _vm.addMarker
                            }
                          }),
                          _vm.markers[0]
                            ? _c("v-textarea", {
                                staticClass: "mx-4 mt-6",
                                attrs: {
                                  label: _vm.$t("objects.blueprint.text"),
                                  outlined: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.meta,
                                  callback: function callback($$v) {
                                    _vm.meta = $$v
                                  },
                                  expression: "meta"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "b",
                        {
                          staticClass: "ma-4"
                        },
                        [_vm._v(" Select a blueprint! ")]
                      )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.StepEnum.PICTURES
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(" Erstellen Sie aussagekräftige Fotos vom Befund. ")
                  ]),
                  _c("partner-report-card-images", {
                    staticClass: "ma-1",
                    attrs: {
                      dto: _vm.report
                    },
                    on: {
                      "update:dto": function updateDto($event) {
                        _vm.report = $event
                      },
                      setIsLoading: _vm.setIsLoadingImage
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.StepEnum.DETAILS
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      outlined: "",
                      label: _vm.$t("objects.report.title")
                    },
                    model: {
                      value: _vm.report.title,
                      callback: function callback($$v) {
                        _vm.$set(_vm.report, "title", $$v)
                      },
                      expression: "report.title"
                    }
                  }),
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("objects.report.message")
                    },
                    model: {
                      value: _vm.report.message,
                      callback: function callback($$v) {
                        _vm.$set(_vm.report, "message", $$v)
                      },
                      expression: "report.message"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.handover.inspections,
                      label: _vm.$t("objects.report.title"),
                      "item-value": "id",
                      outlined: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function fn(_ref3) {
                          var item = _ref3.item
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        item.title
                                          ? _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.title))
                                            ])
                                          : _vm._e(),
                                        item.description
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(_vm._s(item.description))
                                            ])
                                          : _vm._e(),
                                        item.startDate
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "simpleDoubleDigitDate"
                                                    )(item.startDate)
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function fn(_ref4) {
                          var item = _ref4.item
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        item.title
                                          ? _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.title))
                                            ])
                                          : _vm._e(),
                                        item.description
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(_vm._s(item.description))
                                            ])
                                          : _vm._e(),
                                        item.startDate
                                          ? _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "simpleDoubleDigitDate"
                                                    )(item.startDate)
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedInspectionId,
                      callback: function callback($$v) {
                        _vm.selectedInspectionId = $$v
                      },
                      expression: "selectedInspectionId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.StepEnum.SUCCESS
                  }
                },
                [
                  _c("v-img", {
                    staticClass: "mt-8",
                    attrs: {
                      contain: "",
                      src: _vm.image,
                      height: 150,
                      justify: "center"
                    }
                  }),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "text-center"
                    },
                    [_vm._v("Befund erfoglreich angelegt")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("debug", [_vm._v(_vm._s(_vm.report))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }