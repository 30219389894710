var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        hideDivider: true,
        title: ""
          .concat(_vm.customField.name, " ")
          .concat(_vm.$t("views.CustomFieldList.fieldConfiguration"))
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function fn() {
            return [
              _c("custom-field-detail-icon", {
                staticClass: "mr-1",
                attrs: {
                  type: _vm.customField.type
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.isDisabled
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.isValid,
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.$emit("onCheck")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.isDisabled = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-pen")])],
                    1
                  ),
              _vm.customField && _vm.customField.id
                ? _c(
                    "v-btn",
                    {
                      staticStyle: {
                        transform: "scale(0.18)"
                      },
                      attrs: {
                        disabled: !_vm.isValid,
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.$emit("onDelete")
                        }
                      }
                    },
                    [
                      _c("trash-can-icon", {
                        attrs: {
                          disabled: !_vm.isValid
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.$route.params.customFieldId
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.$emit("onClose")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _vm._e(),
              !_vm.$route.params.customFieldId && _vm.customField.id
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.openDetail
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c("custom-field-form", {
            attrs: {
              isDisabled: _vm.isDisabled,
              customField: _vm.customField
            },
            model: {
              value: _vm.isValid,
              callback: function callback($$v) {
                _vm.isValid = $$v
              },
              expression: "isValid"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }