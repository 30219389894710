import { render, staticRenderFns } from "./DashboardCalendar.vue?vue&type=template&id=472139b7&"
import script from "./DashboardCalendar.vue?vue&type=script&lang=ts&"
export * from "./DashboardCalendar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCalendar,VCard,VCardSubtitle,VCardText,VCardTitle,VCheckbox,VIcon,VList,VSlideXTransition,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('472139b7')) {
      api.createRecord('472139b7', component.options)
    } else {
      api.reload('472139b7', component.options)
    }
    module.hot.accept("./DashboardCalendar.vue?vue&type=template&id=472139b7&", function () {
      api.rerender('472139b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/DashboardCalendar.vue"
export default component.exports