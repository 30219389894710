var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("table-wrapper", {
        attrs: {
          confirmNewItems: function confirmNewItems() {},
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          allItems: _vm.allDocuments,
          selectedItems: _vm.selected
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          addItem: _vm.addDocument
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.user.firstName) +
                    " " +
                    _vm._s(item.user.lastName) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.activity",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.i18n[item.activity] || item.activity) + " "
                )
              ]
            }
          }
        ])
      }),
      _vm.isAddDocumentDialog
        ? _c("document-selection-dialog", {
            attrs: {
              activity: _vm.ActivityTypeEnum.CREATE_DOCUMENT,
              folder: "/vehicle/".concat(_vm.vehicle.id),
              isDialogActive: _vm.isAddDocumentDialog,
              createActivityOnSelect: true,
              reference: _vm.reference,
              partnerId: _vm.partnerId,
              documentSuggestions: _vm.documentSuggestions
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isAddDocumentDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isAddDocumentDialog = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isEditAddDocumentDialog,
            title: _vm.i18n["editAddDocumentDialogTitle"],
            leftText: _vm.i18n["abort"],
            rightText: _vm.i18n["confirm"]
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isEditAddDocumentDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isEditAddDocumentDialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.isEditAddDocumentDialog = false
            },
            rightClick: _vm.confirmNewDocument
          }
        },
        [
          _c(
            "v-card-actions",
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  flat: "",
                  label: _vm.i18n["types"],
                  items: _vm.documentTypes
                },
                model: {
                  value: _vm.type,
                  callback: function callback($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-file-input", {
                attrs: {
                  label: _vm.i18n["file"],
                  outlined: "",
                  accept: "image/*,.pdf"
                },
                model: {
                  value: _vm.content,
                  callback: function callback($$v) {
                    _vm.content = $$v
                  },
                  expression: "content"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("combo-box", {
                staticStyle: {
                  width: "100%"
                },
                attrs: {
                  prefill: _vm.tags,
                  items: [],
                  label: _vm.i18n["tags"],
                  isRequired: false
                },
                on: {
                  changeSelection: function changeSelection(v) {
                    return (_vm.tags = v)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }