





























import { emailRuleOptional, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ICompany } from "@/models/company.entity";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CompanyForm extends Vue {
  @Prop({ default: "" })
  subheader!: string;

  @Prop()
  value!: ICompany;

  @Prop({ default: true })
  isValid!: boolean;

  panel: number[] = [0];

  // Computed property to locally manage isValid
  get localIsValid() {
    return this.isValid;
  }

  set localIsValid(value: boolean) {
    this.$emit("update:isValid", value); // Emit an event to update parent prop
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isEmailRule() {
    return [emailRuleOptional()];
  }

  get isPhoneRule() {
    return [phoneRule()];
  }
}
