


























import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PaginatedTable,
    PartnerReportDetailSideCard
  }
})
export default class HandoverReportTable extends mixins(Vue, PermissionMixin) {
  readonly handoverReportStore = HandoverReportModule;

  @Prop()
  partnerId!: string;

  @Prop()
  handoverId!: string;

  isLoadingReport = false;

  showSideCard = false;

  selectedReport: IReport | null = null;

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    predefinedFilter.push({
      name: "all unfinished",
      filter: [
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.FINISHED,
          operation: PageFilterOperationEnum.NOT_EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 90,
        type: "date"
      },
      {
        text: "Title",
        value: "title",
        width: 90
      },
      {
        text: "Message",
        value: "message",
        width: 90
      },
      {
        text: "progressStatus",
        value: "progressStatus",
        width: 90
      }
    ];
  }

  beforeMount() {
    HandoverReportModule.setHiddenFilters([
      new PageFilterElement({ key: "handover.handoverId", value: this.handoverId })
    ]);
  }

  async openReportSideCard(item: IReport) {
    this.showSideCard = false;
    this.isLoadingReport = true;
    this.$nextTick(async () => {
      this.showSideCard = true;
      await item.fetch().catch(handleError);
      this.selectedReport = item;
      new GoToHelper(this.$router).setUrl({
        name: "HandoverReportTableView",
        params: {
          partnerId: this.partnerId,
          handoverId: this.handoverId
        },
        query: { reportId: item.id }
      });
      this.isLoadingReport = false;
    });
  }

  closeReportSideCard() {
    this.selectedReport = null;
    new GoToHelper(this.$router).setUrl({
      name: "HandoverReportTableView",
      params: {
        partnerId: this.partnerId,
        handoverId: this.handoverId
      },
      query: {}
    });
  }
}
