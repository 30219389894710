var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [
                _vm._v(" Vorschau " + _vm._s(_vm.partner.companyName) + " ")
              ]),
              _c("v-card-subtitle", [
                _vm._v(" " + _vm._s(_vm.screens.join(" -> ")) + " ")
              ]),
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tabs-slider", {
                    attrs: {
                      color: "yellow"
                    }
                  }),
                  _c("v-tab", [_vm._v(" Vorschau ")]),
                  _c("v-tab", [_vm._v(" Suche ")])
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.screens, function(screen) {
                              return _c(
                                "v-col",
                                {
                                  key: screen,
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "4"
                                  }
                                },
                                [
                                  _c("iframe", {
                                    staticStyle: {
                                      "pointer-events": "none",
                                      border: "none"
                                    },
                                    attrs: {
                                      src: "".concat(_vm.url).concat(screen),
                                      title: screen,
                                      width: "375px",
                                      height: "666px"
                                    }
                                  })
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.screenTypes,
                          search: _vm.search,
                          "single-expand": true,
                          "item-key": "path",
                          "show-expand": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "top",
                            fn: function fn() {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    attrs: {
                                      flat: ""
                                    }
                                  },
                                  [
                                    _c("v-toolbar-title", [
                                      _vm._v("Alle Screens")
                                    ]),
                                    _c("v-spacer"),
                                    _c("v-text-field", {
                                      attrs: {
                                        "append-icon": "mdi-magnify",
                                        label: "Suche",
                                        "single-line": "",
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.search,
                                        callback: function callback($$v) {
                                          _vm.search = $$v
                                        },
                                        expression: "search"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "expanded-item",
                            fn: function fn(_ref) {
                              var headers = _ref.headers,
                                item = _ref.item
                              return [
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      colspan: headers.length
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          "align-self": "center"
                                        }
                                      },
                                      [
                                        _c("iframe", {
                                          staticStyle: {
                                            "pointer-events": "none",
                                            border: "none"
                                          },
                                          attrs: {
                                            src: ""
                                              .concat(_vm.url)
                                              .concat(item.path),
                                            title: item.path,
                                            width: "375px",
                                            height: "666px"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          "align-self": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              elevation: "0",
                                              href: _vm.link(item.path),
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v("Go to " + _vm._s(item.path))]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }