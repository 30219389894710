var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-auto spaced contain grabbing droppable",
      class: _vm.getClasses,
      attrs: {
        id: "dropfield",
        flat: "",
        "max-width": "500",
        "max-height": "100%"
      },
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          return _vm.dragOver.apply(null, arguments)
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          return _vm.dragLeave.apply(null, arguments)
        },
        drop: function drop($event) {
          $event.preventDefault()
          return _vm.onFileDrop($event)
        }
      }
    },
    [
      !this.file
        ? _c(
            "v-card-text",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _vm.image
                ? _c("v-img", {
                    attrs: {
                      src: _vm.image,
                      height: "175",
                      justify: "center"
                    }
                  })
                : _vm._e(),
              !_vm.image
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            large: "",
                            color: _vm.iconColor
                          }
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                      _c("br"),
                      _c("br")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mt-3",
                  attrs: {
                    large: "",
                    outlined: "",
                    color: "action"
                  },
                  on: {
                    click: function click($event) {
                      return _vm.$refs.uploader.click()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "center d-none d-md-block"
                },
                [_vm._v("oder Drag and Drop")]
              )
            ],
            1
          )
        : _vm._e(),
      this.file
        ? _c("v-img", {
            ref: "imageUploaded",
            staticClass: "white--text align-start",
            attrs: {
              src: _vm.url,
              "lazy-src": _vm.url,
              alt: _vm.alt
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "placeholder",
                  fn: function fn() {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height ma-0",
                          attrs: {
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "grey lighten-5"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              4034393411
            )
          })
        : _vm._e(),
      this.file ? _c("v-card-subtitle", [_vm._v(_vm._s(_vm.text))]) : _vm._e(),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            inset: "",
            "max-width": "500px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _vm.hasInstructions
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                fab: "",
                                icon: "",
                                absolute: "",
                                small: "",
                                bottom: "",
                                left: ""
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v(" mdi-help-box ")])],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("InstructionCard", {
            attrs: {
              instructionTitle: _vm.instructionTitle,
              instructionText: _vm.instructionText,
              instructionPictures: _vm.instructionPictures
            },
            on: {
              clicked: _vm.disableDialog
            }
          })
        ],
        1
      ),
      _vm.isLoading || _vm.isUploaded
        ? _c(
            "v-btn",
            {
              staticClass: "topAdjusted overwriteDisbaled grey darken-4",
              attrs: {
                loading: _vm.isLoading,
                dark: "",
                fab: "",
                small: "",
                absolute: "",
                top: "",
                right: "",
                elevation: "1"
              }
            },
            [_vm.isUploaded ? _c("v-icon", [_vm._v(" mdi-check ")]) : _vm._e()],
            1
          )
        : _vm._e(),
      _c("input", {
        ref: "uploader",
        staticStyle: {
          display: "none"
        },
        attrs: {
          name: "file",
          type: "file",
          accept: _vm.fileTypes
        },
        on: {
          change: _vm.onFileInput
        }
      }),
      _c(
        "v-card-actions",
        [
          this.file
            ? _c(
                "v-btn",
                {
                  attrs: {
                    fab: "",
                    icon: "",
                    absolute: "",
                    small: "",
                    bottom: "",
                    right: ""
                  },
                  on: {
                    click: function click($event) {
                      return _vm.$refs.uploader.click()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-camera-plus")])],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }