var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c("context-drop-down-menu", {
        attrs: {
          actions: _vm.actions
        }
      }),
      _c("confirm-action-dialog", {
        attrs: {
          title: "Ansicht löschen?",
          subtitle: "Diese Aktion kann nicht rückgängig gemacht werden.",
          isDialogActive: _vm.isDeleteDialogActive,
          rightText: _vm.$t("delete"),
          rightColor: "error"
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteCustomView
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }