var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm._l(_vm.value, function(header, index) {
        return _c(
          "div",
          {
            key: "WebhookHeaderConfiguration" + index
          },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  value: _vm.menu === index,
                  "close-on-content-click": false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function fn(_ref) {
                        var on = _ref.on,
                          attrs = _ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    readonly: "",
                                    rules: _vm.requiredRule,
                                    value: header.value,
                                    label: ""
                                      .concat(
                                        _vm.$t("objects.webhookConfig.headers"),
                                        ": "
                                      )
                                      .concat(header.name),
                                    loading: _vm.loading,
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.setMenu(index)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function fn() {
                                          return [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.closeMenu === index,
                                                  "offset-y": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function fn(_ref2) {
                                                        var on = _ref2.on,
                                                          attrs = _ref2.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  [
                                                    _c("v-card-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("removeEntry")
                                                        )
                                                      )
                                                    ]),
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              elevation: 0
                                                            },
                                                            on: {
                                                              click: function click(
                                                                $event
                                                              ) {
                                                                _vm.closeMenu = -1
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "close"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "info",
                                                              small: "",
                                                              elevation: 0
                                                            },
                                                            on: {
                                                              click: function click(
                                                                $event
                                                              ) {
                                                                return _vm.removeHeader(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "remove"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c(
                  "v-card",
                  {
                    staticStyle: {
                      "max-height": "500px",
                      "overflow-y": "scroll"
                    },
                    attrs: {
                      flat: ""
                    }
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: _vm.$t("common.nouns.name"),
                            hint: _vm.$t("webhook.nameHint"),
                            loading: _vm.loading,
                            outlined: ""
                          },
                          model: {
                            value: _vm.nameCopy,
                            callback: function callback($$v) {
                              _vm.nameCopy = $$v
                            },
                            expression: "nameCopy"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: _vm.$t("common.nouns.value"),
                            hint: _vm.$t("webhook.valueHint"),
                            loading: _vm.loading,
                            outlined: ""
                          },
                          model: {
                            value: _vm.valueCopy,
                            callback: function callback($$v) {
                              _vm.valueCopy = $$v
                            },
                            expression: "valueCopy"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "x-small": "",
                              elevation: 0
                            },
                            on: {
                              click: _vm.cancelMenu
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.valueCopy || !_vm.nameCopy,
                              color: "info",
                              "x-small": "",
                              elevation: 0
                            },
                            on: {
                              click: _vm.confirmMenu
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "v-btn",
        {
          attrs: {
            outlined: "",
            block: "",
            text: "",
            height: "56px"
          },
          on: {
            click: _vm.createNewHeader
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("webhook.addHeader")) + " ")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }