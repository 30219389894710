var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
            : _vm.webhookConfig
            ? _c("webhook-config-card", {
                attrs: {
                  value: _vm.webhookConfig
                },
                on: {
                  deleted: function deleted($event) {
                    _vm.webhookConfig = null
                  }
                }
              })
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }