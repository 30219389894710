var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t("components.partner.CustomerBasicInfoCard.title")
      },
      scopedSlots: _vm._u(
        [
          _vm.customerAccountId && _vm.navigateToDetails
            ? {
                key: "actions",
                fn: function fn() {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "nudge-right": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: ""
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.$router.push({
                                              name: _vm.detailsComponentName,
                                              params: {
                                                partnerId: _vm.partnerId,
                                                customerAccountId:
                                                  _vm.customerAccountId
                                              }
                                            })
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1716288495
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "report.ThgChargingStationDetailCard.toDetail"
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c("v-card-text", [
        _vm.createdOn
          ? _c(
              "span",
              [
                _vm.createdOn
                  ? _c("span", [_vm._v(_vm._s(_vm.createdOnFormatted) + " ")])
                  : _c(
                      "v-icon",
                      {
                        staticClass: "mt-1",
                        attrs: {
                          color: "error",
                          small: ""
                        }
                      },
                      [_vm._v("mdi-minus")]
                    ),
                _c("br"),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("common.states.active")) +
                      ": " +
                      _vm._s(_vm.isActive ? _vm.$t("yes") : _vm.$t("no"))
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c("v-card-text", [
        _c("span", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.partner.CustomerBasicInfoCard.marketingOptIn",
                  {
                    value: _vm.isMarketingOptIn ? _vm.$t("yes") : _vm.$t("no")
                  }
                )
              ) +
              " "
          )
        ])
      ]),
      _c("v-card-text", [
        _vm.userId
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.partner.CustomerBasicInfoCard.user", {
                      value: _vm.userId
                    })
                  ) +
                  " "
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }