var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-5 my-2",
      style: _vm.eventEvent && _vm.eventEvent.ack ? "opacity: 0.7;" : "",
      attrs: {
        outlined: "",
        loading: _vm.loadingAck
      }
    },
    [
      _c("refs-event", {
        attrs: {
          item: _vm.eventEvent,
          small: "true"
        },
        on: {
          openDetail: _vm.click,
          action: _vm.acknowledge
        }
      }),
      _c("event-card-acknowledgement-dialog", {
        ref: "eventCardAcknowledgementDialog",
        attrs: {
          value: _vm.eventEvent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }