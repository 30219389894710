var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _vm.loading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-three-line"
                }
              })
            : _vm.value
            ? _c("event-form", {
                attrs: {
                  loading: _vm.loadingUpdate,
                  hideInNewTabButton: true,
                  value: _vm.value,
                  vehicle: _vm.vehicle
                },
                on: {
                  input: _vm.onUpdateEvent,
                  delete: _vm.onDeleteEvent,
                  goToDetail: _vm.goToDetail
                }
              })
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }