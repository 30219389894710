var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "card"
                }
              })
            : _vm.bookingDto
            ? _c("booking-detail-card", {
                attrs: {
                  isEdit: _vm.isEdit,
                  value: _vm.bookingDto,
                  loading: _vm.isLoading,
                  resources: _vm.resourceList,
                  service: _vm.service,
                  resourceName: _vm.bookingResourceName,
                  serviceName: _vm.bookingServiceName
                },
                on: {
                  goTo: _vm.processGoToAction,
                  input: _vm.onUpdateBooking,
                  delete: _vm.onDeleteBooking
                }
              })
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }