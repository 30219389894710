var render = function() {
  var _vm$document

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          attrs: {
            "three-line": ""
          }
        },
        [
          _c(
            "v-list-item-content",
            [
              _vm.loading
                ? _c("v-skeleton-loader", {
                    attrs: {
                      type: "paragraph image"
                    }
                  })
                : _vm.document
                ? _c("refs-document", {
                    attrs: {
                      item: _vm.document
                    }
                  })
                : _c("latest-entries-card-empty")
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c(
                "context-menu",
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.goToDocument
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s("mdi-open-in-new")
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("common.verbs.open"))
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.startRemoveDialog
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                attrs: {
                                  color: "red"
                                },
                                domProps: {
                                  textContent: _vm._s("mdi-trash-can")
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-title", {
                            staticStyle: {
                              color: "red"
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("common.verbs.remove"))
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isConfirmDeleteDialogActive,
          title: _vm.$t("components.fleet.contract.confirmDeleteDocument", {
            name:
              (_vm$document = _vm.document) === null || _vm$document === void 0
                ? void 0
                : _vm$document.title
          }),
          leftText: _vm.$t("common.verbs.cancel"),
          rightText: _vm.$t("common.verbs.remove")
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isConfirmDeleteDialogActive = false
          },
          rightClick: function rightClick($event) {
            return _vm.$emit("deleteReference", _vm.documentId)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }