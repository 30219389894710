var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.canArchive
        ? _c(
            "v-btn",
            {
              attrs: {
                elevation: 0,
                text: ""
              },
              on: {
                click: _vm.startDialog
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.fleet.FleetVehicleDetailHomeCard.archiveRelatedDocuments"
                    )
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: _vm.$t(
              "components.fleet.FleetVehicleDetailHomeCard.archiveRelatedDocumentsTitle"
            ),
            subtitle: _vm.$t(
              "components.fleet.FleetVehicleDetailHomeCard.archiveRelatedDocumentsSubtitle"
            ),
            isDialogActive: _vm.isDialogActive,
            loading:
              _vm.isTicketsLoading ||
              _vm.isEventsLoading ||
              _vm.isReportsLoading ||
              _vm.isDeleteLoading,
            rightDisabled: !(
              _vm.selectedReports.length +
              _vm.selectedTickets.length +
              _vm.selectedEvents.length +
              _vm.selectedRecurringEvents.length +
              _vm.selectedOverdueEvents.length
            ),
            rightLoading: _vm.isDeleteLoading,
            rightText: _vm.confirmButtonText
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            close: function close($event) {
              _vm.isDialogActive = false
            },
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            },
            rightClick: _vm.archiveSelection
          }
        },
        [
          _vm.isTicketsLoading || _vm.isEventsLoading || _vm.isReportsLoading
            ? _c(
                "div",
                [
                  _c("v-skeleton-loader", {
                    attrs: {
                      type: "list-item-three-line"
                    }
                  }),
                  _c("v-skeleton-loader", {
                    attrs: {
                      type: "list-item-three-line"
                    }
                  }),
                  _c("v-skeleton-loader", {
                    attrs: {
                      type: "list-item-three-line"
                    }
                  })
                ],
                1
              )
            : !_vm.tickets.length &&
              !_vm.events.length &&
              !_vm.overdueEvents.length &&
              !_vm.recurringEvents.length
            ? _c("div", [_c("latest-entries-card-empty")], 1)
            : _c(
                "v-expansion-panels",
                {
                  attrs: {
                    flat: "",
                    multiple: ""
                  }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _vm.isAllSelected
                        ? _c("v-checkbox", {
                            staticClass: "ml-1",
                            attrs: {
                              disabled: _vm.isDeleteLoading,
                              "input-value": true,
                              color: "primary"
                            },
                            on: {
                              click: _vm.unselectAllAll
                            }
                          })
                        : _c("v-checkbox", {
                            staticClass: "ml-1",
                            attrs: {
                              disabled: _vm.isDeleteLoading,
                              "input-value": false,
                              color: "primary"
                            },
                            on: {
                              click: _vm.selectAllAll
                            }
                          }),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "timeLine.PartnerReportDetailImageTimeLineElement.selectAll"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.tabConfig, function(config, key) {
                    return _c(
                      "v-expansion-panel",
                      {
                        key: "expansion-panel" + key,
                        attrs: {
                          disabled: config.disabled
                        }
                      },
                      [
                        _c(
                          "v-expansion-panel-header",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.fleet.FleetVehicleDetailHomeCard.".concat(
                                      key
                                    )
                                  )
                                ) +
                                " "
                            ),
                            _c("v-spacer"),
                            _vm._v(
                              " " +
                                _vm._s(config.selectedList.length) +
                                "/" +
                                _vm._s(config.list.length) +
                                " "
                            )
                          ],
                          1
                        ),
                        _c("v-expansion-panel-content", [
                          !config.list.length
                            ? _c("div", [_c("latest-entries-card-empty")], 1)
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-information-variant ")
                                      ]),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass: "text-wrap ml-2"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(config.subtitle) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      config.selectedList.length ===
                                      config.list.length
                                        ? _c("v-checkbox", {
                                            attrs: {
                                              disabled: _vm.isDeleteLoading,
                                              "input-value": true,
                                              color: "primary"
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.unselectAll(
                                                  config.selectedList
                                                )
                                              }
                                            }
                                          })
                                        : _c("v-checkbox", {
                                            attrs: {
                                              disabled: _vm.isDeleteLoading,
                                              "input-value": false,
                                              color: "primary"
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.selectAll(
                                                  config.selectedList,
                                                  config.list
                                                )
                                              }
                                            }
                                          }),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "timeLine.PartnerReportDetailImageTimeLineElement.selectAll"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(config.list, function(item) {
                                    return _c(
                                      config.component,
                                      _vm._b(
                                        {
                                          key: "overdue" + item.id,
                                          tag: "component",
                                          staticClass: "mt-n4",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function fn() {
                                                  return [
                                                    _vm.selectedIndex(
                                                      item,
                                                      config.selectedList
                                                    ) > -1
                                                      ? _c("v-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isDeleteLoading,
                                                            "input-value": true,
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.unselect(
                                                                item,
                                                                config.selectedList
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _c("v-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isDeleteLoading,
                                                            "input-value": false,
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.select(
                                                                item,
                                                                config.selectedList
                                                              )
                                                            }
                                                          }
                                                        })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        "component",
                                        {
                                          item: item,
                                          small: true,
                                          confirmablePersistant: true,
                                          confirmable: config.confirmable
                                        },
                                        false
                                      )
                                    )
                                  })
                                ],
                                2
                              )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }