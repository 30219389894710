var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              breadCrumbs: _vm.breadCrumbList,
              title: _vm.$t("webhook.webhookConfigTable"),
              chips: _vm.chips,
              actions: _vm.actions
            },
            on: {
              actionClicked: function actionClicked(a) {
                return a.exec()
              }
            }
          }),
          _c("webhook-config-table", {
            ref: "webhookConfigTable",
            attrs: {
              partnerId: _vm.partnerId
            },
            on: {
              openSideCard: _vm.openSideCard
            }
          }),
          _c("webhook-config-create-dialog", {
            ref: "webhookConfigCreateDialog",
            attrs: {
              hideButton: true,
              partnerId: _vm.partnerId,
              refs: []
            },
            on: {
              created: function created($event) {
                return $event.goToDetail(_vm.$router)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }