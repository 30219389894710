var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.isDialogActiveNotProp
    ? _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: _vm.$t("components.fleet.CreateMileageDialog.addMileage"),
            isDialogActive: _vm.isDialogActiveNotProp,
            leftLoading: _vm.loading,
            rightLoading: _vm.loading,
            rightDisabled: !_vm.isValid
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActiveNotProp = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActiveNotProp = $event
            },
            leftClick: _vm.close,
            rightClick: _vm.addMileage,
            close: _vm.close
          }
        },
        [
          _vm.latestMileage
            ? _c("v-card-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.fleet.CreateMileageDialog.latestMileage",
                      {
                        mileage: Number(
                          _vm.latestMileage.mileage
                        ).toLocaleString("de-DE"),
                        date: _vm.simpleDoubleDigitDate(_vm.latestMileage.date)
                      }
                    )
                  )
                }
              })
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.requiredRule,
                              label: _vm.$t("common.nouns.date"),
                              type: "date",
                              required: ""
                            },
                            model: {
                              value: _vm.date,
                              callback: function callback($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "6"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.requiredRule,
                              label: _vm.$t("common.nouns.mileage"),
                              type: "number",
                              required: ""
                            },
                            model: {
                              value: _vm.mileage,
                              callback: function callback($$v) {
                                _vm.mileage = $$v
                              },
                              expression: "mileage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.latestMileage &&
          ((_vm.mileage && _vm.latestMileage.mileage > _vm.mileage) ||
            (_vm.date && _vm.latestMileage.date > _vm.date))
            ? _c(
                "v-card-text",
                {
                  attrs: {
                    elevation: 0
                  }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("a-eicon", {
                            attrs: {
                              color: "red"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _vm.mileage && _vm.latestMileage.mileage > _vm.mileage
                            ? _c(
                                "v-list-item-text",
                                {
                                  staticClass: "red--text"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.fleet.CreateMileageDialog.warningMileage"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.date && _vm.latestMileage.date > _vm.date
                            ? _c(
                                "v-list-item-text",
                                {
                                  staticClass: "red--text"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.fleet.CreateMileageDialog.warningDate"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }