import { render, staticRenderFns } from "./BookingImageConfiguration.vue?vue&type=template&id=6e3b665e&scoped=true&"
import script from "./BookingImageConfiguration.vue?vue&type=script&lang=ts&"
export * from "./BookingImageConfiguration.vue?vue&type=script&lang=ts&"
import style0 from "./BookingImageConfiguration.vue?vue&type=style&index=0&id=6e3b665e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3b665e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCol,VIcon,VListItemContent,VRow,VSelect,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e3b665e')) {
      api.createRecord('6e3b665e', component.options)
    } else {
      api.reload('6e3b665e', component.options)
    }
    module.hot.accept("./BookingImageConfiguration.vue?vue&type=template&id=6e3b665e&scoped=true&", function () {
      api.rerender('6e3b665e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/BookingImageConfiguration.vue"
export default component.exports