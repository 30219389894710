var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "selectContainer",
          staticClass: "select-container"
        },
        _vm._l(_vm.imageConfigArray, function(imageConfigItem) {
          return _c(
            "v-row",
            {
              key: imageConfigItem.type
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "10",
                    md: "3",
                    order: "1"
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          disabled: _vm.isShowingDefaultImageConfig,
                          items: _vm.reportImageTypes,
                          "item-disabled": _vm.isReportImageDisabled,
                          label: _vm.$t(
                            "timeLine.PartnerReportDetailImageUpload.imageType"
                          )
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function fn(_ref) {
                                var item = _ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        disabled: _vm.isReportImageDisabled(
                                          item
                                        ),
                                        "text-body-2": true
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(
                                                item
                                              )
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function fn(_ref2) {
                                var item = _ref2.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-body-2"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(
                                                item
                                              )
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: imageConfigItem.type,
                          callback: function callback($$v) {
                            _vm.$set(imageConfigItem, "type", $$v)
                          },
                          expression: "imageConfigItem.type"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6",
                    md: "3",
                    order: "2"
                  }
                },
                [
                  _c("v-switch", {
                    staticClass: "pr-4",
                    attrs: {
                      inset: "",
                      disabled: _vm.isShowingDefaultImageConfig,
                      label: _vm.$t(
                        "components.booking.BookingImageConfiguration.required"
                      )
                    },
                    model: {
                      value: imageConfigItem.configuration.isRequired,
                      callback: function callback($$v) {
                        _vm.$set(
                          imageConfigItem.configuration,
                          "isRequired",
                          $$v
                        )
                      },
                      expression: "imageConfigItem.configuration.isRequired"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6",
                    md: "3",
                    order: "3"
                  }
                },
                [
                  _c("v-switch", {
                    attrs: {
                      inset: "",
                      disabled: _vm.isShowingDefaultImageConfig,
                      label: _vm.$t(
                        "components.booking.BookingImageConfiguration.isMultiple"
                      )
                    },
                    model: {
                      value: imageConfigItem.configuration.isMultiple,
                      callback: function callback($$v) {
                        _vm.$set(
                          imageConfigItem.configuration,
                          "isMultiple",
                          $$v
                        )
                      },
                      expression: "imageConfigItem.configuration.isMultiple"
                    }
                  })
                ],
                1
              ),
              !_vm.isShowingDefaultImageConfig
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "2",
                        md: "3",
                        order: _vm.isMobile ? 1 : 4
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.removeImageConfig(imageConfigItem)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mb-2"
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                col: "1"
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled:
                      _vm.imageConfig.length === _vm.reportImageTypes.length
                  },
                  on: {
                    click: _vm.addImageConfig
                  }
                },
                [
                  !_vm.isShowingDefaultImageConfig
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "white"
                          }
                        },
                        [_vm._v(" mdi-plus ")]
                      )
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isShowingDefaultImageConfig
                          ? _vm.$t("activate")
                          : _vm.$t("add")
                      ) +
                      " "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }