var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      attrs: {
        dense: ""
      }
    },
    [
      _c(
        "v-col",
        {
          attrs: {
            cols: "12"
          }
        },
        _vm._l(_vm.actions, function(_ref, index) {
          var color = _ref.color,
            action = _ref.action,
            condition = _ref.condition,
            text = _ref.text,
            icon = _ref.icon,
            success = _ref.success
          return _c(
            "div",
            {
              key: "ticketsidecardactions" + index
            },
            [
              condition && !success
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        dense: ""
                      },
                      on: {
                        click: action
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            style: "text-transform: uppercase; color: ".concat(
                              color
                            ),
                            attrs: {
                              small: ""
                            },
                            domProps: {
                              textContent: _vm._s(icon)
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-subtitle", [
                        _c("small", {
                          style: "text-transform: uppercase; color: ".concat(
                            color
                          ),
                          domProps: {
                            textContent: _vm._s(text)
                          }
                        })
                      ])
                    ],
                    1
                  )
                : success
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        dense: ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            staticStyle: {
                              "text-transform": "uppercase",
                              color: "green"
                            },
                            attrs: {
                              small: ""
                            },
                            domProps: {
                              textContent: _vm._s(icon)
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-subtitle",
                        [
                          _c("v-icon", {
                            staticStyle: {
                              "text-transform": "uppercase",
                              color: "green"
                            },
                            attrs: {
                              small: ""
                            },
                            domProps: {
                              textContent: _vm._s("mdi-thumb-up-outline")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          rightText: _vm.$t("delete"),
          rightColor: "error"
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteTicket
        }
      }),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.COST
          }
        },
        [
          _c("cost-create-dialog", {
            ref: "costCreateDialog",
            attrs: {
              isDialogActive: _vm.isCostCreateDialogActive,
              hideButton: true,
              partnerId: _vm.partnerId,
              refs: _vm.refs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }