var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _vm._t("activator", null, {
            on: _vm.open
          })
        ],
        2
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogModel,
            absolute: true,
            leftText: _vm.leftText,
            rightText: _vm.rightText,
            hideLeft: _vm.hideLeft,
            title: _vm.title,
            width: "1200px",
            persistent: true,
            fullscreen: _vm.fullScreen
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            close: _vm.close,
            rightClick: _vm.rightClick,
            leftClick: _vm.leftClick
          }
        },
        [
          _c(
            "v-window",
            {
              model: {
                value: _vm.step,
                callback: function callback($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 1
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(" Welchen Bereich wollen Sie begehen? ")
                  ]),
                  _c("v-select", {
                    staticClass: "mx-4",
                    attrs: {
                      items: _vm.imageOptions,
                      "item-text": "text",
                      "item-value": "src",
                      outlined: ""
                    },
                    model: {
                      value: _vm.imageMarkerSrc,
                      callback: function callback($$v) {
                        _vm.imageMarkerSrc = $$v
                      },
                      expression: "imageMarkerSrc"
                    }
                  }),
                  _c("m-image-marker", {
                    key: _vm.mykeey,
                    ref: "imageMarkerTourDialog",
                    staticClass: "mx-4",
                    attrs: {
                      src: _vm.imageMarkerSrc,
                      markers: _vm.markersSorted,
                      deactivated: true,
                      markerSize: "x-small"
                    },
                    on: {
                      markerCreated: _vm.addMarker
                    }
                  }),
                  _c("v-card-text", [
                    _vm._v(" Von wo nach wo besichtigen Sie das Fahrzeug? ")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            dark: "",
                            mandatory: "",
                            color: "info"
                          },
                          model: {
                            value: _vm.direction,
                            callback: function callback($$v) {
                              _vm.direction = $$v
                            },
                            expression: "direction"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: _vm.TourDirectionEnum.LeftToRight
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: _vm.TourDirectionEnum.TopToBottom
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: _vm.TourDirectionEnum.RightToLeft
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                value: _vm.TourDirectionEnum.BottomToTop
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: 2
                  }
                },
                [
                  _c("m-image-marker", {
                    key: _vm.mykeey,
                    ref: "imageMarkerTourDialog2",
                    staticClass: "mx-4",
                    attrs: {
                      src: _vm.imageMarkerSrc,
                      markers: _vm.markersSorted,
                      deactivated: true,
                      markerSize: "x-small"
                    },
                    on: {
                      markerCreated: _vm.addMarker
                    }
                  }),
                  _c("v-card-text", [_vm._v(" here comes my tour ")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }