



















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { IReport } from "@/models/report.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import PartnerReportDetail from "./PartnerReportDetail.vue";
import { ExportModule } from "@/store/modules/export.store";
import { ActivityLogModule } from "@/store/modules/activity-log.store";

@Component({
  components: { SideCard, PartnerReportDetail },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerReportDetailSideCard extends Vue {
  @Prop()
  report!: IReport;

  @Prop({ default: false })
  loading!: boolean;

  mounting = true;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  beforeMount() {
    PartnerModule.setReport(this.report);
  }

  async mounted() {
    this.mounting = true;

    try {
      await ExportModule.getExportReportMetaByPartnerIdAndReportId({
        partnerId: PartnerModule.report.partnerId,
        reportId: PartnerModule.report._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }

    try {
      await ActivityLogModule.loadDocumentsForReport({
        partnerId: PartnerModule.report.partnerId,
        reportId: PartnerModule.report._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
    this.mounting = false;
  }
}
