var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list",
    [
      _c("v-text-field", {
        attrs: {
          dense: "",
          label: _vm.$t(
            "views.booking.ServiceForm.iconSelectionInputPlaceholder"
          ),
          "single-line": "",
          "append-outer-icon": "mdi-" + _vm.icon,
          outlined: "",
          readonly: _vm.disabled
        },
        model: {
          value: _vm.icon,
          callback: function callback($$v) {
            _vm.icon = $$v
          },
          expression: "icon"
        }
      }),
      _c(
        "v-row",
        {
          staticClass: "mt-4 icon-list-container"
        },
        _vm._l(_vm.icons, function(iconItem, index) {
          return _c(
            "v-col",
            {
              key: index
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.icon = iconItem
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        large: ""
                      }
                    },
                    [_vm._v("mdi-" + _vm._s(iconItem))]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }