var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _vm.title ? _c("v-card-title", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c(
            "v-row",
            _vm._l(_vm.activeActions, function(action) {
              return _c(
                "v-col",
                {
                  key: action.key,
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex flex-column h-100",
                      attrs: {
                        flat: "",
                        outlined: ""
                      }
                    },
                    [
                      action.description
                        ? _c(
                            "v-card-text",
                            {
                              staticClass:
                                "text-center flex-grow-1 d-flex align-center justify-center"
                            },
                            [_vm._v(" " + _vm._s(action.description) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: "0",
                                dark: "",
                                color: action.color,
                                block: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.actionClicked(action)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(action.text) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }