var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.screenTypes,
              search: _vm.search,
              "single-expand": true,
              "item-key": "path",
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function fn() {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        attrs: {
                          flat: ""
                        }
                      },
                      [
                        _c("v-toolbar-title", [
                          _vm._v("Schadensmeldung Vorschau")
                        ]),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "append-icon": "mdi-magnify",
                            label: "Suche",
                            "single-line": "",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.search,
                            callback: function callback($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "expanded-item",
                fn: function fn(_ref) {
                  var headers = _ref.headers,
                    item = _ref.item
                  return [
                    _c(
                      "td",
                      {
                        attrs: {
                          colspan: headers.length
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12",
                              "align-self": "center"
                            }
                          },
                          [
                            _c("iframe", {
                              staticStyle: {
                                "pointer-events": "none",
                                border: "none"
                              },
                              attrs: {
                                src: "".concat(_vm.url).concat(item.path),
                                title: item.path,
                                width: "375px",
                                height: "666px"
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12",
                              "align-self": "center"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  elevation: "0",
                                  href: _vm.link(item.path),
                                  target: "_blank"
                                }
                              },
                              [_vm._v("Go to " + _vm._s(item.path))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }