var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        hideTitle: true,
        fullscreen: _vm.fullscreenBreakpoint,
        absolute: true,
        loading: _vm.loading || _vm.mounting
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("partner-report-detail", {
        attrs: {
          height: "calc(100vh - 210px)",
          displayToDetailView: true,
          loading: _vm.loading || _vm.mounting,
          showClose: true
        },
        on: {
          close: function close($event) {
            return _vm.$emit("close")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }