var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("table-wrapper", {
    attrs: {
      showSelect: true,
      hideSearch: true,
      selectedItems: _vm.selected,
      allItems: _vm.overdueEvents,
      headers: _vm.headersOverdue,
      itemKey: "index",
      itemsPerPage: 5,
      dense: _vm.dense,
      loading: _vm.loading,
      isClickableRow: true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u([
      {
        key: "search",
        fn: function fn() {
          return [
            _c(
              "span",
              {
                staticClass: "text-capitalize"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.fleet.FleetVehicleDetailHomeCard.upcomingAppointments"
                      )
                    ) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "selectActions",
        fn: function fn() {
          return [
            _c(
              "v-btn",
              {
                staticClass: "ma-1",
                attrs: {
                  elevation: "0",
                  color: "primary"
                },
                on: {
                  click: _vm.acknowledge
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("acknowledge")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "item.summary",
        fn: function fn(_ref) {
          var _ref2, _vm$getVehicle$displa, _vm$getVehicle, _vm$getVehicle2

          var item = _ref.item
          return [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-title",
                      [
                        _vm._v(" " + _vm._s(item.summary) + " "),
                        item.recurringEventId
                          ? _c(
                              "tooltip",
                              {
                                attrs: {
                                  text: _vm.$t(
                                    "views.fleet.EventCreateDialog.recurrence"
                                  )
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: ""
                                    }
                                  },
                                  [_vm._v("mdi-autorenew")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-subtitle",
                      [
                        _vm._v(" Fahrzeug: "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "FleetVehicleDetail",
                                params: {
                                  partnerId: item.partnerId,
                                  vehicleId: item.vehicleId
                                }
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_ref2 =
                                    (_vm$getVehicle$displa =
                                      (_vm$getVehicle = _vm.getVehicle(
                                        item.vehicleId
                                      )) === null || _vm$getVehicle === void 0
                                        ? void 0
                                        : _vm$getVehicle.displayName) !==
                                      null && _vm$getVehicle$displa !== void 0
                                      ? _vm$getVehicle$displa
                                      : (_vm$getVehicle2 = _vm.getVehicle(
                                          item.vehicleId
                                        )) === null ||
                                        _vm$getVehicle2 === void 0
                                      ? void 0
                                      : _vm$getVehicle2.numberplate) !== null &&
                                    _ref2 !== void 0
                                    ? _ref2
                                    : item.vehicleId
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("v-list-item-subtitle", [
                      _vm._v(" " + _vm._s(item.description))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }