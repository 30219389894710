var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "partner-manage-stepper-step-layout",
    {
      attrs: {
        title: _vm.$t(
          "components.partner.PartnerManageStepper.SetupStep.title"
        ),
        currentStep: _vm.currentStep,
        totalSteps: _vm.totalSteps,
        leftButtonFunction: _vm.goToPrevious,
        rightButtonFunction: _vm.endStep,
        centerButtonFunction: _vm.reset,
        centerButtonLabel: _vm.$t(
          "components.partner.PartnerManageStepper.StepLayout.reset"
        ),
        rightButtonDisabled: !_vm.valid
      }
    },
    [
      [
        _c(
          "v-form",
          {
            staticClass: "mr-2 ml-2 mt-6",
            staticStyle: {
              width: "100%"
            },
            on: {
              submit: function submit($event) {
                $event.preventDefault()
                return _vm.endStep.apply(null, arguments)
              }
            },
            model: {
              value: _vm.valid,
              callback: function callback($$v) {
                _vm.valid = $$v
              },
              expression: "valid"
            }
          },
          _vm._l(_vm.settings, function(setting) {
            return _c(
              "div",
              {
                key: setting.key
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-1"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          setting.label ? _vm.$t(setting.label) : setting.key
                        ) +
                        " "
                    ),
                    setting.isFound
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: {
                              top: "",
                              "nudge-right": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function fn(_ref) {
                                    var on = _ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              "data-test": "".concat(
                                                setting.key,
                                                "-button"
                                              )
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.handleEdit(
                                                  setting.key
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mb-1"
                                            },
                                            [_vm._v("mdi-trash-can-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.partner.PartnerManageDetailCard.delete"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                setting.isPassword
                  ? _c("v-text-field", {
                      attrs: {
                        rules: setting.rules || _vm.valueRules,
                        label: _vm.$t(
                          "components.partner.PartnerManageDetailCard.value"
                        ),
                        disabled: setting.isFound,
                        type: "password",
                        "append-icon": setting.isPassword
                          ? "mdi-eye"
                          : "mdi-eye-off",
                        outlined: "",
                        "data-test": "".concat(setting.key, "-input"),
                        hint: _vm.$t(setting.description),
                        "persistent-hint": ""
                      },
                      on: {
                        "click:append": function clickAppend($event) {
                          setting.isPassword = !setting.isPassword
                        }
                      },
                      model: {
                        value: setting.value,
                        callback: function callback($$v) {
                          _vm.$set(setting, "value", $$v)
                        },
                        expression: "setting.value"
                      }
                    })
                  : _c("v-text-field", {
                      attrs: {
                        rules: setting.rules || _vm.valueRules,
                        label: _vm.$t(
                          "components.partner.PartnerManageDetailCard.value"
                        ),
                        disabled: setting.isFound,
                        outlined: "",
                        "data-test": "".concat(setting.key, "-input"),
                        hint: _vm.$t(setting.description),
                        "persistent-hint": ""
                      },
                      model: {
                        value: setting.value,
                        callback: function callback($$v) {
                          _vm.$set(setting, "value", $$v)
                        },
                        expression: "setting.value"
                      }
                    })
              ],
              1
            )
          }),
          0
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }