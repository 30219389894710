var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        absolute: true,
        loading: _vm.isLoading,
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        fullscreen: _vm.fullscreenBreakpoint
      },
      on: {
        close: _vm.close
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "pb-10"
        },
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              })
            : _vm.value
            ? _c(
                "div",
                [
                  _c("m-header", {
                    attrs: {
                      title: _vm.value.id,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: _vm.processAction
                    }
                  }),
                  _c(
                    "m-detail-view-grid",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "details",
                          fn: function fn() {
                            return [
                              _c("m-detail-table", {
                                attrs: {
                                  title: _vm.$t("about"),
                                  i18nPath: "anonymization",
                                  item: _vm.value,
                                  config: _vm.detailTableConfig
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-container"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-box"
                                },
                                [
                                  _vm.anonymizedImage
                                    ? _c("v-img", {
                                        staticClass: "image-content",
                                        attrs: {
                                          src: _vm.anonymizedImage,
                                          alt: "Anonymized Image",
                                          "max-width": "100%"
                                        }
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "placeholder"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "grey"
                                              }
                                            },
                                            [_vm._v("mdi-image-off")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.waitingStatusText)
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "image-container"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-box"
                                },
                                [
                                  _vm.originalImage
                                    ? _c("v-img", {
                                        staticClass: "image-content",
                                        attrs: {
                                          src: _vm.originalImage,
                                          alt: "Original Image",
                                          "max-width": "100%"
                                        }
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "placeholder"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "grey"
                                              }
                                            },
                                            [_vm._v("mdi-image-off")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "anonymization.sideCard.originalDeleted"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _c("v-card", {
                        attrs: {
                          flat: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }