var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm._l(_vm.actions, function(_ref, index) {
        var color = _ref.color,
          action = _ref.action,
          text = _ref.text,
          icon = _ref.icon
        return _c(
          "div",
          {
            key: "fleethomevehicletableactions" + index
          },
          [
            _c(
              "v-list-item",
              {
                attrs: {
                  dense: ""
                },
                on: {
                  click: action
                }
              },
              [
                _c(
                  "v-list-item-icon",
                  [
                    _c(
                      "v-icon",
                      {
                        style: "text-transform: uppercase; color: ".concat(
                          color
                        ),
                        attrs: {
                          small: ""
                        }
                      },
                      [_vm._v(_vm._s(icon))]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-subtitle",
                  {
                    style: "text-transform: uppercase; color: ".concat(color)
                  },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm.isTicketCreateDialogActive
        ? _c("ticket-create-dialog", {
            attrs: {
              refs: _vm.refs,
              partnerIdProp: _vm.partnerId,
              isDialogActive: _vm.isTicketCreateDialogActive,
              vehicleAggregation: _vm.vehicleAggregation,
              hideToast: true
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              created: _vm.onTicketCreated,
              close: function close($event) {
                _vm.isTicketCreateDialogActive = false
              }
            }
          })
        : _vm._e(),
      _c("event-create-dialog", {
        ref: "EventCreateDialog",
        attrs: {
          hideButton: true,
          hidden: !_vm.eventCreate.isDialogActive
        },
        on: {
          createEvent: _vm.onCreateEvent
        }
      }),
      _vm.vehicleAggregation
        ? _c("partner-report-initialize-dialog", {
            ref: "PartnerReportInitializeDialog",
            attrs: {
              hideButton: true,
              reportDto: _vm.vehicleAggregation.reportDto
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }