var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn(_ref) {
                var on = _ref.on,
                  attrs = _ref.attrs
                return [
                  _c(
                    "div",
                    _vm._g(_vm._b({}, "div", attrs, false), on),
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          rules: _vm.requiredRule,
                          value: _vm.selectedName,
                          loading: _vm.loading,
                          outlined: "",
                          label: _vm.$t("objects.webhookConfig.type")
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isMenu,
            callback: function callback($$v) {
              _vm.isMenu = $$v
            },
            expression: "isMenu"
          }
        },
        [
          _c(
            "v-card",
            {
              staticStyle: {
                "max-height": "500px",
                "overflow-y": "scroll"
              },
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("objects.webhookConfig.type")) + " "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.isMenu = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-space-between": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-treeview", {
                        attrs: {
                          active: _vm.selected,
                          activatable: "",
                          loading: _vm.loading,
                          items: _vm.items
                        },
                        on: {
                          "update:active": function updateActive($event) {
                            _vm.selected = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-scroll-y-transition",
                        {
                          attrs: {
                            mode: "out-in"
                          }
                        },
                        [
                          _vm.selected[0] === ",,"
                            ? _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    width: "300px"
                                  },
                                  attrs: {
                                    flat: ""
                                  }
                                },
                                [
                                  _c("v-card-text", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("webhook.typeInstruction")
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "v-card",
                                {
                                  staticStyle: {
                                    width: "300px"
                                  },
                                  attrs: {
                                    flat: ""
                                  }
                                },
                                [
                                  _c("v-card-text", [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "webhook.resourceDescription",
                                            {
                                              resource: _vm.$t(
                                                "BackendResourceEnum.".concat(
                                                  _vm.resource
                                                )
                                              )
                                            }
                                          )
                                        )
                                      }
                                    }),
                                    _vm.action
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "webhook.actionDescription",
                                                {
                                                  action: _vm.$t(
                                                    "ActionEnum.".concat(
                                                      _vm.action
                                                    )
                                                  )
                                                }
                                              )
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.type
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "WebhookTypeEnumDescription.".concat(
                                                  _vm.type
                                                )
                                              )
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.viewModelMap.get(_vm.selectedResource)
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("webhook.toSeeTheData")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.viewModelMap.get(
                                                  _vm.selectedResource
                                                ).url,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("webhook.clickHere")
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("webhook.andLookFor")
                                              ) +
                                              ' " '
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.viewModelMap.get(
                                                    _vm.selectedResource
                                                  ).name
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(' ". ')
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }