var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialogModel,
        title: _vm.$t("credential.titleAlt"),
        width: "850",
        rightText: _vm.$t("credential.clipboard")
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        leftClick: function leftClick($event) {
          _vm.dialogModel = false
        },
        rightClick: _vm.copy,
        close: function close($event) {
          _vm.dialogModel = false
        }
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("credential.name"),
          disabled: "",
          "persistent-hint": "",
          outlined: ""
        },
        model: {
          value: _vm.key,
          callback: function callback($$v) {
            _vm.key = $$v
          },
          expression: "key"
        }
      }),
      !_vm.isActive
        ? _c("v-card-text", [_vm._v(_vm._s(_vm.$t("credential.inactiveHint")))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }