

























































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { IDialog } from "@/lib/interfaces/dialog.interface";

export enum TourDirectionEnum {
  LeftToRight = "leftToRight",
  TopToBottom = "topToBottom",
  RightToLeft = "rightToLeft",
  BottomToTop = "bottomToTop"
}

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    ReportImageUpload
  }
})
export default class TourHandoverFindingDialog extends mixins(PermissionMixin) implements IDialog {
  isDialogActive = false;

  activeMarker: IMarker | undefined;

  TourDirectionEnum = TourDirectionEnum;

  step = 1;

  example = AssetEnum.damageDetail;

  directions: TourDirectionEnum[] = [
    TourDirectionEnum.LeftToRight,
    TourDirectionEnum.TopToBottom,
    TourDirectionEnum.RightToLeft,
    TourDirectionEnum.BottomToTop
  ];
  directionModel = TourDirectionEnum.LeftToRight;

  get direction() {
    return this.directionModel;
  }

  set direction(v: TourDirectionEnum) {
    this.directionModel = v;
    this.mykeey = this.mykeey + 1;
  }

  mykeey = 1;

  get fullScreen() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  imageMarkerSrc = "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg";

  get markersSorted(): IMarker[] {
    switch (this.direction) {
      case TourDirectionEnum.LeftToRight:
        return this.markers.slice().sort((a, b) => a.left - b.left);
      case TourDirectionEnum.TopToBottom:
        return this.markers.slice().sort((a, b) => a.top - b.top);
      case TourDirectionEnum.RightToLeft:
        return this.markers.slice().sort((a, b) => b.left - a.left);
      case TourDirectionEnum.BottomToTop:
        return this.markers.slice().sort((a, b) => b.top - a.top);
      default:
        return this.markers;
    }
  }

  markers: IMarker[] = [
    { top: 0.62, left: 0.49, text: "1" },
    { top: 0.74, left: 0.81, text: "2" },
    { top: 0.85, left: 0.56, text: "3" },
    { top: 0.87, left: 0.23, text: "4" },
    { top: 0.62, left: 0.68, text: "5" },
    { top: 0.91, left: 0.32, text: "6" },
    { top: 0.9, left: 0.75, text: "7" },
    { top: 0.73, left: 0.47, text: "8" },
    { top: 0.69, left: 0.88, text: "9" },
    { top: 0.53, left: 0.39, text: "10" },
    { top: 0.66, left: 0.17, text: "11" },
    { top: 0.87, left: 0.59, text: "12" },
    { top: 0.84, left: 0.26, text: "13" },
    { top: 0.62, left: 0.93, text: "14" },
    { top: 0.68, left: 0.51, text: "15" }
  ];

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  // Image options for selection
  imageOptions = [
    {
      imageId: 1,
      text: "Image 1",
      src: "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg"
    },
    {
      imageId: 2,
      text: "Image 2",
      src: "https://www.schadensmeldung.digital/images/features/schadenmanagement/individueller-funnel.webp"
    }
  ];

  get hideLeft() {
    if (this.step === 1) {
      return true;
    }
    return false;
  }

  get leftText() {
    if (this.step === 4) {
      return "Befund ansehen";
    }
    return "Zurück";
  }

  get rightText() {
    if (this.step === 1) {
      return "Rundgang starten";
    }
    if (this.step === 4) {
      return "Weiteren Befund anlegen";
    }
    return "Weiter";
  }

  get title() {
    switch (this.step) {
      case 1:
        return "Rundgang planen";
      case 2:
        return "Rundgang";
      default:
        return "";
    }
  }

  open() {
    if (!this.can(this.ActionEnum.CREATE, this.ResourceEnum.HANDOVER)) {
      throw new Error("Permission denied");
    }

    this.isDialogActive = true;
  }

  async rightClick() {
    if (this.activeMarker) {
      this.activeMarker.color = undefined;
    }
    if (this.step === 1) {
      this.activeMarker = this.markersSorted[0];
      this.activeMarker.color = "red";
      this.step = this.step + 1;
      return;
    }

    let index = this.markersSorted.findIndex(marker => marker === this.activeMarker);
    index = index + 1;

    this.activeMarker = this.markersSorted[index];
    this.markers = this.markers.slice();
    this.activeMarker.color = "red";
  }

  async leftClick() {
    this.mykeey = this.mykeey + 1;
    this.step = this.step - 1;
  }

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers = [marker];
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
