var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "Customer Account Detail"
      }
    },
    [
      _c(
        "v-container",
        [
          _c("customer-account-detail", {
            attrs: {
              loading: _vm.isLoading,
              customerAccount: _vm.customerAccount
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }