var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.loading
    ? _c(
        "v-card",
        {
          attrs: {
            loading: _vm.loading,
            flat: "",
            outlined: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.statTitle)) + " ")]
              )
            ]
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass: "text-no-wrap"
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-end flex-wrap"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-4xl me-1 mb-2"
                    },
                    [
                      _c("statistics-card-column", {
                        attrs: {
                          icon: _vm.icon,
                          color: "primary",
                          title: _vm.subtitle,
                          data: _vm.statistics,
                          loading: _vm.loading
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("statistics-spark-line-card", {
                attrs: {
                  items: _vm.items
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-card",
        [
          _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type:
                "card-heading, divider, list-item-two-line,list-item-two-line, image"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }