var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("table-wrapper", {
        attrs: {
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          allItems: _vm.vehicle.blueprints,
          selectedItems: _vm.selected,
          sortBy: "date"
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selected = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.image.url",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _c("image-dialog", {
                  attrs: {
                    cardClass: "my-4",
                    src: item.image.url
                  }
                })
              ]
            }
          },
          {
            key: "item.type",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("blueprint.types." + item.type)) + " "
                )
              ]
            }
          },
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c("v-btn", {
                  attrs: {
                    elevation: 0,
                    color: "info"
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("add"))
                  },
                  on: {
                    click: function click($event) {
                      _vm.isBlueprintCreateDialogActive = true
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("blueprint-create-dialog", {
        ref: "blueprintDialog",
        attrs: {
          isDialogActive: _vm.isBlueprintCreateDialogActive,
          hideButton: true,
          partnerId: _vm.vehicle.partnerId,
          vehicle: _vm.vehicle
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isBlueprintCreateDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isBlueprintCreateDialogActive = $event
          },
          createdActivity: _vm.onCreatedActivity
        }
      }),
      _vm.removeBlueprintDialog
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.removeBlueprintDialog,
              title: _vm.$t("delete"),
              leftText: _vm.$t("cancel"),
              rightText: _vm.$t("delete"),
              rightColor: "error",
              rightLoading: _vm.deleteLoading,
              leftDisabled: _vm.deleteLoading
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.removeBlueprintDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.removeBlueprintDialog = $event
              },
              leftClick: _vm.abortRemoval,
              rightClick: _vm.remove
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }