var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: {
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        loading: _vm.loading,
        hideTitle: true,
        fullscreen: _vm.fullscreenBreakpoint,
        absolute: true
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("contract-card", {
        staticClass: "pa-1 ma-1",
        attrs: {
          loading: _vm.loading,
          partnerId: _vm.partnerId,
          vehicle: _vm.vehicle,
          vehicleId: _vm.vehicleId,
          hideBreadcrumbs: true,
          hideToDetail: false,
          documentSuggestions: _vm.documentSuggestions,
          loadingDocumentSuggestions: _vm.loadingDocumentSuggestions
        },
        on: {
          close: function close($event) {
            return _vm.$emit("close")
          },
          save: function save(v) {
            return _vm.$emit("save", v)
          },
          delete: function _delete(v) {
            return _vm.$emit("delete", v)
          }
        },
        model: {
          value: _vm.value,
          callback: function callback($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }