var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        loading: _vm.loading,
        elevation: 0
      }
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line,list-item-two-line ,list-item-two-line"
            }
          })
        : _vm.value
        ? _c(
            "div",
            [
              _c("m-header", {
                attrs: {
                  title: ""
                    .concat(_vm.$t("components.fleet.contract.detailsFor"), " ")
                    .concat(_vm.value.title),
                  actions: _vm.actions,
                  chips: _vm.chips,
                  breadCrumbs: _vm.breadCrumbs
                },
                on: {
                  actionClicked: _vm.processAction
                }
              }),
              _vm.loadingDocuments
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: ""
                    }
                  })
                : _c("v-divider"),
              _c(
                "m-detail-view-grid",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "details",
                      fn: function fn() {
                        return [
                          _c("m-detail-table", {
                            attrs: {
                              title: _vm.$t("about"),
                              i18nPath:
                                "components.fleet.FleetVehicleDetailContractTableLeasingInputs",
                              item: _vm.value,
                              config: _vm.detailTableConfig,
                              customFieldValues: _vm.customFieldsForm
                            },
                            on: {
                              actionClicked: _vm.processAction
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function fn() {
                        return [
                          _c("m-action-list", {
                            attrs: {
                              divider: true,
                              actions: _vm.actions
                            },
                            on: {
                              actionClicked: _vm.processAction
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mx-4 mb-6"
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common.nouns.contract")) + " "
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on,
                                  attrs = _ref.attrs
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("beginsOn", {
                                            date: ""
                                          })
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      _vm._g(_vm._b({}, "a", attrs, false), on),
                                      [
                                        _vm.startDate
                                          ? _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.readableStartDate
                                                )
                                              }
                                            })
                                          : _c("v-icon", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  "mdi-calendar-remove"
                                                )
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-date-picker", {
                                staticClass: "ma-1",
                                attrs: {
                                  label: _vm.i18n["startDate"]
                                },
                                on: {
                                  change: _vm.askForConfirmation
                                },
                                model: {
                                  value: _vm.startDateCopy,
                                  callback: function callback($$v) {
                                    _vm.startDateCopy = $$v
                                  },
                                  expression: "startDateCopy"
                                }
                              }),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c("v-btn", {
                                    attrs: {
                                      text: ""
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("remove"))
                                    },
                                    on: {
                                      click: _vm.resetStartDate
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("and")) + " "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref2) {
                                var on = _ref2.on,
                                  attrs = _ref2.attrs
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("endsOn", {
                                            date: ""
                                          })
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      _vm._g(_vm._b({}, "a", attrs, false), on),
                                      [
                                        _vm.endDate
                                          ? _c("span", {
                                              attrs: {
                                                color: !_vm.endDate
                                                  ? ""
                                                  : _vm.endDateCopy <=
                                                    _vm.startDateCopy
                                                  ? "error"
                                                  : ""
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.readableEndDate
                                                )
                                              }
                                            })
                                          : _c(
                                              "span",
                                              [
                                                _c("v-icon", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      "mdi-calendar-remove"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                      ]
                                    )
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-date-picker", {
                                staticClass: "ma-1",
                                attrs: {
                                  label: _vm.i18n["endDate"],
                                  color:
                                    _vm.endDateCopy <= _vm.startDateCopy
                                      ? "red"
                                      : "info"
                                },
                                on: {
                                  change: _vm.askForConfirmation
                                },
                                model: {
                                  value: _vm.endDateCopy,
                                  callback: function callback($$v) {
                                    _vm.endDateCopy = $$v
                                  },
                                  expression: "endDateCopy"
                                }
                              }),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c("v-btn", {
                                    attrs: {
                                      text: ""
                                    },
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("remove"))
                                    },
                                    on: {
                                      click: _vm.resetEndDate
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.endDate &&
                              _vm.endDateCopy <= _vm.startDateCopy
                                ? _c("div", {
                                    staticClass: "pa-1",
                                    staticStyle: {
                                      "max-width": "290px",
                                      color: "red"
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "components.fleet.contract.dateWarning"
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("span", [_vm._v(".")])
                    ],
                    1
                  ),
                  _c("m-detail-form", {
                    ref: "contractDetailForm",
                    attrs: {
                      title: _vm.$t("designGuide.MDetailForm.allProps"),
                      description: _vm.$t("components.fleet.contract.details"),
                      item: _vm.value,
                      config: _vm.config,
                      syncChanges: _vm.syncChanges,
                      abortChanges: _vm.abortChanges,
                      extraPanelsCount: 1
                    },
                    on: {
                      "update:item": function updateItem($event) {
                        _vm.value = $event
                      },
                      "update:config": function updateConfig($event) {
                        _vm.config = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "extraPanels",
                        fn: function fn() {
                          return [
                            _c(
                              "Can",
                              {
                                attrs: {
                                  I: _vm.ActionEnum.READ,
                                  a: _vm.ResourceEnum.DOCUMENT,
                                  field: _vm.partnerId
                                }
                              },
                              [
                                _c(
                                  "v-expansion-panel",
                                  {
                                    staticClass: "pa-0 ma-0"
                                  },
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      {
                                        staticClass: "pa-0",
                                        scopedSlots: _vm._u([
                                          {
                                            key: "actions",
                                            fn: function fn() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-4",
                                                    attrs: {
                                                      "x-large": "",
                                                      color: "info"
                                                    }
                                                  },
                                                  [_vm._v(" mdi-chevron-down ")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "title ml-4"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common.nouns.appendix"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-caption"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.documentIds.length
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common.nouns.documents"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-n2 mr-n2"
                                      },
                                      [
                                        _c(
                                          "v-expansion-panel-content",
                                          {
                                            staticClass: "pr-0 pl-0"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex"
                                                }
                                              },
                                              [
                                                _c("v-spacer"),
                                                _c("v-btn", {
                                                  attrs: {
                                                    loading:
                                                      _vm.loadingDocumentSuggestions,
                                                    elevation: 0,
                                                    text: "",
                                                    "x-small": ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "components.fleet.FleetVehicleDetailContractTableLeasingInputs.addDocument"
                                                      )
                                                    )
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.initiateFileUpload
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._l(_vm.documentIds, function(
                                              documentId,
                                              index
                                            ) {
                                              return _c(
                                                "v-card",
                                                {
                                                  key:
                                                    "referenceDocuments" +
                                                    index +
                                                    documentId,
                                                  staticClass: "mb-1",
                                                  attrs: {
                                                    outlined: "",
                                                    elevation: 0
                                                  }
                                                },
                                                [
                                                  _c("contract-card-document", {
                                                    attrs: {
                                                      documentId: documentId,
                                                      partnerId: _vm.partnerId
                                                    },
                                                    on: {
                                                      deleteReference:
                                                        _vm.deleteReference
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.addDocumentDialog
        ? _c("document-selection-dialog", {
            attrs: {
              activity: _vm.ActivityTypeEnum.CREATE_DOCUMENT,
              folder: _vm.vehicle ? "/vehicle/".concat(_vm.vehicle.id) : "",
              isDialogActive: _vm.addDocumentDialog,
              createActivityOnSelect: false,
              reference: _vm.reference,
              partnerId: _vm.partnerId,
              documentSuggestions: _vm.documentSuggestions
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.addDocumentDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.addDocumentDialog = $event
              },
              created: _vm.addReferenceToContract
            }
          })
        : _vm._e(),
      _vm.documentForDeletion
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isConfirmDeleteDialogActive,
              title: _vm.$t("components.fleet.contract.confirmDeleteDocument", {
                name: _vm.documentForDeletion.title
              }),
              leftText: _vm.$t("common.verbs.cancel"),
              rightText: _vm.$t("common.verbs.remove")
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isConfirmDeleteDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isConfirmDeleteDialogActive = $event
              },
              leftClick: function leftClick($event) {
                _vm.isConfirmDeleteDialogActive = false
              },
              rightClick: _vm.deleteReference
            }
          })
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isConfirmDeleteContractDialogActive,
          title: _vm.$t("components.fleet.contract.confirmDeleteContract", {
            name: _vm.value.number
          }),
          leftText: _vm.$t("common.verbs.cancel"),
          rightText: _vm.$t("common.verbs.remove")
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteContractDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isConfirmDeleteContractDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isConfirmDeleteContractDialogActive = false
          },
          rightClick: _vm.deleteContract
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }