var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("the-layout-portal-list", {
              attrs: {
                title: _vm.$t("nav.PartnerCustomerList.title"),
                getModule: _vm.getModule,
                isLoadingMore: _vm.isLoadingMore
              },
              on: {
                loadMore: _vm.onLoadMore,
                refresh: _vm.onRefresh
              },
              scopedSlots: _vm._u([
                {
                  key: "listItem",
                  fn: function fn(_ref) {
                    var listItem = _ref.listItem
                    return [
                      _vm.isLoading || !listItem
                        ? _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type:
                                "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider"
                            }
                          })
                        : _c(
                            "v-list-item",
                            {
                              key: listItem.id,
                              staticClass: "item",
                              class: _vm.isActive(listItem),
                              attrs: {
                                "two-line": ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.set(listItem)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(listItem.firstName) +
                                        " " +
                                        _vm._s(listItem.lastName)
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(listItem.userName))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _c("customer-account-detail", {
              attrs: {
                isLoading: _vm.isLoadingDetail,
                customerAccount: _vm.customerAccount,
                navigateToDetails: true
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }