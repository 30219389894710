import { render, staticRenderFns } from "./ResourceTable.vue?vue&type=template&id=b7fa3b40&"
import script from "./ResourceTable.vue?vue&type=script&lang=ts&"
export * from "./ResourceTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VBtn,VCard,VChip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b7fa3b40')) {
      api.createRecord('b7fa3b40', component.options)
    } else {
      api.reload('b7fa3b40', component.options)
    }
    module.hot.accept("./ResourceTable.vue?vue&type=template&id=b7fa3b40&", function () {
      api.rerender('b7fa3b40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/booking/ResourceTable.vue"
export default component.exports