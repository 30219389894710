var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _vm.partnerSettingLoaded
        ? _c("partner-manage-detail-card")
        : _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type: "list-item-two-line, list-item-two-line"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }