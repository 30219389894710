var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "mt-1"
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto card-outter rounded-0 pa-2",
              staticStyle: {
                "min-height": "550px"
              },
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("pricing-card", {
                        attrs: {
                          config: _vm.starter
                        },
                        on: {
                          order: _vm.toContactSales
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("pricing-card", {
                        attrs: {
                          config: _vm.basic
                        },
                        on: {
                          order: _vm.toContactSales
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("pricing-card", {
                        attrs: {
                          config: _vm.premium
                        },
                        on: {
                          order: _vm.toContactSales
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }