import { render, staticRenderFns } from "./LatestReportEntriesCard.vue?vue&type=template&id=538fe5f9&"
import script from "./LatestReportEntriesCard.vue?vue&type=script&lang=ts&"
export * from "./LatestReportEntriesCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDivider,VSkeletonLoader,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('538fe5f9')) {
      api.createRecord('538fe5f9', component.options)
    } else {
      api.reload('538fe5f9', component.options)
    }
    module.hot.accept("./LatestReportEntriesCard.vue?vue&type=template&id=538fe5f9&", function () {
      api.rerender('538fe5f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cards/LatestReportEntriesCard.vue"
export default component.exports