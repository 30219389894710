var render = function() {
  var _vm$value$attachments, _vm$value$attachments2, _vm$value$customerInf

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.title,
          breadCrumbs: _vm.breadCrumbs,
          alerts: _vm.alerts,
          actions: _vm.actions
        },
        on: {
          actionClicked: _vm.processAction,
          alertClicked: _vm.processAction
        }
      }),
      _c(
        "div",
        {
          staticClass: "text-medium-emphasis mx-4 mb-4 mt-n8 text-truncate"
        },
        _vm._l(_vm.chips, function(item, index) {
          return _c(
            "span",
            {
              key: index
            },
            [
              _c(
                "v-chip",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: item.color,
                    dark: "",
                    small: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-n1 mr-1",
                      attrs: {
                        "x-small": ""
                      }
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  ),
                  _vm._v(" " + _vm._s(item.text) + " ")
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: ""
            }
          })
        : _c("v-divider"),
      _c(
        "m-detail-view-grid",
        {
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function fn() {
                return [
                  _c("m-detail-table", {
                    attrs: {
                      title: _vm.$t("about"),
                      i18nPath: "objects.booking",
                      item: _vm.value,
                      config: _vm.detailTableConfig,
                      showMoreAction: _vm.detailTableMoreAction,
                      customFieldValues: !_vm.isEdit ? _vm.value.values : null
                    },
                    on: {
                      actionClicked: _vm.processAction
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "slot.start",
                        fn: function fn() {
                          return [
                            _c("span", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.simpleDoubleDigitDate(
                                        new Date(_vm.value.start).toISOString()
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("span", [
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatHoursAndMinutes(
                                        new Date(_vm.value.start)
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.end",
                        fn: function fn() {
                          return [
                            _c("span", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.simpleDoubleDigitDate(
                                        new Date(_vm.value.end).toISOString()
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("span", [
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatHoursAndMinutes(
                                        new Date(_vm.value.end)
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.duration",
                        fn: function fn() {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatHoursAndMinutes(
                                      new Date(_vm.value.duration)
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.deletedAt",
                        fn: function fn() {
                          return [
                            _vm.value.isDeleted && _vm.value.deletedAt
                              ? _c("span", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.simpleDoubleDigitDate(
                                            new Date(
                                              _vm.value.deletedAt
                                            ).toISOString()
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatHoursAndMinutes(
                                            new Date(_vm.value.deletedAt)
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.customerInformation",
                        fn: function fn() {
                          return [
                            _vm.value.customerInformation
                              ? _c("refs-user", {
                                  staticClass: "ma-n2 mx-n4",
                                  attrs: {
                                    item: _vm.value.getUserFromCustomer()
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.resourceId",
                        fn: function fn() {
                          return [
                            _vm.value.resourceId
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.resourceName) + " ")
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "slot.serviceId",
                        fn: function fn() {
                          return [
                            _vm.value.serviceId
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.serviceName) + " ")
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function fn() {
                return [
                  _c("m-action-list", {
                    attrs: {
                      divider: true,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: _vm.processAction
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.isEdit
            ? _c("div", [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "mb-2 mt-n2",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "35px"
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "white--text ml-2 mr-2",
                          attrs: {
                            color: "primary",
                            size: "25px",
                            right: true
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.value.getUserIdentifierStringInitials()
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm._v(" " + _vm._s(_vm.avatarAuthorName) + " "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]),
                _vm.value.customerInformation
                  ? _c(
                      "div",
                      [
                        _c("v-divider"),
                        _vm.value.customerInformation
                          ? _c(
                              "booking-customer-information-time-line-element",
                              {
                                attrs: {
                                  firstName:
                                    _vm.value.customerInformation.firstName,
                                  lastName:
                                    _vm.value.customerInformation.lastName,
                                  email: _vm.value.customerInformation.email,
                                  phone: _vm.value.customerInformation.phone,
                                  numberPlate:
                                    _vm.value.customerInformation.numberPlate
                                }
                              }
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.value.bookingInformation &&
                _vm.value.bookingInformation.notes
                  ? _c(
                      "div",
                      [
                        _vm.value.bookingInformation
                          ? _c(
                              "booking-booking-information-time-line-element",
                              {
                                attrs: {
                                  notes: _vm.value.bookingInformation.notes
                                },
                                on: {
                                  input: _vm.updateBookingInformation
                                }
                              }
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("v-divider"),
                    (_vm$value$attachments = _vm.value.attachments) !== null &&
                    _vm$value$attachments !== void 0 &&
                    _vm$value$attachments.length
                      ? _c(
                          "timeline-card",
                          {
                            attrs: {
                              title: _vm.$t(
                                "timeLine.PartnerReportDetailImageTimeLineElement.title",
                                {
                                  number:
                                    (_vm$value$attachments2 =
                                      _vm.value.attachments) === null ||
                                    _vm$value$attachments2 === void 0
                                      ? void 0
                                      : _vm$value$attachments2.length
                                }
                              )
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ma-4"
                              },
                              [
                                _c("booking-attachment-list", {
                                  attrs: {
                                    partnerId: _vm.partnerId,
                                    attachments: _vm.value.attachments
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("v-divider"),
                    _c("activity-card", {
                      attrs: {
                        partner: _vm.partner,
                        source: _vm.source,
                        config: {
                          comment: true,
                          mail: true
                        },
                        mail: _vm.mail
                      }
                    })
                  ],
                  1
                )
              ])
            : _c(
                "div",
                [
                  _c("m-detail-form", {
                    ref: "contractDetailForm",
                    attrs: {
                      title: _vm.$t("designGuide.MDetailForm.allProps"),
                      description: _vm.$t("components.fleet.contract.details"),
                      item: _vm.value,
                      config: _vm.config,
                      abortChanges: _vm.abortChanges,
                      customFields: _vm.serviceCustomFields,
                      syncChanges: _vm.syncChanges
                    },
                    on: {
                      "update:item": function updateItem($event) {
                        _vm.value = $event
                      },
                      "update:config": function updateConfig($event) {
                        _vm.config = $event
                      }
                    }
                  })
                ],
                1
              )
        ]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDeleteDialogActive,
            title: _vm.$t("views.booking.BookingTable.confirmDeleteTitle"),
            subtitle: _vm.$t(
              "views.booking.BookingTable.confirmDeleteSubtitle"
            ),
            leftLoading: _vm.isDeleteDialogLoading,
            rightLoading: _vm.isDeleteDialogLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDeleteDialogActive = false
            },
            rightClick: _vm.deleteBooking
          }
        },
        [
          (_vm$value$customerInf = _vm.value.customerInformation) !== null &&
          _vm$value$customerInf !== void 0 &&
          _vm$value$customerInf.email
            ? _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.booking.BookingDetailCard.notifyUser"
                  ),
                  inset: ""
                },
                model: {
                  value: _vm.notifyUserForDelete,
                  callback: function callback($$v) {
                    _vm.notifyUserForDelete = $$v
                  },
                  expression: "notifyUserForDelete"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isUpdateDialogActive,
            title: _vm.$t(
              "components.booking.BookingDetailCard.confirmUpdateTitle"
            ),
            subtitle: _vm.$t(
              "components.booking.BookingDetailCard.confirmUpdateSubtitle"
            ),
            leftLoading: _vm.isUpdateDialogLoading,
            rightLoading: _vm.isUpdateDialogLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isUpdateDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isUpdateDialogActive = $event
            },
            leftClick: _vm.abortChanges,
            rightClick: _vm.onUpdateBookingClicked
          }
        },
        [
          _c("v-switch", {
            attrs: {
              label: _vm.$t("components.booking.BookingDetailCard.notifyUser"),
              inset: ""
            },
            model: {
              value: _vm.notifyUserForUpdate,
              callback: function callback($$v) {
                _vm.notifyUserForUpdate = $$v
              },
              expression: "notifyUserForUpdate"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }