var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        renderNavigationButton: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "mx-auto"
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-8 align-left justify-left"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("error-card", {
                    attrs: {
                      items: _vm.items,
                      loading: _vm.loading,
                      showLoginBtn: true
                    },
                    on: {
                      reload: _vm.reload
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }