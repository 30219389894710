import { render, staticRenderFns } from "./PartnerCustomerList.vue?vue&type=template&id=65c5fb8c&"
import script from "./PartnerCustomerList.vue?vue&type=script&lang=ts&"
export * from "./PartnerCustomerList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65c5fb8c')) {
      api.createRecord('65c5fb8c', component.options)
    } else {
      api.reload('65c5fb8c', component.options)
    }
    module.hot.accept("./PartnerCustomerList.vue?vue&type=template&id=65c5fb8c&", function () {
      api.rerender('65c5fb8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PartnerCustomerList.vue"
export default component.exports