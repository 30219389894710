var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("table-wrapper", {
    attrs: {
      allItems: _vm.inspections,
      headers: _vm.headers,
      hideTableFooter: true
    },
    on: {
      updateSearch: function updateSearch($event) {
        _vm.search = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item.title",
        fn: function fn(_ref) {
          var item = _ref.item
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "InspectionDetailView",
                    params: {
                      partnerId: item.partnerId,
                      vehicleId: item.handoverId,
                      inspectionId: item.id
                    }
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            )
          ]
        }
      },
      {
        key: "item.start",
        fn: function fn(_ref2) {
          var item = _ref2.item
          return [
            item.start
              ? _c("span", [_vm._v(_vm._s(_vm.ddmmyyyhhmm(item.start)))])
              : _vm._e()
          ]
        }
      },
      {
        key: "item.timestamp.created",
        fn: function fn(_ref3) {
          var item = _ref3.item
          return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")]
        }
      },
      {
        key: "item.address",
        fn: function fn(_ref4) {
          var item = _ref4.item
          return [
            item.address.street
              ? _c("div", [_vm._v(_vm._s(item.address.street))])
              : _vm._e(),
            item.address.zip || item.address.city
              ? _c("div", [
                  _vm._v(
                    _vm._s(item.address.zip) + " " + _vm._s(item.address.city)
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "bodyActions",
        fn: function fn() {
          return [
            _c("inspection-create-dialog", {
              attrs: {
                handover: _vm.handover
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref5) {
                    var on = _ref5.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: 0,
                            color: "info"
                          },
                          on: {
                            click: on
                          }
                        },
                        [_vm._v("create")]
                      )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }