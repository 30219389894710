import { render, staticRenderFns } from "./PartnerFormDetail.vue?vue&type=template&id=5922d896&scoped=true&"
import script from "./PartnerFormDetail.vue?vue&type=script&lang=ts&"
export * from "./PartnerFormDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerFormDetail.vue?vue&type=style&index=0&id=5922d896&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5922d896",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardSubtitle,VCardTitle,VContainer,VIcon,VImg,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5922d896')) {
      api.createRecord('5922d896', component.options)
    } else {
      api.reload('5922d896', component.options)
    }
    module.hot.accept("./PartnerFormDetail.vue?vue&type=template&id=5922d896&scoped=true&", function () {
      api.rerender('5922d896', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PartnerFormDetail.vue"
export default component.exports