var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      class: "ma-".concat(2),
      attrs: {
        flat: "",
        outlined: _vm.outlined
      }
    },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.cols
                  }
                },
                [
                  _c("card-icon", {
                    attrs: {
                      icon: _vm.icon,
                      iconColor: _vm.iconColor
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.cols2
                  }
                },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }