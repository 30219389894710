var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.$t("objects.booking.notes")
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function fn() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    text: ""
                  },
                  on: {
                    click: _vm.editDescription
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.notesCopy
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-textarea", {
                staticClass: "no-border px-2",
                attrs: {
                  flat: "",
                  "hide-details": "",
                  "no-resize": "",
                  multiline: "",
                  readonly: !_vm.isEditNotes
                },
                model: {
                  value: _vm.notesCopy,
                  callback: function callback($$v) {
                    _vm.notesCopy = $$v
                  },
                  expression: "notesCopy"
                }
              }),
              _vm.isEditNotes
                ? _c(
                    "div",
                    {
                      staticClass: "mb-2 ml-1"
                    },
                    [
                      _c("v-btn", {
                        staticClass: "mr-1",
                        attrs: {
                          "x-small": "",
                          elevation: 0
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("cancel"))
                        },
                        on: {
                          click: _vm.abortUpdateDescription
                        }
                      }),
                      _c("v-btn", {
                        attrs: {
                          "x-small": "",
                          elevation: 0,
                          color: "info"
                        },
                        domProps: {
                          textContent: _vm._s(_vm.$t("save"))
                        },
                        on: {
                          click: _vm.updateDescription
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }