var render = function() {
  var _vm$value$data$0$ack,
    _vm$getAckUser,
    _vm$value$data$0$ack2,
    _vm$getAckUser2,
    _vm$value$data$0$ack3

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          staticClass: "px-0"
        },
        [
          _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-clock")])], 1),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("detailedDateWithDay")(_vm.value.start)) +
                    " "
                )
              ]),
              _vm.value.timed
                ? _c("v-list-item-subtitle", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatHoursAndMinutes")(
                            new Date(_vm.value.start)
                          )
                        )
                      )
                    ]),
                    _vm.value.end
                      ? _c("span", [
                          _vm._v(
                            "- " +
                              _vm._s(
                                _vm._f("formatHoursAndMinutes")(
                                  new Date(_vm.value.end)
                                )
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.showVehicle
        ? _c(
            "refs-vehicle",
            {
              staticClass: "pa-0 my-n3",
              attrs: {
                item: _vm.value.vehicle
              },
              on: {
                openDetail: function openDetail(v) {
                  return _vm.$emit("openVehicleDetail", v)
                }
              }
            },
            [
              _c(
                "template",
                {
                  slot: "icon"
                },
                [
                  _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-car")])], 1)
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm.value.type === _vm.TYPES.TICKET
        ? _c(
            "refs-ticket",
            {
              staticClass: "pa-0 my-n3",
              attrs: {
                item: _vm.value.data[0],
                isOpenSideCard: true,
                small: true
              },
              on: {
                openDetail: function openDetail(v) {
                  return _vm.$emit("openTicketDetail", v)
                }
              }
            },
            [
              _c(
                "template",
                {
                  slot: "icon"
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-ticket-outline")])],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _vm.value.type === _vm.TYPES.REPORT
        ? _c(
            "refs-report",
            {
              staticClass: "pa-0 my-n3",
              attrs: {
                item: _vm.value.data[0],
                isOpenSideCard: true,
                small: true
              },
              on: {
                openDetail: function openDetail(v) {
                  return _vm.$emit("openReportDetail", v)
                }
              }
            },
            [
              _c(
                "template",
                {
                  slot: "icon"
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-wrench-outline")])],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm.value.type === _vm.TYPES.LEASING_END ||
          _vm.value.type === _vm.TYPES.LEASING_START
        ? _c(
            "v-list-item",
            {
              staticClass: "px-0"
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-signature")])],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("refs-contract", {
                    staticClass: "pa-0 ma-n3",
                    attrs: {
                      item: _vm.value.data[0]
                    },
                    on: {
                      openDetail: function openDetail(v) {
                        return _vm.$emit("openContractDetail", v)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.value.type === _vm.TYPES.EVENT
        ? _c(
            "div",
            [
              _vm.value.data[0].description
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "px-0"
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-text-box-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "ma-n3"
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(" Beschreibung ")
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      _vm._s(_vm.value.data[0].description)
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.data[0].location
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "px-0"
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-map-marker-outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "ma-n3"
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.value.data[0].location))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.data[0].ack
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "px-0"
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-check-circle")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "ma-n3"
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("detailedDate")(
                                          (_vm$value$data$0$ack =
                                            _vm.value.data[0].ack) === null ||
                                            _vm$value$data$0$ack === void 0
                                            ? void 0
                                            : _vm$value$data$0$ack.at
                                        )
                                      )
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm$getAckUser = _vm.getAckUser(
                                            (_vm$value$data$0$ack2 =
                                              _vm.value.data[0].ack) === null ||
                                              _vm$value$data$0$ack2 === void 0
                                              ? void 0
                                              : _vm$value$data$0$ack2.by
                                          )) === null ||
                                            _vm$getAckUser === void 0
                                            ? void 0
                                            : _vm$getAckUser.firstName
                                        ) +
                                        " " +
                                        _vm._s(
                                          (_vm$getAckUser2 = _vm.getAckUser(
                                            (_vm$value$data$0$ack3 =
                                              _vm.value.data[0].ack) === null ||
                                              _vm$value$data$0$ack3 === void 0
                                              ? void 0
                                              : _vm$value$data$0$ack3.by
                                          )) === null ||
                                            _vm$getAckUser2 === void 0
                                            ? void 0
                                            : _vm$getAckUser2.lastName
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.value.data[0].ack
                ? _c(
                    "v-card-actions",
                    {
                      staticClass: "px-0 pb-0"
                    },
                    [
                      !_vm.value.data[0].isRecurringRoot
                        ? _c("v-btn", {
                            attrs: {
                              elevation: "0",
                              small: "",
                              color: "info"
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("acknowledge"))
                            },
                            on: {
                              click: _vm.acknowledge
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("event-card-acknowledgement-dialog", {
                ref: "eventCardAcknowledgementDialog",
                attrs: {
                  value: _vm.value.data[0]
                },
                on: {
                  confirmed: _vm.onAcknowledged
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }