















import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import { IHandover } from "@/models/handover.entity";
import { Snapshot } from "@/models/snapshot.entity";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    ConfirmActionDialog,
    Debug
  }
})
export default class CreateHandoverSnapshotDialog extends Vue {
  loading = false;

  @Prop({ default: false })
  isDialogActive!: boolean;

  @Prop()
  value!: IHandover;

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }

  async rightClick() {
    this.loading = true;
    try {
      await new Snapshot({ partnerId: this.value.partnerId, handoverId: this.value.id }).create({});
      this.$toast.success("👍");
      this.close();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
