var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.$t("navCategory.fleet"),
              chips: _vm.chips,
              breadCrumbs: _vm.crumbs,
              actions: _vm.actions
            },
            on: {
              actionClicked: _vm.processAction
            }
          }),
          _c(
            "v-tabs",
            {
              class: _vm.isMobile ? "" : "pl-2",
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "yellow"
                }
              }),
              _vm._l(_vm.tabs, function(item) {
                return _c(
                  "v-tab",
                  {
                    key: item
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("views.fleet.FleetHomeView.".concat(item))
                        ) +
                        " "
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.VEHICLE,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.VEHICLE,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3"
                                  }
                                },
                                [
                                  _vm.loading
                                    ? _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "article"
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-2 pa-2",
                                          attrs: {
                                            flat: "",
                                            outlined: "",
                                            "data-test-vehicle-card": ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.setTab(
                                                _vm.FleetTabs.VEHICLE
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "three-line": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-overline mb-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.fleet.FleetVehicleListView.title"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-h5 mb-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.inactiveVehicles
                                                              .length
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.fleet.FleetVehicleListView.state.inactive"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "100"
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      alt: "Fahrzeuge",
                                                      src: require("@/assets/undraw_automobile_repair_ywci.svg"),
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.TICKET,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3"
                                  }
                                },
                                [
                                  _vm.isLoadingTickets
                                    ? _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "article"
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-2 pa-2",
                                          attrs: {
                                            flat: "",
                                            outlined: "",
                                            "data-test-ticket-card": ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.setTab(
                                                _vm.FleetTabs.TICKET
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "three-line": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-overline mb-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.fleet.FleetVehicleDetailHomeCard.tickets"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-h5 mb-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.overdueTickets
                                                              .length
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.fleet.FleetHomeVehicleTable.openTickets"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "100"
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      alt: "Aufgaben",
                                                      src: require("@/assets/undraw_done_re_oak4.svg"),
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.EVENT,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3"
                                  }
                                },
                                [
                                  _vm.isLoadingEvents
                                    ? _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "article"
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-2 pa-2",
                                          attrs: {
                                            flat: "",
                                            outlined: "",
                                            "data-test-event-card": ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.setTab(
                                                _vm.FleetTabs.EVENT
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "three-line": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-overline mb-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.fleet.FleetVehicleDetailHomeCard.events"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-h5 mb-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.overdueEvents
                                                              .length
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.fleet.FleetVehicleDetailHomeCard.overdueEvents"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "100"
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      alt: "Termine",
                                                      src: require("@/assets/undraw_booking_re_gw4j.svg"),
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.REPORT,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3"
                                  }
                                },
                                [
                                  _vm.isLoadingReports
                                    ? _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "article"
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-2 pa-2",
                                          attrs: {
                                            flat: "",
                                            outlined: "",
                                            "data-test-report-card": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "three-line": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-overline mb-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.fleet.FleetVehicleDetailHomeCard.damages"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-h5 mb-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.openReports
                                                              .length
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.fleet.FleetVehicleDetailHomeCard.openDamages"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "100"
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      alt: "Schäden",
                                                      src: require("@/assets/undraw_towing_6yy4.svg"),
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.COST,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3"
                                  }
                                },
                                [
                                  _vm.isLoadingCosts
                                    ? _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "article"
                                        }
                                      })
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass: "ma-2 pa-2",
                                          attrs: {
                                            flat: "",
                                            outlined: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "three-line": ""
                                              },
                                              on: {
                                                click: _vm.goToCosts
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-overline mb-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.fleet.FleetVehicleDetailHomeCard.costs"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-h5 mb-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.costsTotal
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.fleet.FleetVehicleDetailHomeCard.inYear",
                                                            {
                                                              year: new Date().getFullYear()
                                                            }
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-avatar",
                                                {
                                                  attrs: {
                                                    tile: "",
                                                    size: "100"
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      alt: _vm.$t(
                                                        "components.fleet.FleetVehicleDetailHomeCard.costs"
                                                      ),
                                                      src: require("@/assets/undraw_savings_re_eq4w.svg"),
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.EVENT,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-2 mt-2",
                                      attrs: {
                                        outlined: "",
                                        flat: "",
                                        "data-test-overdue-event-card": "",
                                        "min-height": "400"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-capitalize"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.fleet.FleetVehicleDetailHomeCard.events"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-xs text--disabled text-truncate cursor-pointer",
                                              attrs: {
                                                text: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.setTab(
                                                    _vm.FleetTabs.EVENT
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.cards.LatestReportEntriesCard.button"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _vm.isLoadingEvents &&
                                      !_vm.overdueEventsPreview.length
                                        ? _c("v-skeleton-loader", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              type:
                                                "list-item-two-line, list-item-two-line, list-item-two-line"
                                            }
                                          })
                                        : _vm.isLoadingEvents
                                        ? _c("v-progress-linear", {
                                            attrs: {
                                              indeterminate: ""
                                            }
                                          })
                                        : _c(
                                            "v-tabs",
                                            {
                                              model: {
                                                value: _vm.eventTab,
                                                callback: function callback(
                                                  $$v
                                                ) {
                                                  _vm.eventTab = $$v
                                                },
                                                expression: "eventTab"
                                              }
                                            },
                                            [
                                              _c("v-tab", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("overdue"))
                                                )
                                              ]),
                                              _c("v-tab", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.states.upcoming"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.eventTab,
                                            callback: function callback($$v) {
                                              _vm.eventTab = $$v
                                            },
                                            expression: "eventTab"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _vm._l(
                                                    _vm.overdueEventsPreview,
                                                    function(item) {
                                                      return [
                                                        _c("refs-event", {
                                                          key: item.id,
                                                          attrs: {
                                                            item: item,
                                                            small: true,
                                                            confirmable: true,
                                                            confirmablePersistant: true
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openEventSideCard
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  ),
                                                  _vm.overdueEvents.length > 5
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.setTab(
                                                                _vm.FleetTabs
                                                                  .EVENT
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " ... " +
                                                              _vm._s(
                                                                _vm
                                                                  .overdueEvents
                                                                  .length - 5
                                                              ) +
                                                              " weitere "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              !_vm.overdueEvents.length &&
                                              !_vm.isLoadingEvents
                                                ? _c(
                                                    "v-list",
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                                                          "max-height": "150px",
                                                          contain: ""
                                                        }
                                                      }),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "text-center text-caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.fleet.FleetVehicleDetailHomeCard.noOverdueAppointments"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tab-item",
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _vm._l(
                                                    _vm.upcomingEventsPreview,
                                                    function(item) {
                                                      return [
                                                        _c("refs-event", {
                                                          key: item.id,
                                                          attrs: {
                                                            item: item,
                                                            small: true
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openEventSideCard
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  ),
                                                  _vm.upcomingEvents.length > 5
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.setTab(
                                                                _vm.FleetTabs
                                                                  .EVENT
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " ... " +
                                                              _vm._s(
                                                                _vm
                                                                  .upcomingEvents
                                                                  .length - 5
                                                              ) +
                                                              " weitere "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              !_vm.upcomingEvents.length &&
                                              !_vm.isLoadingEvents
                                                ? _c(
                                                    "v-list",
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                                                          "max-height": "150px",
                                                          contain: ""
                                                        }
                                                      }),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "text-center text-caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.fleet.FleetVehicleDetailHomeCard.noUpcomingAppointmentsFromTo",
                                                                  {
                                                                    start:
                                                                      _vm.calendarDatePreviousMonth,
                                                                    end:
                                                                      _vm.calendarDateNextMonth
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.TICKET,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-2 mt-2",
                                      attrs: {
                                        outlined: "",
                                        flat: "",
                                        "data-test-overdue-ticket-card": "",
                                        "min-height": "400"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "text-capitalize"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.fleet.FleetVehicleDetailHomeCard.tickets"
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-xs text--disabled text-truncate cursor-pointer",
                                              attrs: {
                                                text: ""
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.setTab(
                                                    _vm.FleetTabs.TICKET
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.cards.LatestReportEntriesCard.button"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _vm.isLoadingTickets &&
                                      !_vm.overdueTicketsPreview.length
                                        ? _c("v-skeleton-loader", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              type:
                                                "list-item-two-line, list-item-two-line, list-item-two-line"
                                            }
                                          })
                                        : _vm.isLoadingTickets
                                        ? _c("v-progress-linear", {
                                            attrs: {
                                              indeterminate: ""
                                            }
                                          })
                                        : _c(
                                            "v-tabs",
                                            {
                                              model: {
                                                value: _vm.ticketTab,
                                                callback: function callback(
                                                  $$v
                                                ) {
                                                  _vm.ticketTab = $$v
                                                },
                                                expression: "ticketTab"
                                              }
                                            },
                                            [
                                              _c("v-tab", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("overdue"))
                                                )
                                              ]),
                                              _c("v-tab", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.states.upcoming"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.ticketTab,
                                            callback: function callback($$v) {
                                              _vm.ticketTab = $$v
                                            },
                                            expression: "ticketTab"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _vm._l(
                                                    _vm.overdueTicketsPreview,
                                                    function(item) {
                                                      return [
                                                        _c("refs-ticket", {
                                                          key: item.id,
                                                          attrs: {
                                                            item: item
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openTicketSideCard
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  ),
                                                  _vm.overdueTickets.length > 5
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.setTab(
                                                                _vm.FleetTabs
                                                                  .TICKET
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " ... " +
                                                              _vm._s(
                                                                _vm
                                                                  .overdueTickets
                                                                  .length - 5
                                                              ) +
                                                              " weitere "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              !_vm.overdueTickets.length &&
                                              !_vm.isLoadingTickets
                                                ? _c(
                                                    "v-list",
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                                                          "max-height": "150px",
                                                          contain: ""
                                                        }
                                                      }),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "text-center text-caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.fleet.FleetVehicleDetailHomeCard.noOverdueTickets"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tab-item",
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _vm._l(
                                                    _vm.upcomingTicketsPreview,
                                                    function(item) {
                                                      return [
                                                        _c("refs-ticket", {
                                                          key: item.id,
                                                          attrs: {
                                                            item: item
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openTicketSideCard
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  ),
                                                  _vm.upcomingTickets.length > 5
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.setTab(
                                                                _vm.FleetTabs
                                                                  .TICKET
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " ... " +
                                                              _vm._s(
                                                                _vm
                                                                  .upcomingTickets
                                                                  .length - 5
                                                              ) +
                                                              " weitere "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              !_vm.upcomingTickets.length &&
                                              !_vm.isLoadingTickets
                                                ? _c(
                                                    "v-list",
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                                                          "max-height": "150px",
                                                          contain: ""
                                                        }
                                                      }),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "text-center text-caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.fleet.FleetVehicleDetailHomeCard.noUpcomingTicketsFromTo",
                                                                  {
                                                                    start:
                                                                      _vm.calendarDatePreviousMonth,
                                                                    end:
                                                                      _vm.calendarDateNextMonth
                                                                  }
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.READ,
                                a: _vm.ResourceEnum.REPORT,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _vm.showReports
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "6",
                                        xl: "6"
                                      }
                                    },
                                    [
                                      _c("latest-report-entries-card", {
                                        attrs: {
                                          loading: _vm.loading
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.EVENT,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("fleet-home-vehicle-table", {
                                staticClass: "ma-2",
                                attrs: {
                                  initialLoading: _vm.loading,
                                  loadingEvents: _vm.isPromisingEvents
                                },
                                on: {
                                  updateEventList:
                                    _vm.debounceGetEventsPromiseFrom,
                                  openTicketSideCard: _vm.openTicketSideCard,
                                  openEventSideCard: _vm.openEventSideCard
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.VEHICLE,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [_c("fleet-vehicle-table")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.EVENT,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _vm.tab === _vm.getTabId(_vm.FleetTabs.EVENT)
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    md: "12",
                                    lg: "12"
                                  }
                                },
                                [
                                  _c("fleet-home-calendar", {
                                    style: "min-height: ".concat(
                                      _vm.minHeight + 98,
                                      "px"
                                    ),
                                    attrs: {
                                      loadingEvents: _vm.isPromisingEvents
                                    },
                                    on: {
                                      updateEventList:
                                        _vm.debounceGetEventsPromiseFrom,
                                      openVehicleSideCard:
                                        _vm.openVehicleSideCard,
                                      openTicketSideCard:
                                        _vm.openTicketSideCard,
                                      openReportSideCard:
                                        _vm.openReportSideCard,
                                      openContractDetail:
                                        _vm.openContractDetail,
                                      openEventSideCard: _vm.openEventSideCard
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.TICKET,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [_c("fleet-ticket-table")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.COST,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [_c("fleet-cost-table")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.DRIVER,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [_c("fleet-driver-table")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$can(
                _vm.ActionEnum.READ,
                _vm.ResourceEnum.POOL,
                _vm.partnerId
              )
                ? _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [_c("fleet-pool-table")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pa-2"
                    },
                    [
                      _c(
                        "Can",
                        {
                          attrs: {
                            I: _vm.ActionEnum.READ,
                            a: _vm.ResourceEnum.MILEAGE,
                            field: _vm.partnerId
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "6"
                              }
                            },
                            [
                              _c("fleet-home-vehicle-most-used-cars", {
                                style: "min-height: ".concat(
                                  _vm.minHeight,
                                  "px"
                                ),
                                attrs: {
                                  loading: _vm.loading
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "Can",
                        {
                          attrs: {
                            I: _vm.ActionEnum.READ,
                            a: _vm.ResourceEnum.COST,
                            field: _vm.partnerId
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "6"
                              }
                            },
                            [
                              _c("fleet-home-vehicle-cost-analytics", {
                                style: "min-height: ".concat(
                                  _vm.minHeight,
                                  "px"
                                ),
                                attrs: {
                                  loading: _vm.loading,
                                  partnerId: _vm.partnerId
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.focusedTicket
            ? _c("fleet-home-vehicle-table-ticket-side-card", {
                key:
                  "fleet-home-vehicle-table-ticket-side-card" +
                  (_vm.focusedTicket ? _vm.focusedTicket.number : "null"),
                ref: "fleetHomeVehicleTableTicketSideCard",
                attrs: {
                  value: _vm.focusedTicket
                }
              })
            : _vm._e(),
          _vm.focusedEvent
            ? _c(
                "side-card",
                {
                  staticStyle: {
                    "max-width": "600px"
                  },
                  attrs: {
                    title: _vm.$t(
                      "views.fleet.FleetVehicleDetailEventCard.detail"
                    ),
                    width: "600",
                    absolute: true
                  },
                  on: {
                    close: function close($event) {
                      _vm.focusedEvent = null
                    }
                  }
                },
                [
                  _c("fleet-event-menu-card", {
                    staticStyle: {
                      "min-width": "600px"
                    },
                    attrs: {
                      selectedEvent: _vm.focusedEvent,
                      showActions: false
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isReportSideCard
            ? _c("partner-report-detail-side-card", {
                key:
                  "partner-report-detail-side-card-damage-list-table" +
                  (_vm.focusedReport ? _vm.focusedReport.id : "null"),
                attrs: {
                  loading: _vm.isReportSideCardLoading,
                  report: _vm.focusedReport
                },
                on: {
                  close: function close($event) {
                    _vm.isReportSideCard = false
                  }
                }
              })
            : _vm._e(),
          _c("create-vehicle-dialog", {
            attrs: {
              isDialogActive: _vm.isCreateVehicleDialogActive,
              partnerId: _vm.partnerId
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCreateVehicleDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCreateVehicleDialogActive = $event
              }
            }
          }),
          _vm.focusedEvent
            ? _c("event-side-card", {
                attrs: {
                  value: _vm.focusedEvent
                },
                on: {
                  close: function close($event) {
                    _vm.focusedEvent = null
                  },
                  input: _vm.onUpdateEvent,
                  delete: _vm.onDeleteEvent,
                  goToDetail: _vm.goToEventDetail,
                  goToEdit: _vm.goToEventEdit,
                  acknowledged: function acknowledged($event) {
                    return _vm.debounceGetEventsPromiseFrom(_vm.calendarDate)
                  }
                }
              })
            : _vm._e(),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isOnboardingDialogActive,
                title: _vm.$t("views.fleet.FleetHomeVehicleTable.noVehicle"),
                hideRight: !_vm.canCreateVehicle,
                leftText: _vm.$t("close"),
                rightText: _vm.$t("getStarted")
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isOnboardingDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isOnboardingDialogActive = $event
                },
                close: function close($event) {
                  _vm.isOnboardingDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isOnboardingDialogActive = false
                },
                rightClick: function rightClick() {
                  _vm.processAction({
                    key: _vm.FleetActions.CREATE_VEHICLE
                  })

                  _vm.isOnboardingDialogActive = false
                }
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-auto",
                  staticStyle: {
                    "max-width": "750px"
                  },
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c("v-card-subtitle", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "views.fleet.FleetHomeVehicleTable.noVehicleHint0"
                          )
                        )
                      }
                    }),
                    _vm.canCreateVehicle
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function click() {
                                _vm.processAction({
                                  key: _vm.FleetActions.CREATE_VEHICLE
                                })

                                _vm.isOnboardingDialogActive = false
                              }
                            }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "views.fleet.FleetHomeVehicleTable.noVehicleHint1"
                                  )
                                )
                              }
                            })
                          ]
                        )
                      : _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "views.fleet.FleetHomeVehicleTable.noVehicleHint1"
                              )
                            )
                          }
                        }),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "views.fleet.FleetHomeVehicleTable.noVehicleHint2"
                          )
                        )
                      }
                    }),
                    _vm.canCreateVehicle
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function click() {
                                _vm.processAction({
                                  key: _vm.FleetActions.IMPORT_VEHICLE
                                })

                                _vm.isOnboardingDialogActive = false
                              }
                            }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "views.fleet.FleetHomeVehicleTable.noVehicleHint3"
                                  )
                                )
                              }
                            })
                          ]
                        )
                      : _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "views.fleet.FleetHomeVehicleTable.noVehicleHint3"
                              )
                            )
                          }
                        }),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "views.fleet.FleetHomeVehicleTable.noVehicleHint4"
                          )
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }