var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-action",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: "center",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        disabled: !_vm.isPreviousFahrzeugscheinAvailable
                      },
                      on: {
                        click: _vm.redirectToPreviousFahrzeugschein
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left-bold")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        loading: _vm.loading,
                        form: "fahrzeugschein-detail-form",
                        color: "success"
                      }
                    },
                    [_vm._v(" Aktualisieren ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        disabled: !_vm.isNextFahrzeugscheinAvailable
                      },
                      on: {
                        click: _vm.redirectToNextFahrzeugschein
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-right-bold")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  attrs: {
                    id: "fahrzeugschein-detail-form"
                  },
                  on: {
                    submit: function submit($event) {
                      $event.preventDefault()
                      return _vm.updateFahrzeugschein.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Kennzeichen"
                    },
                    model: {
                      value: _vm.fahrzeugschein.kennzeichen,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "kennzeichen", $$v)
                      },
                      expression: "fahrzeugschein.kennzeichen"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Nachname"
                    },
                    model: {
                      value: _vm.fahrzeugschein.nachname,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "nachname", $$v)
                      },
                      expression: "fahrzeugschein.nachname"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Vorname"
                    },
                    model: {
                      value: _vm.fahrzeugschein.vorname,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "vorname", $$v)
                      },
                      expression: "fahrzeugschein.vorname"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "PLZ"
                    },
                    model: {
                      value: _vm.fahrzeugschein.plz,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "plz", $$v)
                      },
                      expression: "fahrzeugschein.plz"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Straße"
                    },
                    model: {
                      value: _vm.fahrzeugschein.strasse,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "strasse", $$v)
                      },
                      expression: "fahrzeugschein.strasse"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Stadt"
                    },
                    model: {
                      value: _vm.fahrzeugschein.stadt,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "stadt", $$v)
                      },
                      expression: "fahrzeugschein.stadt"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Hu Monat"
                    },
                    model: {
                      value: _vm.fahrzeugschein.hu_monat,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "hu_monat", $$v)
                      },
                      expression: "fahrzeugschein.hu_monat"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Hu Jahr"
                    },
                    model: {
                      value: _vm.fahrzeugschein.hu_jahr,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "hu_jahr", $$v)
                      },
                      expression: "fahrzeugschein.hu_jahr"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Erstzulassung Tag"
                    },
                    model: {
                      value: _vm.fahrzeugschein.erstzulassung_tag,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "erstzulassung_tag", $$v)
                      },
                      expression: "fahrzeugschein.erstzulassung_tag"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Erstzulassung Monat"
                    },
                    model: {
                      value: _vm.fahrzeugschein.erstzulassung_monat,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "erstzulassung_monat", $$v)
                      },
                      expression: "fahrzeugschein.erstzulassung_monat"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Erstzulassung Jahr"
                    },
                    model: {
                      value: _vm.fahrzeugschein.erstzulassung_jahr,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "erstzulassung_jahr", $$v)
                      },
                      expression: "fahrzeugschein.erstzulassung_jahr"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Codehersteller"
                    },
                    model: {
                      value: _vm.fahrzeugschein.codehersteller,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "codehersteller", $$v)
                      },
                      expression: "fahrzeugschein.codehersteller"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      outlined: "",
                      clearable: "",
                      label: "Codetyp"
                    },
                    model: {
                      value: _vm.fahrzeugschein.codetyp,
                      callback: function callback($$v) {
                        _vm.$set(_vm.fahrzeugschein, "codetyp", $$v)
                      },
                      expression: "fahrzeugschein.codetyp"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      loading: _vm.loading,
                      "error-messages": _vm.finErrorMessage,
                      outlined: "",
                      clearable: "",
                      label: "Identifizierungsnummer"
                    },
                    on: {
                      change: _vm.changeFin
                    },
                    model: {
                      value: _vm.fahrzeugschein.identifizierungsnummer,
                      callback: function callback($$v) {
                        _vm.$set(
                          _vm.fahrzeugschein,
                          "identifizierungsnummer",
                          $$v
                        )
                      },
                      expression: "fahrzeugschein.identifizierungsnummer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        loading: _vm.loading
                      },
                      on: {
                        click: _vm.printXMLForFahrzeugschein
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-cloud-print")]),
                      _vm._v(" XML Drucken ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        loading: _vm.deleteLoading,
                        color: "red"
                      },
                      on: {
                        click: function click($event) {
                          _vm.deleteDialogOpen = true
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-delete")]), _vm._v(" Löschen ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                topcolor: "success",
                timeout: _vm.timeout,
                dark: ""
              },
              model: {
                value: _vm.snackbar,
                callback: function callback($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [_vm._v(" Fahrzeugschein wurde erfolgreich aktualisiert. ")]
          ),
          _vm.debug
            ? _c("v-card-text", [
                _c("pre", [
                  _vm._v("      \t" + _vm._s(_vm.fahrzeugschein) + "\n    ")
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.deleteDialogOpen,
            width: "500"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2"
                },
                [_vm._v(" Fahrzeugschein löschen ")]
              ),
              _c("v-divider"),
              _c("br"),
              _c("v-card-text", [
                _vm._v(" Möchten Sie diesen Fahrzeugschein wirklich löschen? ")
              ]),
              _c("br"),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.deleteDialogOpen = false
                        }
                      }
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: _vm.deleteFahrzeugschein
                      }
                    },
                    [_vm._v(" Ok ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }