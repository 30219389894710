import { render, staticRenderFns } from "./KanbanBoard.vue?vue&type=template&id=6789cda2&scoped=true&"
import script from "./KanbanBoard.vue?vue&type=script&lang=ts&"
export * from "./KanbanBoard.vue?vue&type=script&lang=ts&"
import style0 from "./KanbanBoard.vue?vue&type=style&index=0&id=6789cda2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6789cda2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VBtn,VCard,VChip,VContainer,VIcon,VListItem,VListItemAction,VListItemContent})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6789cda2')) {
      api.createRecord('6789cda2', component.options)
    } else {
      api.reload('6789cda2', component.options)
    }
    module.hot.accept("./KanbanBoard.vue?vue&type=template&id=6789cda2&scoped=true&", function () {
      api.rerender('6789cda2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/KanbanBoard.vue"
export default component.exports