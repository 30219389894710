var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "ma-2"
    },
    [
      _c("calendar", {
        attrs: {
          headers: _vm.headers,
          events: _vm.events,
          categories: _vm.categories,
          loading: _vm.loading,
          type: _vm.calendarType
        },
        on: {
          "update:type": function updateType($event) {
            _vm.calendarType = $event
          },
          change: function change(v) {
            return _vm.$emit("change", v)
          },
          "click:time": _vm.onTimeContextMenu,
          "click:row": _vm.onRowClick,
          "click:detail": _vm.onClickUpcoming,
          "contextmenu:event": _vm.onEventClick,
          "contextmenu:time": _vm.onTimeContextMenu
        },
        scopedSlots: _vm._u([
          {
            key: "preview",
            fn: function fn(_ref) {
              var slotScope = _ref.slotScope
              return [
                slotScope.item
                  ? _c("booking-menu-card", {
                      attrs: {
                        selected: slotScope.item.booking
                      },
                      on: {
                        select: function select(v) {
                          slotScope.close()

                          _vm.onDetailClick(v)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "titleAction",
            fn: function fn() {
              return [
                !_vm.isMobile
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          color: "info",
                          elevation: "0",
                          small: ""
                        },
                        on: {
                          click: _vm.onClickCreate
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("views.booking.BookingTable.create")
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "upcoming",
            fn: function fn(_ref2) {
              var slotScope = _ref2.slotScope
              return [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(slotScope.item.name) + " ")
                        ]),
                        _c("v-list-item-title", [
                          _vm._v(
                            _vm._s(
                              _vm._f("detailedDateWithDay")(
                                slotScope.item.start
                              )
                            )
                          )
                        ]),
                        _c("v-list-item-subtitle", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatHoursAndMinutes")(
                                  new Date(slotScope.item.start)
                                )
                              )
                            )
                          ]),
                          slotScope.item.end
                            ? _c("span", [
                                _vm._v(
                                  "- " +
                                    _vm._s(
                                      _vm._f("formatHoursAndMinutes")(
                                        new Date(slotScope.item.end)
                                      )
                                    )
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.booking.customerInformation.firstName",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.booking.customerInformation
                        ? item.booking.customerInformation.firstName
                        : ""
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.customerInformation.lastName",
            fn: function fn(_ref4) {
              var item = _ref4.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.booking.customerInformation
                        ? item.booking.customerInformation.lastName
                        : ""
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.isDeleted",
            fn: function fn(_ref5) {
              var item = _ref5.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item.isDeleted ? _vm.$t("yes") : _vm.$t("no")) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.start",
            fn: function fn(_ref6) {
              var item = _ref6.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleDoubleDigitDate")(
                        new Date(item.booking.start)
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.startTime",
            fn: function fn(_ref7) {
              var item = _ref7.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatHoursAndMinutes")(
                        new Date(item.booking.start)
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.end",
            fn: function fn(_ref8) {
              var item = _ref8.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleDoubleDigitDate")(
                        new Date(item.booking.end)
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.endTime",
            fn: function fn(_ref9) {
              var item = _ref9.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatHoursAndMinutes")(
                        new Date(item.booking.end)
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.duration",
            fn: function fn(_ref10) {
              var item = _ref10.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("msToTime")(item.booking.duration)) + " "
                )
              ]
            }
          },
          {
            key: "item.booking.timestamp.created",
            fn: function fn(_ref11) {
              var item = _ref11.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleDoubleDigitDate")(
                        item.booking.timestamp.created
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.booking.serviceId",
            fn: function fn(_ref12) {
              var _vm$mapService$color, _vm$mapService, _vm$mapService2

              var item = _ref12.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      small: "",
                      color: item.booking.serviceId
                        ? (_vm$mapService$color =
                            (_vm$mapService = _vm.mapService(
                              item.booking.serviceId
                            )) === null || _vm$mapService === void 0
                              ? void 0
                              : _vm$mapService.color) !== null &&
                          _vm$mapService$color !== void 0
                          ? _vm$mapService$color
                          : _vm.defaultColor
                        : _vm.placeholderColor
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.booking.serviceId
                            ? (_vm$mapService2 = _vm.mapService(
                                item.booking.serviceId
                              )) === null || _vm$mapService2 === void 0
                              ? void 0
                              : _vm$mapService2.name
                            : _vm.placeholder
                        ) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.booking.resourceId",
            fn: function fn(_ref13) {
              var _vm$mapResource

              var item = _ref13.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      (_vm$mapResource = _vm.mapResource(
                        item.booking.resourceId
                      )) === null || _vm$mapResource === void 0
                        ? void 0
                        : _vm$mapResource.name
                    ) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isCreateDialogActive,
            title: _vm.$t("views.booking.BookingTable.confirmCreateTitle"),
            subtitle: _vm.$t(
              "views.booking.BookingTable.confirmCreateSubtitle"
            ),
            leftLoading: _vm.isCreateDialogLoading,
            rightLoading: _vm.isCreateDialogLoading,
            rightDisabled: !_vm.isCreateValid
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isCreateDialogActive = false
            },
            rightClick: _vm.createBooking
          }
        },
        [
          _c("booking-form", {
            ref: "bookingForm",
            attrs: {
              isLoading: _vm.isCreateDialogLoading,
              services: _vm.servicesList,
              resources: _vm.resourcesList
            },
            on: {
              valid: function valid($event) {
                _vm.isCreateValid = $event
              }
            },
            model: {
              value: _vm.createBookingDto,
              callback: function callback($$v) {
                _vm.createBookingDto = $$v
              },
              expression: "createBookingDto"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            elevation: "6",
            color: "primary",
            fab: "",
            fixed: "",
            bottom: "",
            right: ""
          },
          on: {
            click: _vm.onClickCreate
          }
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: "white"
              }
            },
            [_vm._v(" mdi-plus ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }