var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              breadCrumbs: _vm.breadCrumbList,
              title: _vm.$t("credential.title"),
              chips: _vm.chips,
              actions: _vm.actions
            },
            on: {
              actionClicked: function actionClicked(a) {
                return a.exec()
              }
            }
          }),
          _c("credential-table", {
            ref: "credentialTable",
            attrs: {
              partnerId: _vm.partnerId,
              loadingPartnerUsers: _vm.loadingPartnerUsers
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }