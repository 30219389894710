var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-select", {
    attrs: {
      outlined: "",
      rules: _vm.arrayNotEmptyRule,
      items: _vm.allResources,
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: "400"
      },
      label: _vm.$t("components.booking.ResourceList.selectResource") + " *",
      hint: _vm.$t("components.booking.ResourceList.selectResourceHint"),
      multiple: ""
    },
    model: {
      value: _vm.selectedResourcesModel,
      callback: function callback($$v) {
        _vm.selectedResourcesModel = $$v
      },
      expression: "selectedResourcesModel"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }