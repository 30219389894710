var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.CREDENTIAL
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "info",
                elevation: "0"
              },
              on: {
                click: function click($event) {
                  _vm.dialogActive = true
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("credential.create")))]
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogActive,
            title: _vm.$t("credential.create"),
            leftLoading: _vm.isLoading,
            rightLoading: _vm.isLoading,
            rightDisabled: !_vm.isValid,
            width: "850"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialogActive = false
            },
            rightClick: _vm.create,
            close: function close($event) {
              _vm.dialogActive = false
            }
          }
        },
        [
          _c("credential-form", {
            attrs: {
              valid: _vm.isValid
            },
            on: {
              "update:valid": function updateValid($event) {
                _vm.isValid = $event
              }
            },
            model: {
              value: _vm.credential,
              callback: function callback($$v) {
                _vm.credential = $$v
              },
              expression: "credential"
            }
          }),
          _c("v-card-text", [
            _vm._v(" " + _vm._s(_vm.$t("credential.generalHint")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }