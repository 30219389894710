var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("table-wrapper", {
        attrs: {
          title: "Excelimportassistent",
          loading: _vm.loading,
          headers: _vm.headers,
          allItems: _vm.importedItems
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c(
                  "excel-import",
                  {
                    attrs: {
                      config: _vm.config
                    },
                    on: {
                      uploadData: _vm.importData
                    }
                  },
                  [
                    _c(
                      "v-progress-linear",
                      {
                        attrs: {
                          value: _vm.progress,
                          height: "25"
                        }
                      },
                      [
                        _c("strong", [
                          _vm._v(_vm._s(Math.ceil(_vm.progress)) + "%")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }