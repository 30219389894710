var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _vm.title
        ? _c("v-card-subtitle", [_vm._v(_vm._s(_vm.$t(_vm.title)))])
        : _vm._e(),
      _c("v-simple-table", [
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.$t("views.fleet.FleetVehicleDriverDetailTable.active")
                )
              )
            ]),
            _c(
              "td",
              [
                _vm.currentDriver
                  ? _c(
                      "v-chip",
                      {
                        attrs: {
                          small: "",
                          color: "info",
                          dark: ""
                        }
                      },
                      [_vm._v(_vm._s(_vm.currentDriver))]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("td", [
              _c(
                "div",
                {
                  staticClass: "actionButtons  text-right"
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "max-width": "300"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref) {
                            var on = _ref.on,
                              attrs = _ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        icon: "",
                                        "x-small": "",
                                        color: "info"
                                      },
                                      on: {
                                        click: _vm.openCurrentDriverMenu
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.currentDriverMenu,
                        callback: function callback($$v) {
                          _vm.currentDriverMenu = $$v
                        },
                        expression: "currentDriverMenu"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-caption",
                              staticStyle: {
                                "background-color": "#f5f5f5"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ma-2"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.fleet.FleetVehicleDriverDetailTable.who"
                                      )
                                    ) + " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-divider"),
                          _vm.currentDriver
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    small: "",
                                    block: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.updateNoDriver
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.fleet.FleetVehicleDriverDetailTable.none"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.currentDriver ? _c("v-divider") : _vm._e(),
                          !_vm.driversOfVehicle.length
                            ? _c(
                                "div",
                                [
                                  _c("v-card-text", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.fleet.FleetVehicleDriverDetailTable.noneAvailable"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-divider"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        small: "",
                                        color: "info",
                                        text: ""
                                      },
                                      on: {
                                        click: _vm.openDriverMenu
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.fleet.FleetVehicleDriverDetailTable.assign"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-list",
                                    {
                                      attrs: {
                                        dense: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          model: {
                                            value: _vm.tmpCurrentDriver,
                                            callback: function callback($$v) {
                                              _vm.tmpCurrentDriver = $$v
                                            },
                                            expression: "tmpCurrentDriver"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.driversOfVehicle, function(
                                            item,
                                            i
                                          ) {
                                            return [
                                              _c("v-list-item", {
                                                key: i,
                                                attrs: {
                                                  value: item,
                                                  dense: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function fn(_ref2) {
                                                        var active =
                                                          _ref2.active
                                                        return [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            [
                                                              active
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-check-bold"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-content",
                                                            {
                                                              staticClass:
                                                                "ml-n2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(item)
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "info",
                                            outlined: "",
                                            small: ""
                                          },
                                          on: {
                                            click: _vm.closeMenu
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.fleet.EventCreateDialog.close"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "info",
                                            elevation: "0",
                                            small: ""
                                          },
                                          on: {
                                            click: _vm.updateCurrentDriver
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.fleet.EventCreateDialog.save"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("tr", [
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.$t("views.fleet.FleetVehicleDriverDetailTable.header")
                )
              )
            ]),
            _c(
              "td",
              _vm._l(_vm.driversOfVehicle, function(driver, idx) {
                return _c(
                  "v-chip",
                  {
                    key: idx,
                    staticClass: "mr-2 mb-1",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v(_vm._s(driver))]
                )
              }),
              1
            ),
            _c("td", [
              _c(
                "div",
                {
                  staticClass: "actionButtons text-right"
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "max-width": "400"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function fn(_ref3) {
                            var on = _ref3.on,
                              attrs = _ref3.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        icon: "",
                                        "x-small": "",
                                        color: "info"
                                      },
                                      on: {
                                        click: _vm.openDriverMenu
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.driversMenu,
                        callback: function callback($$v) {
                          _vm.driversMenu = $$v
                        },
                        expression: "driversMenu"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-caption",
                              staticStyle: {
                                "background-color": "#f5f5f5"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ma-2"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.fleet.FleetVehicleDriverDetailTable.add"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list",
                            {
                              attrs: {
                                dense: ""
                              }
                            },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: {
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.temporaryDriverList,
                                    callback: function callback($$v) {
                                      _vm.temporaryDriverList = $$v
                                    },
                                    expression: "temporaryDriverList"
                                  }
                                },
                                [
                                  _vm._l(_vm.drivers, function(item, i) {
                                    return [
                                      _c("v-list-item", {
                                        key: i,
                                        attrs: {
                                          value: item.lastName,
                                          dense: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function fn(_ref4) {
                                                var active = _ref4.active
                                                return [
                                                  _c(
                                                    "v-list-item-avatar",
                                                    [
                                                      active
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-check-bold"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "ml-n2"
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.firstName +
                                                              " " +
                                                              item.lastName
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "info",
                                    outlined: "",
                                    small: ""
                                  },
                                  on: {
                                    click: _vm.closeMenu
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.fleet.EventCreateDialog.close"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "info",
                                    elevation: "0",
                                    small: ""
                                  },
                                  on: {
                                    click: _vm.updateDriver
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.fleet.EventCreateDialog.save"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "info",
                                    small: "",
                                    text: ""
                                  },
                                  on: {
                                    click: _vm.goToDrivers
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.fleet.FleetVehicleDriverDetailTable.new"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }