





















































































































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { Component, Prop } from "vue-property-decorator";
import PartnerReportCardImages from "../partner/PartnerReportCardImages.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import { IVehicle } from "@/models/vehicle.entity";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { IHandover } from "@/models/handover.entity";
import { IInspection } from "@/models/inspection.entity";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { IReport } from "@/models/report.entity";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivPositionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

enum StepEnum {
  LOCATOR = "locator",
  PICTURES = "pictures",
  DETAILS = "details",
  SUCCESS = "success"
}

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    PartnerReportCardImages,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CreateHandoverFindingDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  handover!: IHandover;

  @Prop()
  inspection?: IInspection;

  readonly StepEnum = StepEnum;

  selectedInspectionId = "";

  report = new CreateReportAsPartnerDto();

  step: StepEnum = StepEnum.LOCATOR;

  example = AssetEnum.damageDetail;

  isDialogActive = false;

  created: IReport | null = null;

  isRightLoading = false;

  isImageLoading = false;

  meta = "";

  get fullScreen() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get type() {
    return ReportImageType.damageDetail;
  }

  get blueprints() {
    return this.vehicle.blueprints;
  }

  selectedBlueprintId = "";

  markers: IMarker[] = [];

  get bluePrintImageUrl() {
    return this.vehicle.blueprints.find(b => b.id === this.selectedBlueprintId)?.image.url;
  }

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  get hideLeft() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        return true;
      default:
        return false;
    }
  }

  get rightDisabled() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        return !this.markers.length;
      default:
        return false;
    }
  }

  get leftText() {
    switch (this.step) {
      case StepEnum.SUCCESS:
        return "Befund ansehen";
      default:
        return "Zurück";
    }
  }

  get rightText() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        return "Weiter";
      case StepEnum.PICTURES:
        return "Weiter";
      case StepEnum.DETAILS:
        return "Befund anlegen";
      case StepEnum.SUCCESS:
        return "Weiteren Befund anlegen";
      default:
        return "Weiter";
    }
  }

  get title() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        return "Wo ist der Befund?";
      case StepEnum.PICTURES:
        return "Fotodukumentation";
      case StepEnum.DETAILS:
        return "Details";
      case StepEnum.SUCCESS:
        return "Befund angelegt";
      default:
        return "";
    }
  }

  open() {
    if (
      !(
        this.can(this.ActionEnum.CREATE, this.ResourceEnum.HANDOVER) ||
        this.can(this.ActionEnum.CREATE, this.ResourceEnum.REPORT)
      )
    ) {
      throw new Error("Permission denied");
    }

    this.selectedInspectionId =
      this.inspection?.id ?? this.handover.inspections[this.handover.inspections.length - 1]?.id ?? "";
    this.isDialogActive = true;
    this.initialize();
  }

  initialize() {
    const report = CreateReportAsPartnerDto.createFromVehicle(this.vehicle);
    report.vehicleId = undefined;
    this.report = report;
    this.created = null;
    this.meta = "";
    this.markers.splice(0);
    this.step = StepEnum.LOCATOR;
  }

  async rightClick() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        this.step = StepEnum.PICTURES;
        break;
      case StepEnum.PICTURES:
        this.step = StepEnum.DETAILS;
        break;
      case StepEnum.DETAILS:
        this.isRightLoading = true;
        try {
          const partnerId = this.handover.partnerId;
          const inspectionId = this.selectedInspectionId;
          const handoverId = this.handover.id;
          const position: MrfiktivPositionDtoGen = {
            blueprintId: this.selectedBlueprintId,
            coordinates: {
              x: this.markers[0].left,
              y: this.markers[0].top
            },
            meta: this.meta
          };

          this.created = await this.report.createAsInspectionFinding(partnerId, handoverId, inspectionId, position);

          this.$toast("Befund erfolgreich angelegt");
        } catch (e) {
          handleError(e);
        } finally {
          this.isRightLoading = false;
          this.step = StepEnum.SUCCESS;
        }
        break;
      case StepEnum.SUCCESS:
        this.initialize();
        break;
    }
  }

  async leftClick() {
    switch (this.step) {
      case StepEnum.LOCATOR:
        this.close();
        break;
      case StepEnum.PICTURES:
        this.step = StepEnum.LOCATOR;
        break;
      case StepEnum.DETAILS:
        this.step = StepEnum.PICTURES;
        break;
      case StepEnum.SUCCESS:
        new GoToHelper(this.$router).goToHandoverReportTableView(
          this.handover?.id,
          this.handover?.partnerId,
          undefined,
          true
        );
        break;
    }
  }

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers = [marker];
  }

  setIsLoadingImage(isLoading: boolean) {
    this.isImageLoading = isLoading;
  }

  close() {
    this.$emit("close");
    this.isDialogActive = false;
  }
}
